*,
::before,
::after {
	box-sizing: border-box
}

html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;
	
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block
}

body {
	margin: 0;
	/*font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #575757;
	text-align: left;
	background-color: #f5f6f7;
	font-family: 'Noto Sans', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	
}

[tabindex="-1"]:focus {
	outline: 0!important
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 20px
}

p {
	margin-top: 0;
	margin-bottom: 1rem
}

abbr[title],
abbr[data-original-title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0
}

dt {
	font-weight: 700
}

dd {
	margin-bottom: .5rem;
	margin-left: 0
}

blockquote {
	margin: 0 0 1rem
}

dfn {
	font-style: italic
}

b,
strong {
	font-weight: bolder
}

small {
	font-size: 80%
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline
}

sub {
	bottom: -.25em
}

sup {
	top: -.5em
}

a {
	color: inherit;
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects
}

a:hover {
	color: inherit;
	text-decoration: none
}

a:not([href]):not([tabindex]) {
	color: inherit;
	text-decoration: none
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
	color: inherit;
	text-decoration: none
}

a:not([href]):not([tabindex]):focus {
	outline: 0
}

pre,
code,
kbd,
samp {
	font-family: monospace, monospace;
	font-size: 1em
}

pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	-ms-overflow-style: scrollbar
}

figure {
	margin: 0 0 1rem
}

img {
	vertical-align: middle;
	border-style: none
}

svg:not(:root) {
	overflow: hidden
}

table {
	border-collapse: collapse
}

caption {
	padding-top: 10px;
	padding-bottom: 10px;
	color: #6c757d;
	text-align: left;
	caption-side: bottom
}

th {
	text-align: inherit
}

label {
	display: inline-block;
	margin-bottom: .5rem
}

button {
	border-radius: 0
}

button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit
}

button,
input {
	overflow: visible
}

button,
select {
	text-transform: none
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none
}

input[type="radio"],
input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
	-webkit-appearance: listbox
}

textarea {
	overflow: auto;
	resize: vertical
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal
}

progress {
	vertical-align: baseline
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button
}

output {
	display: inline-block
}

summary {
	display: list-item;
	cursor: pointer
}

template {
	display: none
}

[hidden] {
	display: none!important
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-bottom: 20px;
	font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-weight: 600;
	line-height: 1.2;
	color: #171717
}

h1,
.h1 {
	font-size: 2.571429rem
}

h2,
.h2 {
	font-size: 2.142857rem
}

h3,
.h3 {
	font-size: 1.857143rem
}

h4,
.h4 {
	font-size: 1.571429rem
}

h5,
.h5 {
	font-size: 1.285714rem
}

h6,
.h6 {
	font-size: 1rem
}

.lead {
	font-size: 1.5rem;
	font-weight: 300
}

.display-1 {
	font-size: 6rem;
	font-weight: 300;
	line-height: 1.2
}

.display-2 {
	font-size: 5.5rem;
	font-weight: 300;
	line-height: 1.2
}

.display-3 {
	font-size: 4.5rem;
	font-weight: 300;
	line-height: 1.2
}

.display-4 {
	font-size: 3.5rem;
	font-weight: 300;
	line-height: 1.2
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid #eee
}

small,
.small {
	font-size: 80%;
	font-weight: 400
}

mark,
.mark {
	padding: .2em;
	background-color: #fcf8e3
}

.list-unstyled {
	padding-left: 0;
	list-style: none
}

.list-inline {
	padding-left: 0;
	list-style: none
}

.list-inline-item {
	display: inline-block
}

.list-inline-item:not(:last-child) {
	margin-right: .5rem
}

.initialism {
	font-size: 90%;
	text-transform: uppercase
}

.blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem
}

.blockquote-footer {
	display: block;
	font-size: 80%;
	color: #6c757d
}

.blockquote-footer::before {
	content: "\2014 \00A0"
}

.img-fluid {
	max-width: 100%;
	height: auto
}

.img-thumbnail {
	padding: .25rem;
	background-color: #f5f6f7;
	border: 1px solid #dee2e6;
	border-radius: .25rem;
	max-width: 100%;
	height: auto
}

.figure {
	display: inline-block
}

.figure-img {
	margin-bottom: .5rem;
	line-height: 1
}

.figure-caption {
	font-size: 90%;
	color: #6c757d
}

code,
kbd,
pre,
samp {
	font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

code {
	font-size: 87.5%;
	color: #e83e8c;
	word-break: break-word
}

a>code {
	color: inherit
}

kbd {
	padding: .2rem .4rem;
	font-size: 87.5%;
	color: #fff;
	background-color: #212529;
	border-radius: .2rem
}

kbd kbd {
	padding: 0;
	font-size: 100%;
	font-weight: 700
}

pre {
	display: block;
	font-size: 87.5%;
	color: #212529
}

pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal
}

.pre-scrollable {
	max-height: 340px;
	overflow-y: scroll
}

.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto
}



@media (min-width: 576px) {
	.container {
		max-width: 540px
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 720px
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 960px
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1140px
	}
}

.container-fluid {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto
}

.row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px
}

.no-gutters {
	margin-right: 0;
	margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
	padding-right: 0;
	padding-left: 0
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px
}

.col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%
}

.col-auto {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: none
}

.col-1 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 8.333333%;
	flex: 0 0 8.333333%;
	max-width: 8.333333%
}

.col-2 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 16.666667%;
	flex: 0 0 16.666667%;
	max-width: 16.666667%
}

.col-3 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%
}

.col-4 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 33.333333%;
	flex: 0 0 33.333333%;
	max-width: 33.333333%
}

.col-5 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 41.666667%;
	flex: 0 0 41.666667%;
	max-width: 41.666667%
}

.col-6 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%
}

.col-7 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 58.333333%;
	flex: 0 0 58.333333%;
	max-width: 58.333333%
}

.col-8 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 66.666667%;
	flex: 0 0 66.666667%;
	max-width: 66.666667%
}

.col-9 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%
}

.col-10 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 83.333333%;
	flex: 0 0 83.333333%;
	max-width: 83.333333%
}

.col-11 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 91.666667%;
	flex: 0 0 91.666667%;
	max-width: 91.666667%
}

.col-12 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%
}

.order-first {
	-webkit-box-ordinal-group: 0;
	-ms-flex-order: -1;
	order: -1
}

.order-last {
	-webkit-box-ordinal-group: 14;
	-ms-flex-order: 13;
	order: 13
}

.order-0 {
	-webkit-box-ordinal-group: 1;
	-ms-flex-order: 0;
	order: 0
}

.order-1 {
	-webkit-box-ordinal-group: 2;
	-ms-flex-order: 1;
	order: 1
}

.order-2 {
	-webkit-box-ordinal-group: 3;
	-ms-flex-order: 2;
	order: 2
}

.order-3 {
	-webkit-box-ordinal-group: 4;
	-ms-flex-order: 3;
	order: 3
}

.order-4 {
	-webkit-box-ordinal-group: 5;
	-ms-flex-order: 4;
	order: 4
}

.order-5 {
	-webkit-box-ordinal-group: 6;
	-ms-flex-order: 5;
	order: 5
}

.order-6 {
	-webkit-box-ordinal-group: 7;
	-ms-flex-order: 6;
	order: 6
}

.order-7 {
	-webkit-box-ordinal-group: 8;
	-ms-flex-order: 7;
	order: 7
}

.order-8 {
	-webkit-box-ordinal-group: 9;
	-ms-flex-order: 8;
	order: 8
}

.order-9 {
	-webkit-box-ordinal-group: 10;
	-ms-flex-order: 9;
	order: 9
}

.order-10 {
	-webkit-box-ordinal-group: 11;
	-ms-flex-order: 10;
	order: 10
}

.order-11 {
	-webkit-box-ordinal-group: 12;
	-ms-flex-order: 11;
	order: 11
}

.order-12 {
	-webkit-box-ordinal-group: 13;
	-ms-flex-order: 12;
	order: 12
}

.offset-1 {
	margin-left: 8.333333%
}

.offset-2 {
	margin-left: 16.666667%
}

.offset-3 {
	margin-left: 25%
}

.offset-4 {
	margin-left: 33.333333%
}

.offset-5 {
	margin-left: 41.666667%
}

.offset-6 {
	margin-left: 50%
}

.offset-7 {
	margin-left: 58.333333%
}

.offset-8 {
	margin-left: 66.666667%
}

.offset-9 {
	margin-left: 75%
}

.offset-10 {
	margin-left: 83.333333%
}

.offset-11 {
	margin-left: 91.666667%
}

@media (min-width: 576px) {
	.col-sm {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%
	}
	.col-sm-auto {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none
	}
	.col-sm-1 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%
	}
	.col-sm-2 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%
	}
	.col-sm-3 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}
	.col-sm-4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%
	}
	.col-sm-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%
	}
	.col-sm-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}
	.col-sm-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%
	}
	.col-sm-8 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%
	}
	.col-sm-9 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%
	}
	.col-sm-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%
	}
	.col-sm-11 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%
	}
	.col-sm-12 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}
	.order-sm-first {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1
	}
	.order-sm-last {
		-webkit-box-ordinal-group: 14;
		-ms-flex-order: 13;
		order: 13
	}
	.order-sm-0 {
		-webkit-box-ordinal-group: 1;
		-ms-flex-order: 0;
		order: 0
	}
	.order-sm-1 {
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1
	}
	.order-sm-2 {
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2
	}
	.order-sm-3 {
		-webkit-box-ordinal-group: 4;
		-ms-flex-order: 3;
		order: 3
	}
	.order-sm-4 {
		-webkit-box-ordinal-group: 5;
		-ms-flex-order: 4;
		order: 4
	}
	.order-sm-5 {
		-webkit-box-ordinal-group: 6;
		-ms-flex-order: 5;
		order: 5
	}
	.order-sm-6 {
		-webkit-box-ordinal-group: 7;
		-ms-flex-order: 6;
		order: 6
	}
	.order-sm-7 {
		-webkit-box-ordinal-group: 8;
		-ms-flex-order: 7;
		order: 7
	}
	.order-sm-8 {
		-webkit-box-ordinal-group: 9;
		-ms-flex-order: 8;
		order: 8
	}
	.order-sm-9 {
		-webkit-box-ordinal-group: 10;
		-ms-flex-order: 9;
		order: 9
	}
	.order-sm-10 {
		-webkit-box-ordinal-group: 11;
		-ms-flex-order: 10;
		order: 10
	}
	.order-sm-11 {
		-webkit-box-ordinal-group: 12;
		-ms-flex-order: 11;
		order: 11
	}
	.order-sm-12 {
		-webkit-box-ordinal-group: 13;
		-ms-flex-order: 12;
		order: 12
	}
	.offset-sm-0 {
		margin-left: 0
	}
	.offset-sm-1 {
		margin-left: 8.333333%
	}
	.offset-sm-2 {
		margin-left: 16.666667%
	}
	.offset-sm-3 {
		margin-left: 25%
	}
	.offset-sm-4 {
		margin-left: 33.333333%
	}
	.offset-sm-5 {
		margin-left: 41.666667%
	}
	.offset-sm-6 {
		margin-left: 50%
	}
	.offset-sm-7 {
		margin-left: 58.333333%
	}
	.offset-sm-8 {
		margin-left: 66.666667%
	}
	.offset-sm-9 {
		margin-left: 75%
	}
	.offset-sm-10 {
		margin-left: 83.333333%
	}
	.offset-sm-11 {
		margin-left: 91.666667%
	}
}

@media (min-width: 768px) {
	.col-md {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%
	}
	.col-md-auto {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none
	}
	.col-md-1 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%
	}
	.col-md-2 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%
	}
	.col-md-3 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}
	.col-md-4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%
	}
	.col-md-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%
	}
	.col-md-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}
	.col-md-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%
	}
	.col-md-8 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%
	}
	.col-md-9 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%
	}
	.col-md-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%
	}
	.col-md-11 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%
	}
	.col-md-12 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}
	.order-md-first {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1
	}
	.order-md-last {
		-webkit-box-ordinal-group: 14;
		-ms-flex-order: 13;
		order: 13
	}
	.order-md-0 {
		-webkit-box-ordinal-group: 1;
		-ms-flex-order: 0;
		order: 0
	}
	.order-md-1 {
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1
	}
	.order-md-2 {
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2
	}
	.order-md-3 {
		-webkit-box-ordinal-group: 4;
		-ms-flex-order: 3;
		order: 3
	}
	.order-md-4 {
		-webkit-box-ordinal-group: 5;
		-ms-flex-order: 4;
		order: 4
	}
	.order-md-5 {
		-webkit-box-ordinal-group: 6;
		-ms-flex-order: 5;
		order: 5
	}
	.order-md-6 {
		-webkit-box-ordinal-group: 7;
		-ms-flex-order: 6;
		order: 6
	}
	.order-md-7 {
		-webkit-box-ordinal-group: 8;
		-ms-flex-order: 7;
		order: 7
	}
	.order-md-8 {
		-webkit-box-ordinal-group: 9;
		-ms-flex-order: 8;
		order: 8
	}
	.order-md-9 {
		-webkit-box-ordinal-group: 10;
		-ms-flex-order: 9;
		order: 9
	}
	.order-md-10 {
		-webkit-box-ordinal-group: 11;
		-ms-flex-order: 10;
		order: 10
	}
	.order-md-11 {
		-webkit-box-ordinal-group: 12;
		-ms-flex-order: 11;
		order: 11
	}
	.order-md-12 {
		-webkit-box-ordinal-group: 13;
		-ms-flex-order: 12;
		order: 12
	}
	.offset-md-0 {
		margin-left: 0
	}
	.offset-md-1 {
		margin-left: 8.333333%
	}
	.offset-md-2 {
		margin-left: 16.666667%
	}
	.offset-md-3 {
		margin-left: 25%
	}
	.offset-md-4 {
		margin-left: 33.333333%
	}
	.offset-md-5 {
		margin-left: 41.666667%
	}
	.offset-md-6 {
		margin-left: 50%
	}
	.offset-md-7 {
		margin-left: 58.333333%
	}
	.offset-md-8 {
		margin-left: 66.666667%
	}
	.offset-md-9 {
		margin-left: 75%
	}
	.offset-md-10 {
		margin-left: 83.333333%
	}
	.offset-md-11 {
		margin-left: 91.666667%
	}
}

@media (min-width: 992px) {
	.col-lg {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%
	}
	.col-lg-auto {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none
	}
	.col-lg-1 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%
	}
	.col-lg-2 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%
	}
	.col-lg-3 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}
	.col-lg-4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%
	}
	.col-lg-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%
	}
	.col-lg-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}
	.col-lg-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%
	}
	.col-lg-8 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%
	}
	.col-lg-9 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%
	}
	.col-lg-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%
	}
	.col-lg-11 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%
	}
	.col-lg-12 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}
	.order-lg-first {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1
	}
	.order-lg-last {
		-webkit-box-ordinal-group: 14;
		-ms-flex-order: 13;
		order: 13
	}
	.order-lg-0 {
		-webkit-box-ordinal-group: 1;
		-ms-flex-order: 0;
		order: 0
	}
	.order-lg-1 {
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1
	}
	.order-lg-2 {
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2
	}
	.order-lg-3 {
		-webkit-box-ordinal-group: 4;
		-ms-flex-order: 3;
		order: 3
	}
	.order-lg-4 {
		-webkit-box-ordinal-group: 5;
		-ms-flex-order: 4;
		order: 4
	}
	.order-lg-5 {
		-webkit-box-ordinal-group: 6;
		-ms-flex-order: 5;
		order: 5
	}
	.order-lg-6 {
		-webkit-box-ordinal-group: 7;
		-ms-flex-order: 6;
		order: 6
	}
	.order-lg-7 {
		-webkit-box-ordinal-group: 8;
		-ms-flex-order: 7;
		order: 7
	}
	.order-lg-8 {
		-webkit-box-ordinal-group: 9;
		-ms-flex-order: 8;
		order: 8
	}
	.order-lg-9 {
		-webkit-box-ordinal-group: 10;
		-ms-flex-order: 9;
		order: 9
	}
	.order-lg-10 {
		-webkit-box-ordinal-group: 11;
		-ms-flex-order: 10;
		order: 10
	}
	.order-lg-11 {
		-webkit-box-ordinal-group: 12;
		-ms-flex-order: 11;
		order: 11
	}
	.order-lg-12 {
		-webkit-box-ordinal-group: 13;
		-ms-flex-order: 12;
		order: 12
	}
	.offset-lg-0 {
		margin-left: 0
	}
	.offset-lg-1 {
		margin-left: 8.333333%
	}
	.offset-lg-2 {
		margin-left: 16.666667%
	}
	.offset-lg-3 {
		margin-left: 25%
	}
	.offset-lg-4 {
		margin-left: 33.333333%
	}
	.offset-lg-5 {
		margin-left: 41.666667%
	}
	.offset-lg-6 {
		margin-left: 50%
	}
	.offset-lg-7 {
		margin-left: 58.333333%
	}
	.offset-lg-8 {
		margin-left: 66.666667%
	}
	.offset-lg-9 {
		margin-left: 75%
	}
	.offset-lg-10 {
		margin-left: 83.333333%
	}
	.offset-lg-11 {
		margin-left: 91.666667%
	}
}

@media (min-width: 1200px) {
	.col-xl {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%
	}
	.col-xl-auto {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none
	}
	.col-xl-1 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%
	}
	.col-xl-2 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%
	}
	.col-xl-3 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}
	.col-xl-4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%
	}
	.col-xl-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%
	}
	.col-xl-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}
	.col-xl-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%
	}
	.col-xl-8 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%
	}
	.col-xl-9 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%
	}
	.col-xl-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%
	}
	.col-xl-11 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%
	}
	.col-xl-12 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}
	.order-xl-first {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1
	}
	.order-xl-last {
		-webkit-box-ordinal-group: 14;
		-ms-flex-order: 13;
		order: 13
	}
	.order-xl-0 {
		-webkit-box-ordinal-group: 1;
		-ms-flex-order: 0;
		order: 0
	}
	.order-xl-1 {
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1
	}
	.order-xl-2 {
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2
	}
	.order-xl-3 {
		-webkit-box-ordinal-group: 4;
		-ms-flex-order: 3;
		order: 3
	}
	.order-xl-4 {
		-webkit-box-ordinal-group: 5;
		-ms-flex-order: 4;
		order: 4
	}
	.order-xl-5 {
		-webkit-box-ordinal-group: 6;
		-ms-flex-order: 5;
		order: 5
	}
	.order-xl-6 {
		-webkit-box-ordinal-group: 7;
		-ms-flex-order: 6;
		order: 6
	}
	.order-xl-7 {
		-webkit-box-ordinal-group: 8;
		-ms-flex-order: 7;
		order: 7
	}
	.order-xl-8 {
		-webkit-box-ordinal-group: 9;
		-ms-flex-order: 8;
		order: 8
	}
	.order-xl-9 {
		-webkit-box-ordinal-group: 10;
		-ms-flex-order: 9;
		order: 9
	}
	.order-xl-10 {
		-webkit-box-ordinal-group: 11;
		-ms-flex-order: 10;
		order: 10
	}
	.order-xl-11 {
		-webkit-box-ordinal-group: 12;
		-ms-flex-order: 11;
		order: 11
	}
	.order-xl-12 {
		-webkit-box-ordinal-group: 13;
		-ms-flex-order: 12;
		order: 12
	}
	.offset-xl-0 {
		margin-left: 0
	}
	.offset-xl-1 {
		margin-left: 8.333333%
	}
	.offset-xl-2 {
		margin-left: 16.666667%
	}
	.offset-xl-3 {
		margin-left: 25%
	}
	.offset-xl-4 {
		margin-left: 33.333333%
	}
	.offset-xl-5 {
		margin-left: 41.666667%
	}
	.offset-xl-6 {
		margin-left: 50%
	}
	.offset-xl-7 {
		margin-left: 58.333333%
	}
	.offset-xl-8 {
		margin-left: 66.666667%
	}
	.offset-xl-9 {
		margin-left: 75%
	}
	.offset-xl-10 {
		margin-left: 83.333333%
	}
	.offset-xl-11 {
		margin-left: 91.666667%
	}
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
	background-color: transparent
}

.table th,
.table td {
	padding: 10px;
	vertical-align: top;
	border-top: 1px solid #eaecee
}

.table thead th {
	vertical-align: bottom;
	border-bottom: 2px solid #eaecee
}

.table tbody+tbody {
	border-top: 2px solid #eaecee
}

.table .table {
	background-color: #f5f6f7
}

.table-sm th,
.table-sm td {
	padding: 5px
}

.table-bordered {
	border: 1px solid #eaecee
}

.table-bordered th,
.table-bordered td {
	border: 1px solid #eaecee
}

.table-bordered thead th,
.table-bordered thead td {
	border-bottom-width: 2px
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.02)
}

.table-hover tbody tr:hover {
	background-color: rgba(0, 0, 0, 0.04)
}

.table-primary,
.table-primary>th,
.table-primary>td {
	background-color: #cae6fc
}

.table-hover .table-primary:hover {
	background-color: #b2dbfb
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
	background-color: #b2dbfb
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
	background-color: #d6d8db
}

.table-hover .table-secondary:hover {
	background-color: #c8cbcf
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
	background-color: #c8cbcf
}

.table-success,
.table-success>th,
.table-success>td {
	background-color: #e3f1d4
}

.table-hover .table-success:hover {
	background-color: #d6ebc1
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
	background-color: #d6ebc1
}

.table-info,
.table-info>th,
.table-info>td {
	background-color: #c2eff5
}

.table-hover .table-info:hover {
	background-color: #ace9f1
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
	background-color: #ace9f1
}

.table-warning,
.table-warning>th,
.table-warning>td {
	background-color: #fff0c3
}

.table-hover .table-warning:hover {
	background-color: #ffeaaa
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
	background-color: #ffeaaa
}

.table-danger,
.table-danger>th,
.table-danger>td {
	background-color: #fbcfce
}

.table-hover .table-danger:hover {
	background-color: #f9b8b6
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
	background-color: #f9b8b6
}

.table-light,
.table-light>th,
.table-light>td {
	background-color: #fdfdfe
}

.table-hover .table-light:hover {
	background-color: #ececf6
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
	background-color: #ececf6
}

.table-dark,
.table-dark>th,
.table-dark>td {
	background-color: #c6c8ca
}

.table-hover .table-dark:hover {
	background-color: #b9bbbe
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
	background-color: #b9bbbe
}

.table-active,
.table-active>th,
.table-active>td {
	background-color: rgba(0, 0, 0, 0.04)
}

.table-hover .table-active:hover {
	background-color: rgba(0, 0, 0, 0.04)
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
	background-color: rgba(0, 0, 0, 0.04)
}

.table .thead-dark th {
	color: #eaecee;
	background-color: #343a40;
	border-color: #3f474e
}

.table .thead-light th {
	color: #575757;
	background-color: #eaecee;
	border-color: #eaecee
}

.table-dark {
	color: #eaecee;
	background-color: #343a40
}

.table-dark th,
.table-dark td,
.table-dark thead th {
	border-color: #3f474e
}

.table-dark.table-bordered {
	border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.1)
}

.table-dark.table-hover tbody tr:hover {
	background-color: rgba(0, 0, 0, 0.18)
}

@media (max-width: 575.98px) {
	.table-responsive-sm {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar
	}
	.table-responsive-sm>.table-bordered {
		border: 0
	}
}

@media (max-width: 767.98px) {
	.table-responsive-md {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar
	}
	.table-responsive-md>.table-bordered {
		border: 0
	}
}

@media (max-width: 991.98px) {
	.table-responsive-lg {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar
	}
	.table-responsive-lg>.table-bordered {
		border: 0
	}
}

@media (max-width: 1199.98px) {
	.table-responsive-xl {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar
	}
	.table-responsive-xl>.table-bordered {
		border: 0
	}
}

.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar
}

.table-responsive>.table-bordered {
	border: 0
}

.form-control {
	display: block;
	width: 100%;
	padding: 8px 14px;
	font-size: 1rem;
	line-height: 16px;
	color: #575757;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #dcdfe3;
	border-radius: .25rem;
	transition: border-color ease-in-out .15s
}

.form-control::-ms-expand {
	background-color: transparent;
	border: 0
}

.form-control:focus {
	color: #575757;
	background-color: #fff;
	border-color: #a3acb5;
	outline: 0;
	box-shadow: none
}

.form-control::-webkit-input-placeholder {
	color: #6c757d;
	opacity: 1
}

.form-control::-moz-placeholder {
	color: #6c757d;
	opacity: 1
}

.form-control:-ms-input-placeholder {
	color: #6c757d;
	opacity: 1
}

.form-control::-ms-input-placeholder {
	color: #6c757d;
	opacity: 1
}

.form-control::placeholder {
	color: #6c757d;
	opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #e9ecef;
	opacity: 1
}

select.form-control:not([size]):not([multiple]) {
	height: 34px
}

select.form-control:focus::-ms-value {
	color: #575757;
	background-color: #fff
}

.form-control-file,
.form-control-range {
	display: block;
	width: 100%
}

.col-form-label {
	padding-top: calc(8px + 1px);
	padding-bottom: calc(8px + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 16px
}

.col-form-label-lg {
	padding-top: calc(9px + 1px);
	padding-bottom: calc(9px + 1px);
	font-size: 1.285714rem;
	line-height: 20px
}

.col-form-label-sm {
	padding-top: calc(5px + 1px);
	padding-bottom: calc(5px + 1px);
	font-size: .928571rem;
	line-height: 16px
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding-top: 8px;
	padding-bottom: 8px;
	margin-bottom: 0;
	line-height: 16px;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0
}

.form-control-plaintext.form-control-sm,
.input-group-sm>.form-control-plaintext.form-control,
.input-group-sm>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-prepend>.form-control-plaintext.btn,
.input-group-sm>.input-group-append>.form-control-plaintext.btn,
.form-control-plaintext.form-control-lg,
.input-group-lg>.form-control-plaintext.form-control,
.input-group-lg>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-prepend>.form-control-plaintext.btn,
.input-group-lg>.input-group-append>.form-control-plaintext.btn {
	padding-right: 0;
	padding-left: 0
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
	padding: 5px 9px;
	font-size: .928571rem;
	line-height: 16px;
	border-radius: .2rem
}

select.form-control-sm:not([size]):not([multiple]),
.input-group-sm>select.form-control:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.btn:not([size]):not([multiple]) {
	height: 28px
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
	padding: 9px 18px;
	font-size: 1.285714rem;
	line-height: 20px;
	border-radius: .3rem
}

select.form-control-lg:not([size]):not([multiple]),
.input-group-lg>select.form-control:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]) {
	height: 40px
}

.form-group {
	margin-bottom: 16px
}

.form-text {
	display: block;
	margin-top: .25rem
}

.form-row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px
}

.form-row>.col,
.form-row>[class*="col-"] {
	padding-right: 5px;
	padding-left: 5px
}

.form-check {
	position: relative;
	display: block;
	padding-left: 1.25rem
}

.form-check-input {
	position: absolute;
	margin-top: .3rem;
	margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label {
	color: #6c757d
}

.form-check-label {
	margin-bottom: 0
}

.form-check-inline {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 0;
	margin-right: .75rem
}

.form-check-inline .form-check-input {
	position: static;
	margin-top: 0;
	margin-right: .3125rem;
	margin-left: 0
}

.valid-feedback {
	display: none;
	margin-top: .25rem;
	font-size: .875rem;
	color: #9ccc65
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	width: 250px;
	padding: .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1;
	color: #fff;
	background-color: rgba(156, 204, 101, 0.8);
	border-radius: .2rem
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
	border-color: #9ccc65
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip {
	display: block
}

.was-validated .form-check-input:valid+.form-check-label,
.form-check-input.is-valid+.form-check-label {
	color: #9ccc65
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
	color: #9ccc65
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
	background-color: #daecc5
}

.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip,
.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip {
	display: block
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
	background-color: #b5d98b
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
	border-color: #9ccc65
}

.was-validated .custom-file-input:valid~.custom-file-label::before,
.custom-file-input.is-valid~.custom-file-label::before {
	border-color: inherit
}

.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip,
.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip {
	display: block
}

.is-valid .form-control {
	border-color: #9ccc65
}

.is-valid .form-check-input~.form-check-label {
	color: #9ccc65
}

.is-valid .custom-control-input~.custom-control-label {
	color: #9ccc65
}

.is-valid .custom-control-input~.custom-control-label::before {
	background-color: rgba(156, 204, 101, 0.25)
}

.is-valid .valid-feedback,
.is-valid .valid-tooltip {
	display: block
}

.invalid-feedback {
	display: none;
	margin-top: .25rem;
	font-size: .875rem;
	color: #ef5350
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	width: 250px;
	padding: .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1;
	color: #fff;
	background-color: rgba(239, 83, 80, 0.8);
	border-radius: .2rem
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
	border-color: #ef5350
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip {
	display: block
}

.was-validated .form-check-input:invalid+.form-check-label,
.form-check-input.is-invalid+.form-check-label {
	color: #ef5350
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
	color: #ef5350
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
	background-color: #fac6c5
}

.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip,
.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip {
	display: block
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
	background-color: #f3817f
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
	border-color: #ef5350
}

.was-validated .custom-file-input:invalid~.custom-file-label::before,
.custom-file-input.is-invalid~.custom-file-label::before {
	border-color: inherit
}

.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip,
.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip {
	display: block
}

.is-invalid .form-control {
	border-color: #ef5350
}

.is-invalid .form-check-input~.form-check-label {
	color: #ef5350
}

.is-invalid .custom-control-input~.custom-control-label {
	color: #ef5350
}

.is-invalid .custom-control-input~.custom-control-label::before {
	background-color: rgba(239, 83, 80, 0.25)
}

.is-invalid .invalid-feedback,
.is-invalid .invalid-tooltip {
	display: block
}

.form-inline {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.form-inline .form-check {
	width: 100%
}

@media (min-width: 576px) {
	.form-inline label {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin-bottom: 0
	}
	.form-inline .form-group {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-bottom: 0
	}
	.form-inline .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle
	}
	.form-inline .form-control-plaintext {
		display: inline-block
	}
	.form-inline .input-group {
		width: auto
	}
	.form-inline .form-check {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		width: auto;
		padding-left: 0
	}
	.form-inline .form-check-input {
		position: relative;
		margin-top: 0;
		margin-right: .25rem;
		margin-left: 0
	}
	.form-inline .custom-control {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center
	}
	.form-inline .custom-control-label {
		margin-bottom: 0
	}
}

.btn {
	display: inline-block;
	font-weight: 600;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	padding: 8px 14px;
	font-size: 1rem;
	line-height: 16px;
	border-radius: .25rem;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn:hover,
.btn:focus {
	text-decoration: none
}

.btn:focus,
.btn.focus {
	outline: 0;
	box-shadow: none
}

.btn.disabled,
.btn:disabled {
	opacity: 1
}

.btn:not(:disabled):not(.disabled) {
	cursor: pointer
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
	background-image: none
}

a.btn.disabled,
fieldset:disabled a.btn {
	pointer-events: none
}

.btn-primary {
	color: #fff;
	background-color: #42a5f5;
	border-color: #0d89ed
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus {
	color: #fff;
	background-color: #0d89ed;
	border-color: #0a6dbd
}

.btn-primary.disabled,
.btn-primary:disabled {
	background-color: #42a5f5;
	border-color: #0d89ed
}

.btn-primary:not([disabled]):not(.disabled):active {
	background-color: #42a5f5;
	border-color: #0d89ed
}

.btn-primary:not([disabled]):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
	background-color: #0d89ed;
	background-image: none;
	border-color: #0a6dbd
}

.btn-primary.btn-noborder {
	border-color: #42a5f5
}

.btn-primary.btn-noborder:hover,
.btn-primary.btn-noborder:focus,
.btn-primary.btn-noborder.focus {
	border-color: #0d89ed
}

.btn-primary.btn-noborder.disabled,
.btn-primary.btn-noborder:disabled,
.btn-primary.btn-noborder:not([disabled]):not(.disabled):active,
.btn-primary.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: #42a5f5
}

.show>.btn-primary.btn-noborder.dropdown-toggle {
	border-color: #0d89ed
}

.btn-secondary {
	color: #fff;
	background-color: #6c757d;
	border-color: #50565c
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus {
	color: #fff;
	background-color: #50565c;
	border-color: #383d41
}

.btn-secondary.disabled,
.btn-secondary:disabled {
	background-color: #6c757d;
	border-color: #50565c
}

.btn-secondary:not([disabled]):not(.disabled):active {
	background-color: #6c757d;
	border-color: #50565c
}

.btn-secondary:not([disabled]):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
	background-color: #50565c;
	background-image: none;
	border-color: #383d41
}

.btn-secondary.btn-noborder {
	border-color: #6c757d
}

.btn-secondary.btn-noborder:hover,
.btn-secondary.btn-noborder:focus,
.btn-secondary.btn-noborder.focus {
	border-color: #50565c
}

.btn-secondary.btn-noborder.disabled,
.btn-secondary.btn-noborder:disabled,
.btn-secondary.btn-noborder:not([disabled]):not(.disabled):active,
.btn-secondary.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: #6c757d
}

.show>.btn-secondary.btn-noborder.dropdown-toggle {
	border-color: #50565c
}

.btn-success {
	color: #fff;
	background-color: #9ccc65;
	border-color: #7eb73d
}

.btn-success:hover,
.btn-success:focus,
.btn-success.focus {
	color: #fff;
	background-color: #7eb73d;
	border-color: #649130
}

.btn-success.disabled,
.btn-success:disabled {
	background-color: #9ccc65;
	border-color: #7eb73d
}

.btn-success:not([disabled]):not(.disabled):active {
	background-color: #9ccc65;
	border-color: #7eb73d
}

.btn-success:not([disabled]):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
	background-color: #7eb73d;
	background-image: none;
	border-color: #649130
}

.btn-success.btn-noborder {
	border-color: #9ccc65
}

.btn-success.btn-noborder:hover,
.btn-success.btn-noborder:focus,
.btn-success.btn-noborder.focus {
	border-color: #7eb73d
}

.btn-success.btn-noborder.disabled,
.btn-success.btn-noborder:disabled,
.btn-success.btn-noborder:not([disabled]):not(.disabled):active,
.btn-success.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: #9ccc65
}

.show>.btn-success.btn-noborder.dropdown-toggle {
	border-color: #7eb73d
}

.btn-info {
	color: #fff;
	background-color: #26c6da;
	border-color: #1c97a6
}

.btn-info:hover,
.btn-info:focus,
.btn-info.focus {
	color: #fff;
	background-color: #1c97a6;
	border-color: #15707b
}

.btn-info.disabled,
.btn-info:disabled {
	background-color: #26c6da;
	border-color: #1c97a6
}

.btn-info:not([disabled]):not(.disabled):active {
	background-color: #26c6da;
	border-color: #1c97a6
}

.btn-info:not([disabled]):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
	background-color: #1c97a6;
	background-image: none;
	border-color: #15707b
}

.btn-info.btn-noborder {
	border-color: #26c6da
}

.btn-info.btn-noborder:hover,
.btn-info.btn-noborder:focus,
.btn-info.btn-noborder.focus {
	border-color: #1c97a6
}

.btn-info.btn-noborder.disabled,
.btn-info.btn-noborder:disabled,
.btn-info.btn-noborder:not([disabled]):not(.disabled):active,
.btn-info.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: #26c6da
}

.show>.btn-info.btn-noborder.dropdown-toggle {
	border-color: #1c97a6
}

.btn-warning {
	color: #fff;
	background-color: #ffca28;
	border-color: #eab000
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus {
	color: #fff;
	background-color: #eab000;
	border-color: #b78a00
}

.btn-warning.disabled,
.btn-warning:disabled {
	background-color: #ffca28;
	border-color: #eab000
}

.btn-warning:not([disabled]):not(.disabled):active {
	background-color: #ffca28;
	border-color: #eab000
}

.btn-warning:not([disabled]):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
	background-color: #eab000;
	background-image: none;
	border-color: #b78a00
}

.btn-warning.btn-noborder {
	border-color: #ffca28
}

.btn-warning.btn-noborder:hover,
.btn-warning.btn-noborder:focus,
.btn-warning.btn-noborder.focus {
	border-color: #eab000
}

.btn-warning.btn-noborder.disabled,
.btn-warning.btn-noborder:disabled,
.btn-warning.btn-noborder:not([disabled]):not(.disabled):active,
.btn-warning.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: #ffca28
}

.show>.btn-warning.btn-noborder.dropdown-toggle {
	border-color: #eab000
}

.btn-danger {
	color: #fff;
	background-color: #ef5350;
	border-color: #ea1c18
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus {
	color: #fff;
	background-color: #ea1c18;
	border-color: #bd1511
}

.btn-danger.disabled,
.btn-danger:disabled {
	background-color: #ef5350;
	border-color: #ea1c18
}

.btn-danger:not([disabled]):not(.disabled):active {
	background-color: #ef5350;
	border-color: #ea1c18
}

.btn-danger:not([disabled]):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
	background-color: #ea1c18;
	background-image: none;
	border-color: #bd1511
}

.btn-danger.btn-noborder {
	border-color: #ef5350
}

.btn-danger.btn-noborder:hover,
.btn-danger.btn-noborder:focus,
.btn-danger.btn-noborder.focus {
	border-color: #ea1c18
}

.btn-danger.btn-noborder.disabled,
.btn-danger.btn-noborder:disabled,
.btn-danger.btn-noborder:not([disabled]):not(.disabled):active,
.btn-danger.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: #ef5350
}

.show>.btn-danger.btn-noborder.dropdown-toggle {
	border-color: #ea1c18
}

.btn-light {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #d4dae1
}

.btn-light:hover,
.btn-light:focus,
.btn-light.focus {
	color: #212529;
	background-color: #d4dae1;
	border-color: #b7c1cb
}

.btn-light.disabled,
.btn-light:disabled {
	background-color: #f8f9fa;
	border-color: #d4dae1
}

.btn-light:not([disabled]):not(.disabled):active {
	background-color: #f8f9fa;
	border-color: #d4dae1
}

.btn-light:not([disabled]):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
	background-color: #d4dae1;
	background-image: none;
	border-color: #b7c1cb
}

.btn-light.btn-noborder {
	border-color: #f8f9fa
}

.btn-light.btn-noborder:hover,
.btn-light.btn-noborder:focus,
.btn-light.btn-noborder.focus {
	border-color: #d4dae1
}

.btn-light.btn-noborder.disabled,
.btn-light.btn-noborder:disabled,
.btn-light.btn-noborder:not([disabled]):not(.disabled):active,
.btn-light.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: #f8f9fa
}

.show>.btn-light.btn-noborder.dropdown-toggle {
	border-color: #d4dae1
}

.btn-dark {
	color: #fff;
	background-color: #343a40;
	border-color: #191b1e
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark.focus {
	color: #fff;
	background-color: #191b1e;
	border-color: #020202
}

.btn-dark.disabled,
.btn-dark:disabled {
	background-color: #343a40;
	border-color: #191b1e
}

.btn-dark:not([disabled]):not(.disabled):active {
	background-color: #343a40;
	border-color: #191b1e
}

.btn-dark:not([disabled]):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
	background-color: #191b1e;
	background-image: none;
	border-color: #020202
}

.btn-dark.btn-noborder {
	border-color: #343a40
}

.btn-dark.btn-noborder:hover,
.btn-dark.btn-noborder:focus,
.btn-dark.btn-noborder.focus {
	border-color: #191b1e
}

.btn-dark.btn-noborder.disabled,
.btn-dark.btn-noborder:disabled,
.btn-dark.btn-noborder:not([disabled]):not(.disabled):active,
.btn-dark.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: #343a40
}

.show>.btn-dark.btn-noborder.dropdown-toggle {
	border-color: #191b1e
}

.btn-outline-primary {
	color: #42a5f5;
	background-color: transparent;
	background-image: none;
	border-color: #42a5f5
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.focus {
	color: #fff;
	background-color: #42a5f5;
	border-color: #42a5f5
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
	color: #42a5f5;
	background-color: transparent
}

.btn-outline-primary:not([disabled]):not(.disabled):active {
	color: #42a5f5;
	background-color: transparent;
	border-color: #42a5f5
}

.btn-outline-primary:not([disabled]):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
	color: #fff;
	background-color: #42a5f5;
	border-color: #42a5f5
}

.btn-outline-primary.btn-noborder {
	border-color: transparent
}

.btn-outline-primary.btn-noborder:not([disabled]):not(.disabled):active,
.btn-outline-primary.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: transparent
}

.btn-outline-secondary {
	color: #6c757d;
	background-color: transparent;
	background-image: none;
	border-color: #6c757d
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
	color: #6c757d;
	background-color: transparent
}

.btn-outline-secondary:not([disabled]):not(.disabled):active {
	color: #6c757d;
	background-color: transparent;
	border-color: #6c757d
}

.btn-outline-secondary:not([disabled]):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d
}

.btn-outline-secondary.btn-noborder {
	border-color: transparent
}

.btn-outline-secondary.btn-noborder:not([disabled]):not(.disabled):active,
.btn-outline-secondary.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: transparent
}

.btn-outline-success {
	color: #9ccc65;
	background-color: transparent;
	background-image: none;
	border-color: #9ccc65
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success.focus {
	color: #fff;
	background-color: #9ccc65;
	border-color: #9ccc65
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
	color: #9ccc65;
	background-color: transparent
}

.btn-outline-success:not([disabled]):not(.disabled):active {
	color: #9ccc65;
	background-color: transparent;
	border-color: #9ccc65
}

.btn-outline-success:not([disabled]):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
	color: #fff;
	background-color: #9ccc65;
	border-color: #9ccc65
}

.btn-outline-success.btn-noborder {
	border-color: transparent
}

.btn-outline-success.btn-noborder:not([disabled]):not(.disabled):active,
.btn-outline-success.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: transparent
}

.btn-outline-info {
	color: #26c6da;
	background-color: transparent;
	background-image: none;
	border-color: #26c6da
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info.focus {
	color: #fff;
	background-color: #26c6da;
	border-color: #26c6da
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
	color: #26c6da;
	background-color: transparent
}

.btn-outline-info:not([disabled]):not(.disabled):active {
	color: #26c6da;
	background-color: transparent;
	border-color: #26c6da
}

.btn-outline-info:not([disabled]):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
	color: #fff;
	background-color: #26c6da;
	border-color: #26c6da
}

.btn-outline-info.btn-noborder {
	border-color: transparent
}

.btn-outline-info.btn-noborder:not([disabled]):not(.disabled):active,
.btn-outline-info.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: transparent
}

.btn-outline-warning {
	color: #ffca28;
	background-color: transparent;
	background-image: none;
	border-color: #ffca28
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning.focus {
	color: #fff;
	background-color: #ffca28;
	border-color: #ffca28
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
	color: #ffca28;
	background-color: transparent
}

.btn-outline-warning:not([disabled]):not(.disabled):active {
	color: #ffca28;
	background-color: transparent;
	border-color: #ffca28
}

.btn-outline-warning:not([disabled]):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
	color: #fff;
	background-color: #ffca28;
	border-color: #ffca28
}

.btn-outline-warning.btn-noborder {
	border-color: transparent
}

.btn-outline-warning.btn-noborder:not([disabled]):not(.disabled):active,
.btn-outline-warning.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: transparent
}

.btn-outline-danger {
	color: #ef5350;
	background-color: transparent;
	background-image: none;
	border-color: #ef5350
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger.focus {
	color: #fff;
	background-color: #ef5350;
	border-color: #ef5350
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
	color: #ef5350;
	background-color: transparent
}

.btn-outline-danger:not([disabled]):not(.disabled):active {
	color: #ef5350;
	background-color: transparent;
	border-color: #ef5350
}

.btn-outline-danger:not([disabled]):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
	color: #fff;
	background-color: #ef5350;
	border-color: #ef5350
}

.btn-outline-danger.btn-noborder {
	border-color: transparent
}

.btn-outline-danger.btn-noborder:not([disabled]):not(.disabled):active,
.btn-outline-danger.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: transparent
}

.btn-outline-light {
	color: #f8f9fa;
	background-color: transparent;
	background-image: none;
	border-color: #f8f9fa
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light.focus {
	color: #fff;
	background-color: #f8f9fa;
	border-color: #f8f9fa
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
	color: #f8f9fa;
	background-color: transparent
}

.btn-outline-light:not([disabled]):not(.disabled):active {
	color: #f8f9fa;
	background-color: transparent;
	border-color: #f8f9fa
}

.btn-outline-light:not([disabled]):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
	color: #fff;
	background-color: #f8f9fa;
	border-color: #f8f9fa
}

.btn-outline-light.btn-noborder {
	border-color: transparent
}

.btn-outline-light.btn-noborder:not([disabled]):not(.disabled):active,
.btn-outline-light.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: transparent
}

.btn-outline-dark {
	color: #343a40;
	background-color: transparent;
	background-image: none;
	border-color: #343a40
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark.focus {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
	color: #343a40;
	background-color: transparent
}

.btn-outline-dark:not([disabled]):not(.disabled):active {
	color: #343a40;
	background-color: transparent;
	border-color: #343a40
}

.btn-outline-dark:not([disabled]):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40
}

.btn-outline-dark.btn-noborder {
	border-color: transparent
}

.btn-outline-dark.btn-noborder:not([disabled]):not(.disabled):active,
.btn-outline-dark.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: transparent
}

.btn-link {
	font-weight: 400;
	color: #42a5f5;
	background-color: transparent
}

.btn-link:hover {
	color: #0b72c6;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent
}

.btn-link:focus,
.btn-link.focus {
	text-decoration: none;
	border-color: transparent;
	box-shadow: none
}

.btn-link:disabled,
.btn-link.disabled {
	color: #6c757d
}

.btn-lg,
.btn-group-lg>.btn {
	padding: 9px 18px;
	font-size: 1.285714rem;
	line-height: 20px;
	border-radius: .3rem
}

.btn-sm,
.btn-group-sm>.btn {
	padding: 5px 9px;
	font-size: .928571rem;
	line-height: 16px;
	border-radius: .2rem
}

.btn-block {
	display: block;
	width: 100%
}

.btn-block+.btn-block {
	margin-top: .5rem
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
	width: 100%
}

.fade {
	opacity: 0;
	transition: opacity .15s linear
}

.fade.show {
	opacity: 1
}

.collapse {
	display: none
}

.collapse.show {
	display: block
}

tr.collapse.show {
	display: table-row
}

tbody.collapse.show {
	display: table-row-group
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height .35s ease
}

.dropup,
.dropdown {
	position: relative
}

.dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: .2125em;
	vertical-align: .2125em;
	content: "";
	border-top: .25em solid;
	border-right: .25em solid transparent;
	border-bottom: 0;
	border-left: .25em solid transparent
}

.dropdown-toggle:empty::after {
	margin-left: 0
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 8px 0;
	margin: .125rem 0 0;
	font-size: 1rem;
	color: #575757;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #eaecee;
	border-radius: 2px
}

.dropup .dropdown-menu {
	margin-top: 0;
	margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: .2125em;
	vertical-align: .2125em;
	content: "";
	border-top: 0;
	border-right: .25em solid transparent;
	border-bottom: .25em solid;
	border-left: .25em solid transparent
}

.dropup .dropdown-toggle:empty::after {
	margin-left: 0
}

.dropright .dropdown-menu {
	margin-top: 0;
	margin-left: .125rem
}

.dropright .dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: .2125em;
	vertical-align: .2125em;
	content: "";
	border-top: .25em solid transparent;
	border-bottom: .25em solid transparent;
	border-left: .25em solid
}

.dropright .dropdown-toggle:empty::after {
	margin-left: 0
}

.dropright .dropdown-toggle::after {
	vertical-align: 0
}

.dropleft .dropdown-menu {
	margin-top: 0;
	margin-right: .125rem
}

.dropleft .dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: .2125em;
	vertical-align: .2125em;
	content: "";
	display: none
}

.dropleft .dropdown-toggle::before {
	display: inline-block;
	width: 0;
	height: 0;
	margin-right: .2125em;
	vertical-align: .2125em;
	content: "";
	border-top: .25em solid transparent;
	border-right: .25em solid;
	border-bottom: .25em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
	margin-left: 0
}

.dropleft .dropdown-toggle::before {
	vertical-align: 0
}

.dropdown-divider {
	height: 0;
	margin: .5rem 0;
	overflow: hidden;
	border-top: 1px solid #eaecee
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 5px 10px;
	clear: both;
	font-weight: 400;
	color: #575757;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0
}

.dropdown-item:hover,
.dropdown-item:focus {
	color: #16181b;
	text-decoration: none;
	background-color: #f5f6f7
}

.dropdown-item.active,
.dropdown-item:active {
	color: #575757;
	text-decoration: none;
	background-color: #eaecee
}

.dropdown-item.disabled,
.dropdown-item:disabled {
	color: #6c757d;
	background-color: transparent
}

.dropdown-menu.show {
	display: block
}

.dropdown-header {
	display: block;
	padding: 8px 10px;
	margin-bottom: 0;
	font-size: .928571rem;
	color: #171717;
	white-space: nowrap
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: middle
}

.btn-group>.btn,
.btn-group-vertical>.btn {
	position: relative;
	-webkit-box-flex: 0;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
	z-index: 1
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
	z-index: 1
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn+.btn-group,
.btn-group-vertical .btn-group+.btn,
.btn-group-vertical .btn-group+.btn-group {
	margin-left: -1px
}

.btn-toolbar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start
}

.btn-toolbar .input-group {
	width: auto
}

.btn-group>.btn:first-child {
	margin-left: 0
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.dropdown-toggle-split {
	padding-right: 10.5px;
	padding-left: 10.5px
}

.dropdown-toggle-split::after {
	margin-left: 0
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
	padding-right: 6.75px;
	padding-left: 6.75px
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
	padding-right: 13.5px;
	padding-left: 13.5px
}

.btn-group-vertical {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
	width: 100%
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
	margin-top: -1px;
	margin-left: 0
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
	border-top-left-radius: 0;
	border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
	margin-bottom: 0
}

.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none
}

.input-group {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%
}

.input-group>.form-control,
.input-group>.custom-select,
.input-group>.custom-file {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file:focus {
	z-index: 3
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
	margin-left: -1px
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.input-group>.custom-file {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::before {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label,
.input-group>.custom-file:not(:first-child) .custom-file-label::before {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.input-group-prepend,
.input-group-append {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.input-group-prepend .btn,
.input-group-append .btn {
	position: relative;
	z-index: 2
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
	margin-left: -1px
}

.input-group-prepend {
	margin-right: -1px
}

.input-group-append {
	margin-left: -1px
}

.input-group-text {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 8px 14px;
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 16px;
	color: #575757;
	text-align: center;
	white-space: nowrap;
	background-color: #fbfbfc;
	border: 1px solid #dcdfe3;
	border-radius: .25rem
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
	margin-top: 0
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.custom-control {
	position: relative;
	display: block;
	min-height: 1.5rem;
	padding-left: 24px
}

.custom-control-inline {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-right: 16px
}

.custom-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
	color: #fff;
	background-color: #42a5f5
}

.custom-control-input:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px #f5f6f7, 0 0 0 3px #42a5f5
}

.custom-control-input:active~.custom-control-label::before {
	color: #fff;
	background-color: #ecf6fe
}

.custom-control-input:disabled~.custom-control-label {
	color: #6c757d
}

.custom-control-input:disabled~.custom-control-label::before {
	background-color: #e9ecef
}

.custom-control-label {
	margin-bottom: 0
}

.custom-control-label::before {
	position: absolute;
	top: -7.25px;
	left: 0;
	display: block;
	width: 16px;
	height: 16px;
	pointer-events: none;
	content: "";
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: #dee2e6
}

.custom-control-label::after {
	position: absolute;
	top: -7.25px;
	left: 0;
	display: block;
	width: 16px;
	height: 16px;
	content: "";
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%
}

.custom-checkbox .custom-control-label::before {
	border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
	background-color: #42a5f5
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
	background-color: #42a5f5
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
	background-color: rgba(66, 165, 245, 0.5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
	background-color: rgba(66, 165, 245, 0.5)
}

.custom-radio .custom-control-label::before {
	border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
	background-color: #42a5f5
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
	background-color: rgba(66, 165, 245, 0.5)
}

.custom-select {
	display: inline-block;
	width: 100%;
	height: 34px;
	padding: .375rem 1.75rem .375rem .75rem;
	line-height: 16px;
	color: #575757;
	vertical-align: middle;
	background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
	background-size: 8px 10px;
	border: 1px solid #dcdfe3;
	border-radius: .25rem;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none
}

.custom-select:focus {
	border-color: #bbdffb;
	outline: 0;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(187, 223, 251, 0.5)
}

.custom-select:focus::-ms-value {
	color: #575757;
	background-color: #fff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
	height: auto;
	padding-right: .75rem;
	background-image: none
}

.custom-select:disabled {
	color: #6c757d;
	background-color: #e9ecef
}

.custom-select::-ms-expand {
	opacity: 0
}

.custom-select-sm {
	height: 28px;
	padding-top: .375rem;
	padding-bottom: .375rem;
	font-size: 75%
}

.custom-select-lg {
	height: 40px;
	padding-top: .375rem;
	padding-bottom: .375rem;
	font-size: 125%
}

.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 34px;
	margin-bottom: 0
}

.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: 34px;
	margin: 0;
	opacity: 0
}

.custom-file-input:focus~.custom-file-control {
	border-color: #a3acb5;
	box-shadow: none
}

.custom-file-input:focus~.custom-file-control::before {
	border-color: #a3acb5
}

.custom-file-input:lang(en)~.custom-file-label::after {
	content: "Browse"
}

.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: 34px;
	padding: 8px 14px;
	line-height: 16px;
	color: #575757;
	background-color: #fff;
	border: 1px solid #dcdfe3;
	border-radius: .25rem
}

.custom-file-label::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	display: block;
	height: calc(34px - 1px * 2);
	padding: 8px 14px;
	line-height: 16px;
	color: #575757;
	content: "Browse";
	background-color: #fbfbfc;
	border-left: 1px solid #dcdfe3;
	border-radius: 0 .25rem .25rem 0
}

.nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none
}

.nav-link {
	display: block;
	padding: .5rem 1rem
}

.nav-link:hover,
.nav-link:focus {
	text-decoration: none
}

.nav-link.disabled {
	color: #6c757d
}

.nav-tabs {
	border-bottom: 1px solid #eaecee
}

.nav-tabs .nav-item {
	margin-bottom: -1px
}

.nav-tabs .nav-link {
	border: 1px solid transparent;
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: #eaecee
}

.nav-tabs .nav-link.disabled {
	color: #6c757d;
	background-color: transparent;
	border-color: transparent
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #575757;
	background-color: #fff;
	border-color: #eaecee
}

.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0
}

.nav-pills .nav-link {
	border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	color: #fff;
	background-color: #42a5f5
}

.nav-fill .nav-item {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	text-align: center
}

.nav-justified .nav-item {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	text-align: center
}

.tab-content>.tab-pane {
	display: none
}

.tab-content>.active {
	display: block
}

.breadcrumb {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 9px 14px;
	margin-bottom: 1rem;
	list-style: none;
	background-color: #f5f6f7;
	border-radius: .25rem
}

.breadcrumb-item+.breadcrumb-item::before {
	display: inline-block;
	padding-right: .5rem;
	padding-left: .5rem;
	color: #6c757d;
	content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
	text-decoration: underline;
	text-decoration: none
}

.breadcrumb-item.active {
	color: #171717
}

.pagination {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: .25rem
}

.page-link {
	position: relative;
	display: block;
	padding: 8px 10px;
	margin-left: -1px;
	line-height: 16px;
	color: #171717;
	background-color: #f5f6f7;
	border: 1px solid #f5f6f7
}

.page-link:hover {
	color: #171717;
	text-decoration: none;
	background-color: #cdd2d7;
	border-color: #cdd2d7
}

.page-link:focus {
	z-index: 2;
	outline: 0;
	box-shadow: none
}

.page-link:not(:disabled):not(.disabled) {
	cursor: pointer
}

.page-item:first-child .page-link {
	margin-left: 0;
	border-top-left-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
	border-top-right-radius: .25rem;
	border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
	z-index: 1;
	color: #fff;
	background-color: #42a5f5;
	border-color: #42a5f5
}

.page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	cursor: auto;
	background-color: #fff;
	border-color: #fff
}

.pagination-lg .page-link {
	padding: 9px 20px;
	font-size: 1.285714rem;
	line-height: 20px
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: .3rem;
	border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: .3rem;
	border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
	padding: 5px 7px;
	font-size: .928571rem;
	line-height: 16px
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: .2rem;
	border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: .2rem;
	border-bottom-right-radius: .2rem
}

.badge {
	display: inline-block;
	padding: 3px 5px;
	font-size: 85%;
	font-weight: 600;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25rem
}

.badge:empty {
	display: none
}

.btn .badge {
	position: relative;
	top: -1px
}

.badge-pill {
	padding-right: .6em;
	padding-left: .6em;
	border-radius: 10rem
}

.badge-primary {
	color: #fff;
	background-color: #42a5f5
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #128ef2
}

.badge-secondary {
	color: #fff;
	background-color: #6c757d
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #545b62
}

.badge-success {
	color: #fff;
	background-color: #9ccc65
}

.badge-success[href]:hover,
.badge-success[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #83bf3f
}

.badge-info {
	color: #fff;
	background-color: #26c6da
}

.badge-info[href]:hover,
.badge-info[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #1e9faf
}

.badge-warning {
	color: #fff;
	background-color: #ffca28
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #f4b800
}

.badge-danger {
	color: #fff;
	background-color: #ef5350
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #eb2521
}

.badge-light {
	color: #212529;
	background-color: #f8f9fa
}

.badge-light[href]:hover,
.badge-light[href]:focus {
	color: #212529;
	text-decoration: none;
	background-color: #dae0e5
}

.badge-dark {
	color: #fff;
	background-color: #343a40
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #1d2124
}

.alert {
	position: relative;
	padding: .75rem 1.25rem;
	margin-bottom: 24px;
	border: 0 solid transparent;
	border-radius: .25rem
}

.alert-heading {
	color: inherit
}

.alert-link {
	font-weight: 600
}

.alert-dismissible {
	padding-right: 4rem
}

.alert-dismissible .close {
	position: absolute;
	top: 0;
	right: 0;
	padding: .75rem 1.25rem;
	color: inherit
}

.alert-primary {
	color: #22567f;
	background-color: #d9edfd;
	border-color: #cae6fc
}

.alert-primary hr {
	border-top-color: #b2dbfb
}

.alert-primary .alert-link {
	color: #173b57
}

.alert-secondary {
	color: #383d41;
	background-color: #e2e3e5;
	border-color: #d6d8db
}

.alert-secondary hr {
	border-top-color: #c8cbcf
}

.alert-secondary .alert-link {
	color: #202326
}

.alert-success {
	color: #516a35;
	background-color: #ebf5e0;
	border-color: #e3f1d4
}

.alert-success hr {
	border-top-color: #d6ebc1
}

.alert-success .alert-link {
	color: #374824
}

.alert-info {
	color: #146771;
	background-color: #d4f4f8;
	border-color: #c2eff5
}

.alert-info hr {
	border-top-color: #ace9f1
}

.alert-info .alert-link {
	color: #0c4046
}

.alert-warning {
	color: #856915;
	background-color: #fff4d4;
	border-color: #fff0c3
}

.alert-warning hr {
	border-top-color: #ffeaaa
}

.alert-warning .alert-link {
	color: #59460e
}

.alert-danger {
	color: #7c2b2a;
	background-color: #fcdddc;
	border-color: #fbcfce
}

.alert-danger hr {
	border-top-color: #f9b8b6
}

.alert-danger .alert-link {
	color: #561e1d
}

.alert-light {
	color: #818182;
	background-color: #fefefe;
	border-color: #fdfdfe
}

.alert-light hr {
	border-top-color: #ececf6
}

.alert-light .alert-link {
	color: #686868
}

.alert-dark {
	color: #1b1e21;
	background-color: #d6d8d9;
	border-color: #c6c8ca
}

.alert-dark hr {
	border-top-color: #b9bbbe
}

.alert-dark .alert-link {
	color: #040505
}

@-webkit-keyframes progress-bar-stripes {
	from {
		background-position: 20px 0
	}
	to {
		background-position: 0 0
	}
}

@keyframes progress-bar-stripes {
	from {
		background-position: 20px 0
	}
	to {
		background-position: 0 0
	}
}

.progress {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 20px;
	overflow: hidden;
	font-size: 1rem;
	background-color: #e9ecef;
	border-radius: 0
}

.progress-bar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #fff;
	text-align: center;
	background-color: #42a5f5;
	transition: width .6s ease
}

.progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 20px 20px
}

.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite
}

.media {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start
}

.media-body {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1
}

.list-group {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0
}

.list-group-item-action {
	width: 100%;
	color: #575757;
	text-align: inherit
}

.list-group-item-action:hover,
.list-group-item-action:focus {
	color: #575757;
	text-decoration: none;
	background-color: #fbfbfc
}

.list-group-item-action:active {
	color: #575757;
	background-color: #eaecee
}

.list-group-item {
	position: relative;
	display: block;
	padding: .75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid #eaecee
}

.list-group-item:first-child {
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem
}

.list-group-item:last-child {
	margin-bottom: 0;
	border-bottom-right-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.list-group-item:hover,
.list-group-item:focus {
	z-index: 1;
	text-decoration: none
}

.list-group-item.disabled,
.list-group-item:disabled {
	color: #6c757d;
	background-color: #fff
}

.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #42a5f5;
	border-color: #42a5f5
}

.list-group-flush .list-group-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0
}

.list-group-flush:first-child .list-group-item:first-child {
	border-top: 0
}

.list-group-flush:last-child .list-group-item:last-child {
	border-bottom: 0
}

.list-group-item-primary {
	color: #22567f;
	background-color: #cae6fc
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
	color: #22567f;
	background-color: #b2dbfb
}

.list-group-item-primary.list-group-item-action.active {
	color: #fff;
	background-color: #22567f;
	border-color: #22567f
}

.list-group-item-secondary {
	color: #383d41;
	background-color: #d6d8db
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
	color: #383d41;
	background-color: #c8cbcf
}

.list-group-item-secondary.list-group-item-action.active {
	color: #fff;
	background-color: #383d41;
	border-color: #383d41
}

.list-group-item-success {
	color: #516a35;
	background-color: #e3f1d4
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
	color: #516a35;
	background-color: #d6ebc1
}

.list-group-item-success.list-group-item-action.active {
	color: #fff;
	background-color: #516a35;
	border-color: #516a35
}

.list-group-item-info {
	color: #146771;
	background-color: #c2eff5
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
	color: #146771;
	background-color: #ace9f1
}

.list-group-item-info.list-group-item-action.active {
	color: #fff;
	background-color: #146771;
	border-color: #146771
}

.list-group-item-warning {
	color: #856915;
	background-color: #fff0c3
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
	color: #856915;
	background-color: #ffeaaa
}

.list-group-item-warning.list-group-item-action.active {
	color: #fff;
	background-color: #856915;
	border-color: #856915
}

.list-group-item-danger {
	color: #7c2b2a;
	background-color: #fbcfce
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
	color: #7c2b2a;
	background-color: #f9b8b6
}

.list-group-item-danger.list-group-item-action.active {
	color: #fff;
	background-color: #7c2b2a;
	border-color: #7c2b2a
}

.list-group-item-light {
	color: #818182;
	background-color: #fdfdfe
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
	color: #818182;
	background-color: #ececf6
}

.list-group-item-light.list-group-item-action.active {
	color: #fff;
	background-color: #818182;
	border-color: #818182
}

.list-group-item-dark {
	color: #1b1e21;
	background-color: #c6c8ca
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
	color: #1b1e21;
	background-color: #b9bbbe
}

.list-group-item-dark.list-group-item-action.active {
	color: #fff;
	background-color: #1b1e21;
	border-color: #1b1e21
}

.close {
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: .5
}

.close:hover,
.close:focus {
	color: #000;
	text-decoration: none;
	opacity: .75
}

.close:not(:disabled):not(.disabled) {
	cursor: pointer
}

button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none
}

.modal-open {
	overflow: hidden
}

.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	display: none;
	overflow: hidden;
	outline: 0
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: .5rem;
	pointer-events: none
}

.modal.fade .modal-dialog {
	transition: -webkit-transform .3s ease-out;
	transition: transform .3s ease-out;
	transition: transform .3s ease-out, -webkit-transform .3s ease-out;
	-webkit-transform: translate(0, -25%);
	transform: translate(0, -25%)
}

.modal.show .modal-dialog {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0)
}

.modal-dialog-centered {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	min-height: calc(100% - (0.5rem * 2))
}

.modal-content {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 0 solid rgba(0, 0, 0, 0.2);
	border-radius: .3rem;
	outline: 0
}

.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #000
}

.modal-backdrop.fade {
	opacity: 0
}

.modal-backdrop.show {
	opacity: .6
}

.modal-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 24px;
	border-bottom: 0 solid #eaecee;
	border-top-left-radius: .3rem;
	border-top-right-radius: .3rem
}

.modal-header .close {
	padding: 24px;
	margin: -24px -24px -24px auto
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.5
}

.modal-body {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 18px
}

.modal-footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding: 18px;
	border-top: 0 solid #eaecee
}

.modal-footer> :not(:first-child) {
	margin-left: .25rem
}

.modal-footer> :not(:last-child) {
	margin-right: .25rem
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto
	}
	.modal-dialog-centered {
		min-height: calc(100% - (1.75rem * 2))
	}
	.modal-sm {
		max-width: 300px
	}
}

@media (min-width: 992px) {
	.modal-lg {
		max-width: 800px
	}
}

.tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	margin: 0;
	font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: .928571rem;
	word-wrap: break-word;
	opacity: 0
}

.tooltip.show {
	opacity: .9
}

.tooltip .arrow {
	position: absolute;
	display: block;
	width: .8rem;
	height: .4rem
}

.tooltip .arrow::before {
	position: absolute;
	content: "";
	border-color: transparent;
	border-style: solid
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
	padding: .4rem 0
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
	bottom: 0
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
	top: 0;
	border-width: .4rem .4rem 0;
	border-top-color: #343a40
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
	padding: 0 .4rem
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
	left: 0;
	width: .4rem;
	height: .8rem
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
	right: 0;
	border-width: .4rem .4rem .4rem 0;
	border-right-color: #343a40
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
	padding: .4rem 0
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
	top: 0
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
	bottom: 0;
	border-width: 0 .4rem .4rem;
	border-bottom-color: #343a40
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
	padding: 0 .4rem
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
	right: 0;
	width: .4rem;
	height: .8rem
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
	left: 0;
	border-width: .4rem 0 .4rem .4rem;
	border-left-color: #343a40
}

.tooltip-inner {
	max-width: 200px;
	padding: 4px 10px;
	color: #fff;
	text-align: center;
	background-color: #343a40;
	border-radius: .25rem
}

.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1060;
	display: block;
	max-width: 276px;
	font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: .928571rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #eaecee;
	border-radius: .3rem
}

.popover .arrow {
	position: absolute;
	display: block;
	width: 1rem;
	height: .5rem;
	margin: 0 .3rem
}

.popover .arrow::before,
.popover .arrow::after {
	position: absolute;
	display: block;
	content: "";
	border-color: transparent;
	border-style: solid
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
	margin-bottom: .5rem
}

.bs-popover-top .arrow,
.bs-popover-auto[x-placement^="top"] .arrow {
	bottom: calc((0.5rem + 1px) * -1)
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
	border-width: .5rem .5rem 0
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
	bottom: 0;
	border-top-color: #eaecee
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
	bottom: 1px;
	border-top-color: #fff
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
	margin-left: .5rem
}

.bs-popover-right .arrow,
.bs-popover-auto[x-placement^="right"] .arrow {
	left: calc((0.5rem + 1px) * -1);
	width: .5rem;
	height: 1rem;
	margin: .3rem 0
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
	border-width: .5rem .5rem .5rem 0
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
	left: 0;
	border-right-color: #eaecee
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
	left: 1px;
	border-right-color: #fff
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
	margin-top: .5rem
}

.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^="bottom"] .arrow {
	top: calc((0.5rem + 1px) * -1)
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	border-width: 0 .5rem .5rem
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
	top: 0;
	border-bottom-color: #eaecee
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	top: 1px;
	border-bottom-color: #fff
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 1rem;
	margin-left: -.5rem;
	content: "";
	border-bottom: 1px solid #fff
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
	margin-right: .5rem
}

.bs-popover-left .arrow,
.bs-popover-auto[x-placement^="left"] .arrow {
	right: calc((0.5rem + 1px) * -1);
	width: .5rem;
	height: 1rem;
	margin: .3rem 0
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
	border-width: .5rem 0 .5rem .5rem
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
	right: 0;
	border-left-color: #eaecee
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
	right: 1px;
	border-left-color: #fff
}

.popover-header {
	padding: 12px 10px;
	margin-bottom: 0;
	font-size: 1rem;
	color: #171717;
	background-color: #fff;
	border-bottom: 1px solid #f2f2f2;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px)
}

.popover-header:empty {
	display: none
}

.popover-body {
	padding: 10px;
	color: #575757
}

.align-baseline {
	vertical-align: baseline!important
}

.align-top {
	vertical-align: top!important
}

.align-middle {
	vertical-align: middle!important
}

.align-bottom {
	vertical-align: bottom!important
}

.align-text-bottom {
	vertical-align: text-bottom!important
}

.align-text-top {
	vertical-align: text-top!important
}

.bg-primary {
	background-color: #42a5f5!important
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
	background-color: #128ef2!important
}

.bg-secondary {
	background-color: #6c757d!important
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
	background-color: #545b62!important
}

.bg-success {
	background-color: #9ccc65!important
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
	background-color: #83bf3f!important
}

.bg-info {
	background-color: #26c6da!important
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
	background-color: #1e9faf!important
}

.bg-warning {
	background-color: #ffca28!important
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
	background-color: #f4b800!important
}

.bg-danger {
	background-color: #ef5350!important
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
	background-color: #eb2521!important
}

.bg-light {
	background-color: #f8f9fa!important
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
	background-color: #dae0e5!important
}

.bg-dark {
	background-color: #282c3f!important
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
	background-color: #1d2124!important
}

.bg-white {
	background-color: #fff!important
}

.bg-transparent {
	background-color: transparent!important
}

.border {
	border: 1px solid #f2f2f2!important
}

.border-top {
	border-top: 1px solid #f2f2f2!important
}

.border-right {
	border-right: 1px solid #f2f2f2!important
}

.border-bottom {
	border-bottom: 1px solid #f2f2f2!important
}

.border-left {
	border-left: 1px solid #f2f2f2!important
}

.border-0 {
	border: 0!important
}

.border-top-0 {
	border-top: 0!important
}

.border-right-0 {
	border-right: 0!important
}

.border-bottom-0 {
	border-bottom: 0!important
}

.border-left-0 {
	border-left: 0!important
}

.border-primary {
	border-color: #42a5f5!important
}

.border-secondary {
	border-color: #6c757d!important
}

.border-success {
	border-color: #9ccc65!important
}

.border-info {
	border-color: #26c6da!important
}

.border-warning {
	border-color: #ffca28!important
}

.border-danger {
	border-color: #ef5350!important
}

.border-light {
	border-color: #f8f9fa!important
}

.border-dark {
	border-color: #343a40!important
}

.border-white {
	border-color: #fff!important
}

.rounded {
	border-radius: .25rem!important
}

.rounded-top {
	border-top-left-radius: .25rem!important;
	border-top-right-radius: .25rem!important
}

.rounded-right {
	border-top-right-radius: .25rem!important;
	border-bottom-right-radius: .25rem!important
}

.rounded-bottom {
	border-bottom-right-radius: .25rem!important;
	border-bottom-left-radius: .25rem!important
}

.rounded-left {
	border-top-left-radius: .25rem!important;
	border-bottom-left-radius: .25rem!important
}

.rounded-circle {
	border-radius: 50%!important
}

.rounded-0 {
	border-radius: 0!important
}

.clearfix::after {
	display: block;
	clear: both;
	content: ""
}

.d-none {
	display: none!important
}

.d-inline {
	display: inline!important
}

.d-inline-block {
	display: inline-block!important
}

.d-block {
	display: block!important
}

.d-table {
	display: table!important
}

.d-table-row {
	display: table-row!important
}

.d-table-cell {
	display: table-cell!important
}

.d-flex {
	display: -webkit-box!important;
	display: -ms-flexbox!important;
	display: flex!important
}

.d-inline-flex {
	display: -webkit-inline-box!important;
	display: -ms-inline-flexbox!important;
	display: inline-flex!important
}

@media (min-width: 576px) {
	.d-sm-none {
		display: none!important
	}
	.d-sm-inline {
		display: inline!important
	}
	.d-sm-inline-block {
		display: inline-block!important
	}
	.d-sm-block {
		display: block!important
	}
	.d-sm-table {
		display: table!important
	}
	.d-sm-table-row {
		display: table-row!important
	}
	.d-sm-table-cell {
		display: table-cell!important
	}
	.d-sm-flex {
		display: -webkit-box!important;
		display: -ms-flexbox!important;
		display: flex!important
	}
	.d-sm-inline-flex {
		display: -webkit-inline-box!important;
		display: -ms-inline-flexbox!important;
		display: inline-flex!important
	}
}

@media (min-width: 768px) {
	.d-md-none {
		display: none!important
	}
	.d-md-inline {
		display: inline!important
	}
	.d-md-inline-block {
		display: inline-block!important
	}
	.d-md-block {
		display: block!important
	}
	.d-md-table {
		display: table!important
	}
	.d-md-table-row {
		display: table-row!important
	}
	.d-md-table-cell {
		display: table-cell!important
	}
	.d-md-flex {
		display: -webkit-box!important;
		display: -ms-flexbox!important;
		display: flex!important
	}
	.d-md-inline-flex {
		display: -webkit-inline-box!important;
		display: -ms-inline-flexbox!important;
		display: inline-flex!important
	}
}

@media (min-width: 992px) {
	.d-lg-none {
		display: none!important
	}
	.d-lg-inline {
		display: inline!important
	}
	.d-lg-inline-block {
		display: inline-block!important
	}
	.d-lg-block {
		display: block!important
	}
	.d-lg-table {
		display: table!important
	}
	.d-lg-table-row {
		display: table-row!important
	}
	.d-lg-table-cell {
		display: table-cell!important
	}
	.d-lg-flex {
		display: -webkit-box!important;
		display: -ms-flexbox!important;
		display: flex!important
	}
	.d-lg-inline-flex {
		display: -webkit-inline-box!important;
		display: -ms-inline-flexbox!important;
		display: inline-flex!important
	}
}

@media (min-width: 1200px) {
	.d-xl-none {
		display: none!important
	}
	.d-xl-inline {
		display: inline!important
	}
	.d-xl-inline-block {
		display: inline-block!important
	}
	.d-xl-block {
		display: block!important
	}
	.d-xl-table {
		display: table!important
	}
	.d-xl-table-row {
		display: table-row!important
	}
	.d-xl-table-cell {
		display: table-cell!important
	}
	.d-xl-flex {
		display: -webkit-box!important;
		display: -ms-flexbox!important;
		display: flex!important
	}
	.d-xl-inline-flex {
		display: -webkit-inline-box!important;
		display: -ms-inline-flexbox!important;
		display: inline-flex!important
	}
}

@media print {
	.d-print-none {
		display: none!important
	}
	.d-print-inline {
		display: inline!important
	}
	.d-print-inline-block {
		display: inline-block!important
	}
	.d-print-block {
		display: block!important
	}
	.d-print-table {
		display: table!important
	}
	.d-print-table-row {
		display: table-row!important
	}
	.d-print-table-cell {
		display: table-cell!important
	}
	.d-print-flex {
		display: -webkit-box!important;
		display: -ms-flexbox!important;
		display: flex!important
	}
	.d-print-inline-flex {
		display: -webkit-inline-box!important;
		display: -ms-inline-flexbox!important;
		display: inline-flex!important
	}
}

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden
}

.embed-responsive::before {
	display: block;
	content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0
}

.embed-responsive-21by9::before {
	padding-top: 42.857143%
}

.embed-responsive-16by9::before {
	padding-top: 56.25%
}

.embed-responsive-4by3::before {
	padding-top: 75%
}

.embed-responsive-1by1::before {
	padding-top: 100%
}

.flex-row {
	-webkit-box-orient: horizontal!important;
	-webkit-box-direction: normal!important;
	-ms-flex-direction: row!important;
	flex-direction: row!important
}

.flex-column {
	-webkit-box-orient: vertical!important;
	-webkit-box-direction: normal!important;
	-ms-flex-direction: column!important;
	flex-direction: column!important
}

.flex-row-reverse {
	-webkit-box-orient: horizontal!important;
	-webkit-box-direction: reverse!important;
	-ms-flex-direction: row-reverse!important;
	flex-direction: row-reverse!important
}

.flex-column-reverse {
	-webkit-box-orient: vertical!important;
	-webkit-box-direction: reverse!important;
	-ms-flex-direction: column-reverse!important;
	flex-direction: column-reverse!important
}

.flex-wrap {
	-ms-flex-wrap: wrap!important;
	flex-wrap: wrap!important
}

.flex-nowrap {
	-ms-flex-wrap: nowrap!important;
	flex-wrap: nowrap!important
}

.flex-wrap-reverse {
	-ms-flex-wrap: wrap-reverse!important;
	flex-wrap: wrap-reverse!important
}

.justify-content-start {
	-webkit-box-pack: start!important;
	-ms-flex-pack: start!important;
	justify-content: flex-start!important
}

.justify-content-end {
	-webkit-box-pack: end!important;
	-ms-flex-pack: end!important;
	justify-content: flex-end!important
}

.justify-content-center {
	-webkit-box-pack: center!important;
	-ms-flex-pack: center!important;
	justify-content: center!important
}

.justify-content-between {
	-webkit-box-pack: justify!important;
	-ms-flex-pack: justify!important;
	justify-content: space-between!important
}

.justify-content-around {
	-ms-flex-pack: distribute!important;
	justify-content: space-around!important
}

.align-items-start {
	-webkit-box-align: start!important;
	-ms-flex-align: start!important;
	align-items: flex-start!important
}

.align-items-end {
	-webkit-box-align: end!important;
	-ms-flex-align: end!important;
	align-items: flex-end!important
}

.align-items-center {
	-webkit-box-align: center!important;
	-ms-flex-align: center!important;
	align-items: center!important
}

.align-items-baseline {
	-webkit-box-align: baseline!important;
	-ms-flex-align: baseline!important;
	align-items: baseline!important
}

.align-items-stretch {
	-webkit-box-align: stretch!important;
	-ms-flex-align: stretch!important;
	align-items: stretch!important
}

.align-content-start {
	-ms-flex-line-pack: start!important;
	align-content: flex-start!important
}

.align-content-end {
	-ms-flex-line-pack: end!important;
	align-content: flex-end!important
}

.align-content-center {
	-ms-flex-line-pack: center!important;
	align-content: center!important
}

.align-content-between {
	-ms-flex-line-pack: justify!important;
	align-content: space-between!important
}

.align-content-around {
	-ms-flex-line-pack: distribute!important;
	align-content: space-around!important
}

.align-content-stretch {
	-ms-flex-line-pack: stretch!important;
	align-content: stretch!important
}

.align-self-auto {
	-ms-flex-item-align: auto!important;
	align-self: auto!important
}

.align-self-start {
	-ms-flex-item-align: start!important;
	align-self: flex-start!important
}

.align-self-end {
	-ms-flex-item-align: end!important;
	align-self: flex-end!important
}

.align-self-center {
	-ms-flex-item-align: center!important;
	align-self: center!important
}

.align-self-baseline {
	-ms-flex-item-align: baseline!important;
	align-self: baseline!important
}

.align-self-stretch {
	-ms-flex-item-align: stretch!important;
	align-self: stretch!important
}

@media (min-width: 576px) {
	.flex-sm-row {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: normal!important;
		-ms-flex-direction: row!important;
		flex-direction: row!important
	}
	.flex-sm-column {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: normal!important;
		-ms-flex-direction: column!important;
		flex-direction: column!important
	}
	.flex-sm-row-reverse {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: reverse!important;
		-ms-flex-direction: row-reverse!important;
		flex-direction: row-reverse!important
	}
	.flex-sm-column-reverse {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: reverse!important;
		-ms-flex-direction: column-reverse!important;
		flex-direction: column-reverse!important
	}
	.flex-sm-wrap {
		-ms-flex-wrap: wrap!important;
		flex-wrap: wrap!important
	}
	.flex-sm-nowrap {
		-ms-flex-wrap: nowrap!important;
		flex-wrap: nowrap!important
	}
	.flex-sm-wrap-reverse {
		-ms-flex-wrap: wrap-reverse!important;
		flex-wrap: wrap-reverse!important
	}
	.justify-content-sm-start {
		-webkit-box-pack: start!important;
		-ms-flex-pack: start!important;
		justify-content: flex-start!important
	}
	.justify-content-sm-end {
		-webkit-box-pack: end!important;
		-ms-flex-pack: end!important;
		justify-content: flex-end!important
	}
	.justify-content-sm-center {
		-webkit-box-pack: center!important;
		-ms-flex-pack: center!important;
		justify-content: center!important
	}
	.justify-content-sm-between {
		-webkit-box-pack: justify!important;
		-ms-flex-pack: justify!important;
		justify-content: space-between!important
	}
	.justify-content-sm-around {
		-ms-flex-pack: distribute!important;
		justify-content: space-around!important
	}
	.align-items-sm-start {
		-webkit-box-align: start!important;
		-ms-flex-align: start!important;
		align-items: flex-start!important
	}
	.align-items-sm-end {
		-webkit-box-align: end!important;
		-ms-flex-align: end!important;
		align-items: flex-end!important
	}
	.align-items-sm-center {
		-webkit-box-align: center!important;
		-ms-flex-align: center!important;
		align-items: center!important
	}
	.align-items-sm-baseline {
		-webkit-box-align: baseline!important;
		-ms-flex-align: baseline!important;
		align-items: baseline!important
	}
	.align-items-sm-stretch {
		-webkit-box-align: stretch!important;
		-ms-flex-align: stretch!important;
		align-items: stretch!important
	}
	.align-content-sm-start {
		-ms-flex-line-pack: start!important;
		align-content: flex-start!important
	}
	.align-content-sm-end {
		-ms-flex-line-pack: end!important;
		align-content: flex-end!important
	}
	.align-content-sm-center {
		-ms-flex-line-pack: center!important;
		align-content: center!important
	}
	.align-content-sm-between {
		-ms-flex-line-pack: justify!important;
		align-content: space-between!important
	}
	.align-content-sm-around {
		-ms-flex-line-pack: distribute!important;
		align-content: space-around!important
	}
	.align-content-sm-stretch {
		-ms-flex-line-pack: stretch!important;
		align-content: stretch!important
	}
	.align-self-sm-auto {
		-ms-flex-item-align: auto!important;
		align-self: auto!important
	}
	.align-self-sm-start {
		-ms-flex-item-align: start!important;
		align-self: flex-start!important
	}
	.align-self-sm-end {
		-ms-flex-item-align: end!important;
		align-self: flex-end!important
	}
	.align-self-sm-center {
		-ms-flex-item-align: center!important;
		align-self: center!important
	}
	.align-self-sm-baseline {
		-ms-flex-item-align: baseline!important;
		align-self: baseline!important
	}
	.align-self-sm-stretch {
		-ms-flex-item-align: stretch!important;
		align-self: stretch!important
	}
}

@media (min-width: 768px) {
	.flex-md-row {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: normal!important;
		-ms-flex-direction: row!important;
		flex-direction: row!important
	}
	.flex-md-column {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: normal!important;
		-ms-flex-direction: column!important;
		flex-direction: column!important
	}
	.flex-md-row-reverse {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: reverse!important;
		-ms-flex-direction: row-reverse!important;
		flex-direction: row-reverse!important
	}
	.flex-md-column-reverse {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: reverse!important;
		-ms-flex-direction: column-reverse!important;
		flex-direction: column-reverse!important
	}
	.flex-md-wrap {
		-ms-flex-wrap: wrap!important;
		flex-wrap: wrap!important
	}
	.flex-md-nowrap {
		-ms-flex-wrap: nowrap!important;
		flex-wrap: nowrap!important
	}
	.flex-md-wrap-reverse {
		-ms-flex-wrap: wrap-reverse!important;
		flex-wrap: wrap-reverse!important
	}
	.justify-content-md-start {
		-webkit-box-pack: start!important;
		-ms-flex-pack: start!important;
		justify-content: flex-start!important
	}
	.justify-content-md-end {
		-webkit-box-pack: end!important;
		-ms-flex-pack: end!important;
		justify-content: flex-end!important
	}
	.justify-content-md-center {
		-webkit-box-pack: center!important;
		-ms-flex-pack: center!important;
		justify-content: center!important
	}
	.justify-content-md-between {
		-webkit-box-pack: justify!important;
		-ms-flex-pack: justify!important;
		justify-content: space-between!important
	}
	.justify-content-md-around {
		-ms-flex-pack: distribute!important;
		justify-content: space-around!important
	}
	.align-items-md-start {
		-webkit-box-align: start!important;
		-ms-flex-align: start!important;
		align-items: flex-start!important
	}
	.align-items-md-end {
		-webkit-box-align: end!important;
		-ms-flex-align: end!important;
		align-items: flex-end!important
	}
	.align-items-md-center {
		-webkit-box-align: center!important;
		-ms-flex-align: center!important;
		align-items: center!important
	}
	.align-items-md-baseline {
		-webkit-box-align: baseline!important;
		-ms-flex-align: baseline!important;
		align-items: baseline!important
	}
	.align-items-md-stretch {
		-webkit-box-align: stretch!important;
		-ms-flex-align: stretch!important;
		align-items: stretch!important
	}
	.align-content-md-start {
		-ms-flex-line-pack: start!important;
		align-content: flex-start!important
	}
	.align-content-md-end {
		-ms-flex-line-pack: end!important;
		align-content: flex-end!important
	}
	.align-content-md-center {
		-ms-flex-line-pack: center!important;
		align-content: center!important
	}
	.align-content-md-between {
		-ms-flex-line-pack: justify!important;
		align-content: space-between!important
	}
	.align-content-md-around {
		-ms-flex-line-pack: distribute!important;
		align-content: space-around!important
	}
	.align-content-md-stretch {
		-ms-flex-line-pack: stretch!important;
		align-content: stretch!important
	}
	.align-self-md-auto {
		-ms-flex-item-align: auto!important;
		align-self: auto!important
	}
	.align-self-md-start {
		-ms-flex-item-align: start!important;
		align-self: flex-start!important
	}
	.align-self-md-end {
		-ms-flex-item-align: end!important;
		align-self: flex-end!important
	}
	.align-self-md-center {
		-ms-flex-item-align: center!important;
		align-self: center!important
	}
	.align-self-md-baseline {
		-ms-flex-item-align: baseline!important;
		align-self: baseline!important
	}
	.align-self-md-stretch {
		-ms-flex-item-align: stretch!important;
		align-self: stretch!important
	}
}

@media (min-width: 992px) {
	.flex-lg-row {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: normal!important;
		-ms-flex-direction: row!important;
		flex-direction: row!important
	}
	.flex-lg-column {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: normal!important;
		-ms-flex-direction: column!important;
		flex-direction: column!important
	}
	.flex-lg-row-reverse {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: reverse!important;
		-ms-flex-direction: row-reverse!important;
		flex-direction: row-reverse!important
	}
	.flex-lg-column-reverse {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: reverse!important;
		-ms-flex-direction: column-reverse!important;
		flex-direction: column-reverse!important
	}
	.flex-lg-wrap {
		-ms-flex-wrap: wrap!important;
		flex-wrap: wrap!important
	}
	.flex-lg-nowrap {
		-ms-flex-wrap: nowrap!important;
		flex-wrap: nowrap!important
	}
	.flex-lg-wrap-reverse {
		-ms-flex-wrap: wrap-reverse!important;
		flex-wrap: wrap-reverse!important
	}
	.justify-content-lg-start {
		-webkit-box-pack: start!important;
		-ms-flex-pack: start!important;
		justify-content: flex-start!important
	}
	.justify-content-lg-end {
		-webkit-box-pack: end!important;
		-ms-flex-pack: end!important;
		justify-content: flex-end!important
	}
	.justify-content-lg-center {
		-webkit-box-pack: center!important;
		-ms-flex-pack: center!important;
		justify-content: center!important
	}
	.justify-content-lg-between {
		-webkit-box-pack: justify!important;
		-ms-flex-pack: justify!important;
		justify-content: space-between!important
	}
	.justify-content-lg-around {
		-ms-flex-pack: distribute!important;
		justify-content: space-around!important
	}
	.align-items-lg-start {
		-webkit-box-align: start!important;
		-ms-flex-align: start!important;
		align-items: flex-start!important
	}
	.align-items-lg-end {
		-webkit-box-align: end!important;
		-ms-flex-align: end!important;
		align-items: flex-end!important
	}
	.align-items-lg-center {
		-webkit-box-align: center!important;
		-ms-flex-align: center!important;
		align-items: center!important
	}
	.align-items-lg-baseline {
		-webkit-box-align: baseline!important;
		-ms-flex-align: baseline!important;
		align-items: baseline!important
	}
	.align-items-lg-stretch {
		-webkit-box-align: stretch!important;
		-ms-flex-align: stretch!important;
		align-items: stretch!important
	}
	.align-content-lg-start {
		-ms-flex-line-pack: start!important;
		align-content: flex-start!important
	}
	.align-content-lg-end {
		-ms-flex-line-pack: end!important;
		align-content: flex-end!important
	}
	.align-content-lg-center {
		-ms-flex-line-pack: center!important;
		align-content: center!important
	}
	.align-content-lg-between {
		-ms-flex-line-pack: justify!important;
		align-content: space-between!important
	}
	.align-content-lg-around {
		-ms-flex-line-pack: distribute!important;
		align-content: space-around!important
	}
	.align-content-lg-stretch {
		-ms-flex-line-pack: stretch!important;
		align-content: stretch!important
	}
	.align-self-lg-auto {
		-ms-flex-item-align: auto!important;
		align-self: auto!important
	}
	.align-self-lg-start {
		-ms-flex-item-align: start!important;
		align-self: flex-start!important
	}
	.align-self-lg-end {
		-ms-flex-item-align: end!important;
		align-self: flex-end!important
	}
	.align-self-lg-center {
		-ms-flex-item-align: center!important;
		align-self: center!important
	}
	.align-self-lg-baseline {
		-ms-flex-item-align: baseline!important;
		align-self: baseline!important
	}
	.align-self-lg-stretch {
		-ms-flex-item-align: stretch!important;
		align-self: stretch!important
	}
}

@media (min-width: 1200px) {
	.flex-xl-row {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: normal!important;
		-ms-flex-direction: row!important;
		flex-direction: row!important
	}
	.flex-xl-column {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: normal!important;
		-ms-flex-direction: column!important;
		flex-direction: column!important
	}
	.flex-xl-row-reverse {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: reverse!important;
		-ms-flex-direction: row-reverse!important;
		flex-direction: row-reverse!important
	}
	.flex-xl-column-reverse {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: reverse!important;
		-ms-flex-direction: column-reverse!important;
		flex-direction: column-reverse!important
	}
	.flex-xl-wrap {
		-ms-flex-wrap: wrap!important;
		flex-wrap: wrap!important
	}
	.flex-xl-nowrap {
		-ms-flex-wrap: nowrap!important;
		flex-wrap: nowrap!important
	}
	.flex-xl-wrap-reverse {
		-ms-flex-wrap: wrap-reverse!important;
		flex-wrap: wrap-reverse!important
	}
	.justify-content-xl-start {
		-webkit-box-pack: start!important;
		-ms-flex-pack: start!important;
		justify-content: flex-start!important
	}
	.justify-content-xl-end {
		-webkit-box-pack: end!important;
		-ms-flex-pack: end!important;
		justify-content: flex-end!important
	}
	.justify-content-xl-center {
		-webkit-box-pack: center!important;
		-ms-flex-pack: center!important;
		justify-content: center!important
	}
	.justify-content-xl-between {
		-webkit-box-pack: justify!important;
		-ms-flex-pack: justify!important;
		justify-content: space-between!important
	}
	.justify-content-xl-around {
		-ms-flex-pack: distribute!important;
		justify-content: space-around!important
	}
	.align-items-xl-start {
		-webkit-box-align: start!important;
		-ms-flex-align: start!important;
		align-items: flex-start!important
	}
	.align-items-xl-end {
		-webkit-box-align: end!important;
		-ms-flex-align: end!important;
		align-items: flex-end!important
	}
	.align-items-xl-center {
		-webkit-box-align: center!important;
		-ms-flex-align: center!important;
		align-items: center!important
	}
	.align-items-xl-baseline {
		-webkit-box-align: baseline!important;
		-ms-flex-align: baseline!important;
		align-items: baseline!important
	}
	.align-items-xl-stretch {
		-webkit-box-align: stretch!important;
		-ms-flex-align: stretch!important;
		align-items: stretch!important
	}
	.align-content-xl-start {
		-ms-flex-line-pack: start!important;
		align-content: flex-start!important
	}
	.align-content-xl-end {
		-ms-flex-line-pack: end!important;
		align-content: flex-end!important
	}
	.align-content-xl-center {
		-ms-flex-line-pack: center!important;
		align-content: center!important
	}
	.align-content-xl-between {
		-ms-flex-line-pack: justify!important;
		align-content: space-between!important
	}
	.align-content-xl-around {
		-ms-flex-line-pack: distribute!important;
		align-content: space-around!important
	}
	.align-content-xl-stretch {
		-ms-flex-line-pack: stretch!important;
		align-content: stretch!important
	}
	.align-self-xl-auto {
		-ms-flex-item-align: auto!important;
		align-self: auto!important
	}
	.align-self-xl-start {
		-ms-flex-item-align: start!important;
		align-self: flex-start!important
	}
	.align-self-xl-end {
		-ms-flex-item-align: end!important;
		align-self: flex-end!important
	}
	.align-self-xl-center {
		-ms-flex-item-align: center!important;
		align-self: center!important
	}
	.align-self-xl-baseline {
		-ms-flex-item-align: baseline!important;
		align-self: baseline!important
	}
	.align-self-xl-stretch {
		-ms-flex-item-align: stretch!important;
		align-self: stretch!important
	}
}

.float-left {
	float: left!important
}

.float-right {
	float: right!important
}

.float-none {
	float: none!important
}

@media (min-width: 576px) {
	.float-sm-left {
		float: left!important
	}
	.float-sm-right {
		float: right!important
	}
	.float-sm-none {
		float: none!important
	}
}

@media (min-width: 768px) {
	.float-md-left {
		float: left!important
	}
	.float-md-right {
		float: right!important
	}
	.float-md-none {
		float: none!important
	}
}

@media (min-width: 992px) {
	.float-lg-left {
		float: left!important
	}
	.float-lg-right {
		float: right!important
	}
	.float-lg-none {
		float: none!important
	}
}

@media (min-width: 1200px) {
	.float-xl-left {
		float: left!important
	}
	.float-xl-right {
		float: right!important
	}
	.float-xl-none {
		float: none!important
	}
}

.position-static {
	position: static!important
}

.position-relative {
	position: relative!important
}

.position-absolute {
	position: absolute!important
}

.position-fixed {
	position: fixed!important
}

.position-sticky {
	position: -webkit-sticky!important;
	position: sticky!important
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	-webkit-clip-path: inset(50%);
	clip-path: inset(50%);
	border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal;
	-webkit-clip-path: none;
	clip-path: none
}

.w-25 {
	width: 25%!important
}

.w-50 {
	width: 50%!important
}

.w-75 {
	width: 75%!important
}

.w-100 {
	width: 100%!important
}

.h-25 {
	height: 25%!important
}

.h-50 {
	height: 50%!important
}

.h-75 {
	height: 75%!important
}

.h-100 {
	height: 100%!important
}

.mw-100 {
	max-width: 100%!important
}

.mh-100 {
	max-height: 100%!important
}

.m-0 {
	margin: 0!important
}

.mt-0,
.my-0 {
	margin-top: 0!important
}

.mr-0,
.mx-0 {
	margin-right: 0!important
}

.mb-0,
.my-0 {
	margin-bottom: 0!important
}

.ml-0,
.mx-0 {
	margin-left: 0!important
}

.m-1 {
	margin: .25rem!important
}

.mt-1,
.my-1 {
	margin-top: .25rem!important
}

.mr-1,
.mx-1 {
	margin-right: .25rem!important
}

.mb-1,
.my-1 {
	margin-bottom: .25rem!important
}

.ml-1,
.mx-1 {
	margin-left: .25rem!important
}

.m-2 {
	margin: .5rem!important
}

.mt-2,
.my-2 {
	margin-top: .5rem!important
}

.mr-2,
.mx-2 {
	margin-right: .5rem!important
}

.mb-2,
.my-2 {
	margin-bottom: .5rem!important
}

.ml-2,
.mx-2 {
	margin-left: .5rem!important
}

.m-3 {
	margin: 1rem!important
}

.mt-3,
.my-3 {
	margin-top: 1rem!important
}

.mr-3,
.mx-3 {
	margin-right: 1rem!important
}

.mb-3,
.my-3 {
	margin-bottom: 1rem!important
}

.ml-3,
.mx-3 {
	margin-left: 1rem!important
}

.m-4 {
	margin: 1.5rem!important
}

.mt-4,
.my-4 {
	margin-top: 1.5rem!important
}

.mr-4,
.mx-4 {
	margin-right: 1.5rem!important
}

.mb-4,
.my-4 {
	margin-bottom: 1.5rem!important
}

.ml-4,
.mx-4 {
	margin-left: 1.5rem!important
}

.m-5 {
	margin: 3rem!important
}

.mt-5,
.my-5 {
	margin-top: 3rem!important
}

.mr-5,
.mx-5 {
	margin-right: 3rem!important
}

.mb-5,
.my-5 {
	margin-bottom: 3rem!important
}

.ml-5,
.mx-5 {
	margin-left: 3rem!important
}

.p-0 {
	padding: 0!important
}

.pt-0,
.py-0 {
	padding-top: 0!important
}

.pr-0,
.px-0 {
	padding-right: 0!important
}

.pb-0,
.py-0 {
	padding-bottom: 0!important
}

.pl-0,
.px-0 {
	padding-left: 0!important
}

.p-1 {
	padding: .25rem!important
}

.pt-1,
.py-1 {
	padding-top: .25rem!important
}

.pr-1,
.px-1 {
	padding-right: .25rem!important
}

.pb-1,
.py-1 {
	padding-bottom: .25rem!important
}

.pl-1,
.px-1 {
	padding-left: .25rem!important
}

.p-2 {
	padding: .5rem!important
}

.pt-2,
.py-2 {
	padding-top: .5rem!important
}

.pr-2,
.px-2 {
	padding-right: .5rem!important
}

.pb-2,
.py-2 {
	padding-bottom: .5rem!important
}

.pl-2,
.px-2 {
	padding-left: .5rem!important
}

.p-3 {
	padding: 1rem!important
}

.pt-3,
.py-3 {
	padding-top: 1rem!important
}

.pr-3,
.px-3 {
	padding-right: 1rem!important
}

.pb-3,
.py-3 {
	padding-bottom: 1rem!important
}

.pl-3,
.px-3 {
	padding-left: 1rem!important
}

.p-4 {
	padding: 1.5rem!important
}

.pt-4,
.py-4 {
	padding-top: 1.5rem!important
}

.pr-4,
.px-4 {
	padding-right: 1.5rem!important
}

.pb-4,
.py-4 {
	padding-bottom: 1.5rem!important
}

.pl-4,
.px-4 {
	padding-left: 1.5rem!important
}

.p-5 {
	padding: 3rem!important
}

.pt-5,
.py-5 {
	padding-top: 3rem!important
}

.pr-5,
.px-5 {
	padding-right: 3rem!important
}

.pb-5,
.py-5 {
	padding-bottom: 3rem!important
}

.pl-5,
.px-5 {
	padding-left: 3rem!important
}

.m-auto {
	margin: auto!important
}

.mt-auto,
.my-auto {
	margin-top: auto!important
}

.mr-auto,
.mx-auto {
	margin-right: auto!important
}

.mb-auto,
.my-auto {
	margin-bottom: auto!important
}

.ml-auto,
.mx-auto {
	margin-left: auto!important
}

@media (min-width: 576px) {
	.m-sm-0 {
		margin: 0!important
	}
	.mt-sm-0,
	.my-sm-0 {
		margin-top: 0!important
	}
	.mr-sm-0,
	.mx-sm-0 {
		margin-right: 0!important
	}
	.mb-sm-0,
	.my-sm-0 {
		margin-bottom: 0!important
	}
	.ml-sm-0,
	.mx-sm-0 {
		margin-left: 0!important
	}
	.m-sm-1 {
		margin: .25rem!important
	}
	.mt-sm-1,
	.my-sm-1 {
		margin-top: .25rem!important
	}
	.mr-sm-1,
	.mx-sm-1 {
		margin-right: .25rem!important
	}
	.mb-sm-1,
	.my-sm-1 {
		margin-bottom: .25rem!important
	}
	.ml-sm-1,
	.mx-sm-1 {
		margin-left: .25rem!important
	}
	.m-sm-2 {
		margin: .5rem!important
	}
	.mt-sm-2,
	.my-sm-2 {
		margin-top: .5rem!important
	}
	.mr-sm-2,
	.mx-sm-2 {
		margin-right: .5rem!important
	}
	.mb-sm-2,
	.my-sm-2 {
		margin-bottom: .5rem!important
	}
	.ml-sm-2,
	.mx-sm-2 {
		margin-left: .5rem!important
	}
	.m-sm-3 {
		margin: 1rem!important
	}
	.mt-sm-3,
	.my-sm-3 {
		margin-top: 1rem!important
	}
	.mr-sm-3,
	.mx-sm-3 {
		margin-right: 1rem!important
	}
	.mb-sm-3,
	.my-sm-3 {
		margin-bottom: 1rem!important
	}
	.ml-sm-3,
	.mx-sm-3 {
		margin-left: 1rem!important
	}
	.m-sm-4 {
		margin: 1.5rem!important
	}
	.mt-sm-4,
	.my-sm-4 {
		margin-top: 1.5rem!important
	}
	.mr-sm-4,
	.mx-sm-4 {
		margin-right: 1.5rem!important
	}
	.mb-sm-4,
	.my-sm-4 {
		margin-bottom: 1.5rem!important
	}
	.ml-sm-4,
	.mx-sm-4 {
		margin-left: 1.5rem!important
	}
	.m-sm-5 {
		margin: 3rem!important
	}
	.mt-sm-5,
	.my-sm-5 {
		margin-top: 3rem!important
	}
	.mr-sm-5,
	.mx-sm-5 {
		margin-right: 3rem!important
	}
	.mb-sm-5,
	.my-sm-5 {
		margin-bottom: 3rem!important
	}
	.ml-sm-5,
	.mx-sm-5 {
		margin-left: 3rem!important
	}
	.p-sm-0 {
		padding: 0!important
	}
	.pt-sm-0,
	.py-sm-0 {
		padding-top: 0!important
	}
	.pr-sm-0,
	.px-sm-0 {
		padding-right: 0!important
	}
	.pb-sm-0,
	.py-sm-0 {
		padding-bottom: 0!important
	}
	.pl-sm-0,
	.px-sm-0 {
		padding-left: 0!important
	}
	.p-sm-1 {
		padding: .25rem!important
	}
	.pt-sm-1,
	.py-sm-1 {
		padding-top: .25rem!important
	}
	.pr-sm-1,
	.px-sm-1 {
		padding-right: .25rem!important
	}
	.pb-sm-1,
	.py-sm-1 {
		padding-bottom: .25rem!important
	}
	.pl-sm-1,
	.px-sm-1 {
		padding-left: .25rem!important
	}
	.p-sm-2 {
		padding: .5rem!important
	}
	.pt-sm-2,
	.py-sm-2 {
		padding-top: .5rem!important
	}
	.pr-sm-2,
	.px-sm-2 {
		padding-right: .5rem!important
	}
	.pb-sm-2,
	.py-sm-2 {
		padding-bottom: .5rem!important
	}
	.pl-sm-2,
	.px-sm-2 {
		padding-left: .5rem!important
	}
	.p-sm-3 {
		padding: 1rem!important
	}
	.pt-sm-3,
	.py-sm-3 {
		padding-top: 1rem!important
	}
	.pr-sm-3,
	.px-sm-3 {
		padding-right: 1rem!important
	}
	.pb-sm-3,
	.py-sm-3 {
		padding-bottom: 1rem!important
	}
	.pl-sm-3,
	.px-sm-3 {
		padding-left: 1rem!important
	}
	.p-sm-4 {
		padding: 1.5rem!important
	}
	.pt-sm-4,
	.py-sm-4 {
		padding-top: 1.5rem!important
	}
	.pr-sm-4,
	.px-sm-4 {
		padding-right: 1.5rem!important
	}
	.pb-sm-4,
	.py-sm-4 {
		padding-bottom: 1.5rem!important
	}
	.pl-sm-4,
	.px-sm-4 {
		padding-left: 1.5rem!important
	}
	.p-sm-5 {
		padding: 3rem!important
	}
	.pt-sm-5,
	.py-sm-5 {
		padding-top: 3rem!important
	}
	.pr-sm-5,
	.px-sm-5 {
		padding-right: 3rem!important
	}
	.pb-sm-5,
	.py-sm-5 {
		padding-bottom: 3rem!important
	}
	.pl-sm-5,
	.px-sm-5 {
		padding-left: 3rem!important
	}
	.m-sm-auto {
		margin: auto!important
	}
	.mt-sm-auto,
	.my-sm-auto {
		margin-top: auto!important
	}
	.mr-sm-auto,
	.mx-sm-auto {
		margin-right: auto!important
	}
	.mb-sm-auto,
	.my-sm-auto {
		margin-bottom: auto!important
	}
	.ml-sm-auto,
	.mx-sm-auto {
		margin-left: auto!important
	}
}

@media (min-width: 768px) {
	.m-md-0 {
		margin: 0!important
	}
	.mt-md-0,
	.my-md-0 {
		margin-top: 0!important
	}
	.mr-md-0,
	.mx-md-0 {
		margin-right: 0!important
	}
	.mb-md-0,
	.my-md-0 {
		margin-bottom: 0!important
	}
	.ml-md-0,
	.mx-md-0 {
		margin-left: 0!important
	}
	.m-md-1 {
		margin: .25rem!important
	}
	.mt-md-1,
	.my-md-1 {
		margin-top: .25rem!important
	}
	.mr-md-1,
	.mx-md-1 {
		margin-right: .25rem!important
	}
	.mb-md-1,
	.my-md-1 {
		margin-bottom: .25rem!important
	}
	.ml-md-1,
	.mx-md-1 {
		margin-left: .25rem!important
	}
	.m-md-2 {
		margin: .5rem!important
	}
	.mt-md-2,
	.my-md-2 {
		margin-top: .5rem!important
	}
	.mr-md-2,
	.mx-md-2 {
		margin-right: .5rem!important
	}
	.mb-md-2,
	.my-md-2 {
		margin-bottom: .5rem!important
	}
	.ml-md-2,
	.mx-md-2 {
		margin-left: .5rem!important
	}
	.m-md-3 {
		margin: 1rem!important
	}
	.mt-md-3,
	.my-md-3 {
		margin-top: 1rem!important
	}
	.mr-md-3,
	.mx-md-3 {
		margin-right: 1rem!important
	}
	.mb-md-3,
	.my-md-3 {
		margin-bottom: 1rem!important
	}
	.ml-md-3,
	.mx-md-3 {
		margin-left: 1rem!important
	}
	.m-md-4 {
		margin: 1.5rem!important
	}
	.mt-md-4,
	.my-md-4 {
		margin-top: 1.5rem!important
	}
	.mr-md-4,
	.mx-md-4 {
		margin-right: 1.5rem!important
	}
	.mb-md-4,
	.my-md-4 {
		margin-bottom: 1.5rem!important
	}
	.ml-md-4,
	.mx-md-4 {
		margin-left: 1.5rem!important
	}
	.m-md-5 {
		margin: 3rem!important
	}
	.mt-md-5,
	.my-md-5 {
		margin-top: 3rem!important
	}
	.mr-md-5,
	.mx-md-5 {
		margin-right: 3rem!important
	}
	.mb-md-5,
	.my-md-5 {
		margin-bottom: 3rem!important
	}
	.ml-md-5,
	.mx-md-5 {
		margin-left: 3rem!important
	}
	.p-md-0 {
		padding: 0!important
	}
	.pt-md-0,
	.py-md-0 {
		padding-top: 0!important
	}
	.pr-md-0,
	.px-md-0 {
		padding-right: 0!important
	}
	.pb-md-0,
	.py-md-0 {
		padding-bottom: 0!important
	}
	.pl-md-0,
	.px-md-0 {
		padding-left: 0!important
	}
	.p-md-1 {
		padding: .25rem!important
	}
	.pt-md-1,
	.py-md-1 {
		padding-top: .25rem!important
	}
	.pr-md-1,
	.px-md-1 {
		padding-right: .25rem!important
	}
	.pb-md-1,
	.py-md-1 {
		padding-bottom: .25rem!important
	}
	.pl-md-1,
	.px-md-1 {
		padding-left: .25rem!important
	}
	.p-md-2 {
		padding: .5rem!important
	}
	.pt-md-2,
	.py-md-2 {
		padding-top: .5rem!important
	}
	.pr-md-2,
	.px-md-2 {
		padding-right: .5rem!important
	}
	.pb-md-2,
	.py-md-2 {
		padding-bottom: .5rem!important
	}
	.pl-md-2,
	.px-md-2 {
		padding-left: .5rem!important
	}
	.p-md-3 {
		padding: 1rem!important
	}
	.pt-md-3,
	.py-md-3 {
		padding-top: 1rem!important
	}
	.pr-md-3,
	.px-md-3 {
		padding-right: 1rem!important
	}
	.pb-md-3,
	.py-md-3 {
		padding-bottom: 1rem!important
	}
	.pl-md-3,
	.px-md-3 {
		padding-left: 1rem!important
	}
	.p-md-4 {
		padding: 1.5rem!important
	}
	.pt-md-4,
	.py-md-4 {
		padding-top: 1.5rem!important
	}
	.pr-md-4,
	.px-md-4 {
		padding-right: 1.5rem!important
	}
	.pb-md-4,
	.py-md-4 {
		padding-bottom: 1.5rem!important
	}
	.pl-md-4,
	.px-md-4 {
		padding-left: 1.5rem!important
	}
	.p-md-5 {
		padding: 3rem!important
	}
	.pt-md-5,
	.py-md-5 {
		padding-top: 3rem!important
	}
	.pr-md-5,
	.px-md-5 {
		padding-right: 3rem!important
	}
	.pb-md-5,
	.py-md-5 {
		padding-bottom: 3rem!important
	}
	.pl-md-5,
	.px-md-5 {
		padding-left: 3rem!important
	}
	.m-md-auto {
		margin: auto!important
	}
	.mt-md-auto,
	.my-md-auto {
		margin-top: auto!important
	}
	.mr-md-auto,
	.mx-md-auto {
		margin-right: auto!important
	}
	.mb-md-auto,
	.my-md-auto {
		margin-bottom: auto!important
	}
	.ml-md-auto,
	.mx-md-auto {
		margin-left: auto!important
	}
}

@media (min-width: 992px) {
	.m-lg-0 {
		margin: 0!important
	}
	.mt-lg-0,
	.my-lg-0 {
		margin-top: 0!important
	}
	.mr-lg-0,
	.mx-lg-0 {
		margin-right: 0!important
	}
	.mb-lg-0,
	.my-lg-0 {
		margin-bottom: 0!important
	}
	.ml-lg-0,
	.mx-lg-0 {
		margin-left: 0!important
	}
	.m-lg-1 {
		margin: .25rem!important
	}
	.mt-lg-1,
	.my-lg-1 {
		margin-top: .25rem!important
	}
	.mr-lg-1,
	.mx-lg-1 {
		margin-right: .25rem!important
	}
	.mb-lg-1,
	.my-lg-1 {
		margin-bottom: .25rem!important
	}
	.ml-lg-1,
	.mx-lg-1 {
		margin-left: .25rem!important
	}
	.m-lg-2 {
		margin: .5rem!important
	}
	.mt-lg-2,
	.my-lg-2 {
		margin-top: .5rem!important
	}
	.mr-lg-2,
	.mx-lg-2 {
		margin-right: .5rem!important
	}
	.mb-lg-2,
	.my-lg-2 {
		margin-bottom: .5rem!important
	}
	.ml-lg-2,
	.mx-lg-2 {
		margin-left: .5rem!important
	}
	.m-lg-3 {
		margin: 1rem!important
	}
	.mt-lg-3,
	.my-lg-3 {
		margin-top: 1rem!important
	}
	.mr-lg-3,
	.mx-lg-3 {
		margin-right: 1rem!important
	}
	.mb-lg-3,
	.my-lg-3 {
		margin-bottom: 1rem!important
	}
	.ml-lg-3,
	.mx-lg-3 {
		margin-left: 1rem!important
	}
	.m-lg-4 {
		margin: 1.5rem!important
	}
	.mt-lg-4,
	.my-lg-4 {
		margin-top: 1.5rem!important
	}
	.mr-lg-4,
	.mx-lg-4 {
		margin-right: 1.5rem!important
	}
	.mb-lg-4,
	.my-lg-4 {
		margin-bottom: 1.5rem!important
	}
	.ml-lg-4,
	.mx-lg-4 {
		margin-left: 1.5rem!important
	}
	.m-lg-5 {
		margin: 3rem!important
	}
	.mt-lg-5,
	.my-lg-5 {
		margin-top: 3rem!important
	}
	.mr-lg-5,
	.mx-lg-5 {
		margin-right: 3rem!important
	}
	.mb-lg-5,
	.my-lg-5 {
		margin-bottom: 3rem!important
	}
	.ml-lg-5,
	.mx-lg-5 {
		margin-left: 3rem!important
	}
	.p-lg-0 {
		padding: 0!important
	}
	.pt-lg-0,
	.py-lg-0 {
		padding-top: 0!important
	}
	.pr-lg-0,
	.px-lg-0 {
		padding-right: 0!important
	}
	.pb-lg-0,
	.py-lg-0 {
		padding-bottom: 0!important
	}
	.pl-lg-0,
	.px-lg-0 {
		padding-left: 0!important
	}
	.p-lg-1 {
		padding: .25rem!important
	}
	.pt-lg-1,
	.py-lg-1 {
		padding-top: .25rem!important
	}
	.pr-lg-1,
	.px-lg-1 {
		padding-right: .25rem!important
	}
	.pb-lg-1,
	.py-lg-1 {
		padding-bottom: .25rem!important
	}
	.pl-lg-1,
	.px-lg-1 {
		padding-left: .25rem!important
	}
	.p-lg-2 {
		padding: .5rem!important
	}
	.pt-lg-2,
	.py-lg-2 {
		padding-top: .5rem!important
	}
	.pr-lg-2,
	.px-lg-2 {
		padding-right: .5rem!important
	}
	.pb-lg-2,
	.py-lg-2 {
		padding-bottom: .5rem!important
	}
	.pl-lg-2,
	.px-lg-2 {
		padding-left: .5rem!important
	}
	.p-lg-3 {
		padding: 1rem!important
	}
	.pt-lg-3,
	.py-lg-3 {
		padding-top: 1rem!important
	}
	.pr-lg-3,
	.px-lg-3 {
		padding-right: 1rem!important
	}
	.pb-lg-3,
	.py-lg-3 {
		padding-bottom: 1rem!important
	}
	.pl-lg-3,
	.px-lg-3 {
		padding-left: 1rem!important
	}
	.p-lg-4 {
		padding: 1.5rem!important
	}
	.pt-lg-4,
	.py-lg-4 {
		padding-top: 1.5rem!important
	}
	.pr-lg-4,
	.px-lg-4 {
		padding-right: 1.5rem!important
	}
	.pb-lg-4,
	.py-lg-4 {
		padding-bottom: 1.5rem!important
	}
	.pl-lg-4,
	.px-lg-4 {
		padding-left: 1.5rem!important
	}
	.p-lg-5 {
		padding: 3rem!important
	}
	.pt-lg-5,
	.py-lg-5 {
		padding-top: 3rem!important
	}
	.pr-lg-5,
	.px-lg-5 {
		padding-right: 3rem!important
	}
	.pb-lg-5,
	.py-lg-5 {
		padding-bottom: 3rem!important
	}
	.pl-lg-5,
	.px-lg-5 {
		padding-left: 3rem!important
	}
	.m-lg-auto {
		margin: auto!important
	}
	.mt-lg-auto,
	.my-lg-auto {
		margin-top: auto!important
	}
	.mr-lg-auto,
	.mx-lg-auto {
		margin-right: auto!important
	}
	.mb-lg-auto,
	.my-lg-auto {
		margin-bottom: auto!important
	}
	.ml-lg-auto,
	.mx-lg-auto {
		margin-left: auto!important
	}
}

@media (min-width: 1200px) {
	.m-xl-0 {
		margin: 0!important
	}
	.mt-xl-0,
	.my-xl-0 {
		margin-top: 0!important
	}
	.mr-xl-0,
	.mx-xl-0 {
		margin-right: 0!important
	}
	.mb-xl-0,
	.my-xl-0 {
		margin-bottom: 0!important
	}
	.ml-xl-0,
	.mx-xl-0 {
		margin-left: 0!important
	}
	.m-xl-1 {
		margin: .25rem!important
	}
	.mt-xl-1,
	.my-xl-1 {
		margin-top: .25rem!important
	}
	.mr-xl-1,
	.mx-xl-1 {
		margin-right: .25rem!important
	}
	.mb-xl-1,
	.my-xl-1 {
		margin-bottom: .25rem!important
	}
	.ml-xl-1,
	.mx-xl-1 {
		margin-left: .25rem!important
	}
	.m-xl-2 {
		margin: .5rem!important
	}
	.mt-xl-2,
	.my-xl-2 {
		margin-top: .5rem!important
	}
	.mr-xl-2,
	.mx-xl-2 {
		margin-right: .5rem!important
	}
	.mb-xl-2,
	.my-xl-2 {
		margin-bottom: .5rem!important
	}
	.ml-xl-2,
	.mx-xl-2 {
		margin-left: .5rem!important
	}
	.m-xl-3 {
		margin: 1rem!important
	}
	.mt-xl-3,
	.my-xl-3 {
		margin-top: 1rem!important
	}
	.mr-xl-3,
	.mx-xl-3 {
		margin-right: 1rem!important
	}
	.mb-xl-3,
	.my-xl-3 {
		margin-bottom: 1rem!important
	}
	.ml-xl-3,
	.mx-xl-3 {
		margin-left: 1rem!important
	}
	.m-xl-4 {
		margin: 1.5rem!important
	}
	.mt-xl-4,
	.my-xl-4 {
		margin-top: 1.5rem!important
	}
	.mr-xl-4,
	.mx-xl-4 {
		margin-right: 1.5rem!important
	}
	.mb-xl-4,
	.my-xl-4 {
		margin-bottom: 1.5rem!important
	}
	.ml-xl-4,
	.mx-xl-4 {
		margin-left: 1.5rem!important
	}
	.m-xl-5 {
		margin: 3rem!important
	}
	.mt-xl-5,
	.my-xl-5 {
		margin-top: 3rem!important
	}
	.mr-xl-5,
	.mx-xl-5 {
		margin-right: 3rem!important
	}
	.mb-xl-5,
	.my-xl-5 {
		margin-bottom: 3rem!important
	}
	.ml-xl-5,
	.mx-xl-5 {
		margin-left: 3rem!important
	}
	.p-xl-0 {
		padding: 0!important
	}
	.pt-xl-0,
	.py-xl-0 {
		padding-top: 0!important
	}
	.pr-xl-0,
	.px-xl-0 {
		padding-right: 0!important
	}
	.pb-xl-0,
	.py-xl-0 {
		padding-bottom: 0!important
	}
	.pl-xl-0,
	.px-xl-0 {
		padding-left: 0!important
	}
	.p-xl-1 {
		padding: .25rem!important
	}
	.pt-xl-1,
	.py-xl-1 {
		padding-top: .25rem!important
	}
	.pr-xl-1,
	.px-xl-1 {
		padding-right: .25rem!important
	}
	.pb-xl-1,
	.py-xl-1 {
		padding-bottom: .25rem!important
	}
	.pl-xl-1,
	.px-xl-1 {
		padding-left: .25rem!important
	}
	.p-xl-2 {
		padding: .5rem!important
	}
	.pt-xl-2,
	.py-xl-2 {
		padding-top: .5rem!important
	}
	.pr-xl-2,
	.px-xl-2 {
		padding-right: .5rem!important
	}
	.pb-xl-2,
	.py-xl-2 {
		padding-bottom: .5rem!important
	}
	.pl-xl-2,
	.px-xl-2 {
		padding-left: .5rem!important
	}
	.p-xl-3 {
		padding: 1rem!important
	}
	.pt-xl-3,
	.py-xl-3 {
		padding-top: 1rem!important
	}
	.pr-xl-3,
	.px-xl-3 {
		padding-right: 1rem!important
	}
	.pb-xl-3,
	.py-xl-3 {
		padding-bottom: 1rem!important
	}
	.pl-xl-3,
	.px-xl-3 {
		padding-left: 1rem!important
	}
	.p-xl-4 {
		padding: 1.5rem!important
	}
	.pt-xl-4,
	.py-xl-4 {
		padding-top: 1.5rem!important
	}
	.pr-xl-4,
	.px-xl-4 {
		padding-right: 1.5rem!important
	}
	.pb-xl-4,
	.py-xl-4 {
		padding-bottom: 1.5rem!important
	}
	.pl-xl-4,
	.px-xl-4 {
		padding-left: 1.5rem!important
	}
	.p-xl-5 {
		padding: 3rem!important
	}
	.pt-xl-5,
	.py-xl-5 {
		padding-top: 3rem!important
	}
	.pr-xl-5,
	.px-xl-5 {
		padding-right: 3rem!important
	}
	.pb-xl-5,
	.py-xl-5 {
		padding-bottom: 3rem!important
	}
	.pl-xl-5,
	.px-xl-5 {
		padding-left: 3rem!important
	}
	.m-xl-auto {
		margin: auto!important
	}
	.mt-xl-auto,
	.my-xl-auto {
		margin-top: auto!important
	}
	.mr-xl-auto,
	.mx-xl-auto {
		margin-right: auto!important
	}
	.mb-xl-auto,
	.my-xl-auto {
		margin-bottom: auto!important
	}
	.ml-xl-auto,
	.mx-xl-auto {
		margin-left: auto!important
	}
}

.text-justify {
	text-align: justify!important
}

.text-nowrap {
	white-space: nowrap!important
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap
}

.text-left {
	text-align: left!important
}

.text-right {
	text-align: right!important
}

.text-center {
	text-align: center!important
}

@media (min-width: 576px) {
	.text-sm-left {
		text-align: left!important
	}
	.text-sm-right {
		text-align: right!important
	}
	.text-sm-center {
		text-align: center!important
	}
}

@media (min-width: 768px) {
	.text-md-left {
		text-align: left!important
	}
	.text-md-right {
		text-align: right!important
	}
	.text-md-center {
		text-align: center!important
	}
}

@media (min-width: 992px) {
	.text-lg-left {
		text-align: left!important
	}
	.text-lg-right {
		text-align: right!important
	}
	.text-lg-center {
		text-align: center!important
	}
}

@media (min-width: 1200px) {
	.text-xl-left {
		text-align: left!important
	}
	.text-xl-right {
		text-align: right!important
	}
	.text-xl-center {
		text-align: center!important
	}
}

.text-lowercase {
	text-transform: lowercase!important
}

.text-uppercase {
	text-transform: uppercase!important
}

.text-capitalize {
	text-transform: capitalize!important
}

.font-weight-light {
	font-weight: 300!important
}

.font-weight-normal {
	font-weight: 400!important
}

.font-weight-bold {
	font-weight: 700!important
}

.font-italic {
	font-style: italic!important
}

.text-white {
	color: #fff!important
}

.text-primary {
	color: #42a5f5!important
}

a.text-primary.link-effect::before {
	background-color: #42a5f5
}

a.text-primary:hover,
a.text-primary:focus {
	color: #0b72c6!important
}

.text-secondary {
	color: #6c757d!important
}

a.text-secondary.link-effect::before {
	background-color: #6c757d
}

a.text-secondary:hover,
a.text-secondary:focus {
	color: #3d4246!important
}

.text-success {
	color: #9ccc65!important
}

a.text-success.link-effect::before {
	background-color: #9ccc65
}

a.text-success:hover,
a.text-success:focus {
	color: #699833!important
}

.text-info {
	color: #26c6da!important
}

a.text-info.link-effect::before {
	background-color: #26c6da
}

a.text-info:hover,
a.text-info:focus {
	color: #167784!important
}

.text-warning {
	color: #ffca28!important
}

a.text-warning.link-effect::before {
	background-color: #ffca28
}

a.text-warning:hover,
a.text-warning:focus {
	color: #c19100!important
}

.text-danger {
	color: #ef5350!important
}

a.text-danger.link-effect::before {
	background-color: #ef5350
}

a.text-danger:hover,
a.text-danger:focus {
	color: #c71612!important
}

.text-light {
	color: #f8f9fa!important
}

a.text-light.link-effect::before {
	background-color: #f8f9fa
}

a.text-light:hover,
a.text-light:focus {
	color: #bdc6d0!important
}

.text-dark {
	color: #343a40!important
}

a.text-dark.link-effect::before {
	background-color: #343a40
}

a.text-dark:hover,
a.text-dark:focus {
	color: #060708!important
}

.text-muted {
	color: #6c757d!important
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0
}

.visible {
	visibility: visible!important
}

.invisible {
	visibility: hidden!important
}

@media print {
	*,
	::before,
	::after {
		text-shadow: none!important;
		box-shadow: none!important
	}
	a:not(.btn) {
		text-decoration: underline
	}
	abbr[title]::after {
		content: " (" attr(title) ")"
	}
	pre {
		white-space: pre-wrap!important
	}
	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid
	}
	thead {
		display: table-header-group
	}
	tr,
	img {
		page-break-inside: avoid
	}
	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3
	}
	h2,
	h3 {
		page-break-after: avoid
	}
	@page {
		size: a3
	}
	body {
		min-width: 992px!important
	}
	.container {
		min-width: 992px!important
	}
	.navbar {
		display: none
	}
	.badge {
		border: 1px solid #000
	}
	.table {
		border-collapse: collapse!important
	}
	.table td,
	.table th {
		background-color: #fff!important
	}
	.table-bordered th,
	.table-bordered td {
		border: 1px solid #ddd!important
	}
}

html,
body {
	font-size: 14px
}

html.no-focus :focus {
	outline: none!important
}

strong {
	font-weight: 600
}

a {
	transition: color .12s ease-out
}

@media (min-width: 576px) {
	a.link-effect {
		position: relative
	}
	a.link-effect::before {
		position: absolute;
		bottom: -5px;
		left: 0;
		width: 100%;
		height: 2px;
		content: "";
		background-color: #42a5f5;
		visibility: hidden;
		-webkit-transform: translateY(6px);
		transform: translateY(6px);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		opacity: 0;
		transition: opacity .12s ease-out, -webkit-transform .12s ease-out;
		transition: transform .12s ease-out, opacity .12s ease-out;
		transition: transform .12s ease-out, opacity .12s ease-out, -webkit-transform .12s ease-out
	}
}

a:hover.link-effect::before {
	visibility: visible;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1
}

p {
	line-height: 1.6;
	margin-bottom: 24px
}

.nice-copy p,
p.nice-copy {
	line-height: 1.8;
	font-size: 1.142857rem
}

.nice-copy-story p,
p.nice-copy-story {
	line-height: 1.8;
	font-size: 1.285714rem;
	color: #626262
}

.nice-copy-story h2,
.nice-copy-story h3,
.nice-copy-story h4 {
	margin-top: 50px
}

.font-size-h1 {
	font-size: 2.571429rem
}

.font-size-h2 {
	font-size: 2.142857rem
}

.font-size-h3 {
	font-size: 1.857143rem
}

.font-size-h4 {
	font-size: 1.571429rem
}

.font-size-h5 {
	font-size: 1.285714rem
}

.font-size-h6 {
	font-size: 1rem
}

@media (max-width: 767.98px) {
	.display-1 {
		font-size: 3.429rem
	}
	.display-2 {
		font-size: 3.143rem
	}
	.display-3 {
		font-size: 2.857rem
	}
	.display-4 {
		font-size: 2.571429rem
	}
	h1,
	.h1,
	.font-size-h1 {
		font-size: 2.142857rem
	}
	h2,
	.h2,
	.font-size-h2 {
		font-size: 1.857143rem
	}
	h3,
	.h3,
	.font-size-h3 {
		font-size: 1.571429rem
	}
	h4,
	.h4,
	.font-size-h4 {
		font-size: 1.285714rem
	}
	h5,
	.h5,
	.font-size-h5,
	h6,
	.h6,
	.font-size-h6 {
		font-size: 1rem
	}
}

.content-heading {
	margin-bottom: 20px;
	padding-top: 30px;
	padding-bottom: 8px;
	font-size: 1.428571rem;
	font-weight: 400;
	line-height: 28px;
	border-bottom: 1px solid #eaecee
}

.content-heading small {
	margin-top: 5px;
	font-size: 16px;
	font-weight: 400;
	color: #6c757d
}

@media (min-width: 768px) {
	.content-heading {
		margin-bottom: 25px;
		padding-top: 40px
	}
	.content-heading small {
		margin-top: 0
	}
}

.content-heading .dropdown {
	line-height: 1.5
}

small,
.small {
	font-weight: inherit;
	color: #646464
}

.row.gutters-tiny {
	margin-right: -3px;
	margin-left: -3px
}

.row.gutters-tiny>.col,
.row.gutters-tiny>[class*="col-"] {
	padding-left: 3px;
	padding-right: 3px
}

.row.gutters-tiny .push,
.row.gutters-tiny .block {
	margin-bottom: 6px
}

.row.row-deck>div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch
}

.row.row-deck>div>.block {
	min-width: 100%
}

.table thead th {
	border-top: none;
	border-bottom: none;
	font-weight: 600;
	text-transform: uppercase
}

.table-borderless,
.table-borderless th,
.table-borderless td {
	border: none!important
}

.table-vcenter th,
.table-vcenter td {
	vertical-align: middle
}

.table-responsive>.table.table-bordered thead th,
[class*="table-responsive-"]>.table.table-bordered thead th {
	border-top: 1px solid #eaecee
}

.table-active,
.table-active>th,
.table-active>td {
	background-color: #f5f6f7
}

.table-hover .table-active:hover {
	background-color: #e7e9ec
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
	background-color: #e7e9ec
}

.table-primary,
.table-primary>th,
.table-primary>td {
	background-color: #d3eafd
}

.table-hover .table-primary:hover {
	background-color: #bbdffb
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
	background-color: #bbdffb
}

.table-success,
.table-success>th,
.table-success>td {
	background-color: #ebf5df
}

.table-hover .table-success:hover {
	background-color: #dfefcc
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
	background-color: #dfefcc
}

.table-info,
.table-info>th,
.table-info>td {
	background-color: #e3f4fc
}

.table-hover .table-info:hover {
	background-color: #ccebfa
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
	background-color: #ccebfa
}

.table-warning,
.table-warning>th,
.table-warning>td {
	background-color: #fcf7e6
}

.table-hover .table-warning:hover {
	background-color: #f9f0cf
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
	background-color: #f9f0cf
}

.table-danger,
.table-danger>th,
.table-danger>td {
	background-color: #fae9e8
}

.table-hover .table-danger:hover {
	background-color: #f5d5d3
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
	background-color: #f5d5d3
}

.js-table-checkable tbody tr,
.js-table-sections-header>tr {
	cursor: pointer
}

.js-table-sections-header>tr>td:first-child>i {
	transition: -webkit-transform .2s ease-in-out;
	transition: transform .2s ease-in-out;
	transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out
}

.js-table-sections-header+tbody {
	display: none
}

.js-table-sections-header.show>tr>td:first-child>i {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.js-table-sections-header.show+tbody {
	display: table-row-group
}

label {
	margin-bottom: 5px;
	font-weight: 600
}

.form-control,
.input-group-text {
	height: 34px;
	font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
}

select.form-control {
	height: auto
}

select.form-control:not([size]):not([multiple]) {
	padding-top: 6px;
	padding-bottom: 6px
}

select.form-control-sm:not([size]):not([multiple]),
.input-group-sm>select.form-control:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.btn:not([size]):not([multiple]) {
	padding-top: 3px;
	padding-bottom: 3px
}

select.form-control-lg:not([size]):not([multiple]),
.input-group-lg>select.form-control:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]) {
	padding-top: 7px;
	padding-bottom: 7px
}

textarea.form-control {
	height: auto;
	padding: 14px
}

textarea.form-control.form-control-sm,
.input-group-sm>textarea.form-control,
.input-group-sm>.input-group-prepend>textarea.form-control.input-group-text,
.input-group-sm>.input-group-append>textarea.form-control.input-group-text,
.input-group-sm>.input-group-prepend>textarea.form-control.btn,
.input-group-sm>.input-group-append>textarea.form-control.btn {
	padding: 9px
}

textarea.form-control.form-control-lg,
.input-group-lg>textarea.form-control,
.input-group-lg>.input-group-prepend>textarea.form-control.input-group-text,
.input-group-lg>.input-group-append>textarea.form-control.input-group-text,
.input-group-lg>.input-group-prepend>textarea.form-control.btn,
.input-group-lg>.input-group-append>textarea.form-control.btn {
	padding: 18px
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.form-control,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
	height: 40px;
	font-size: 1.142857rem;
	line-height: 20px
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.form-control,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
	height: 28px;
	font-size: 1rem;
	line-height: 16px
}

.col-form-label-lg {
	font-size: 1.142857rem
}

.col-form-label-sm {
	font-size: 1rem
}

.invalid-feedback {
	font-size: .928571rem
}

.btn {
	height: 34px;
	font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-weight: 600
}

.btn.btn-square {
	border-radius: 0!important
}

.btn.btn-rounded {
	border-radius: 50px!important
}

.btn .si {
	position: relative;
	top: 1px
}

.btn-sm,
.btn-group-sm>.btn,
.btn-group-sm>.btn {
	height: 28px
}

.btn-lg,
.btn-group-lg>.btn,
.btn-group-lg>.btn {
	height: 40px
}

.btn.btn-hero {
	padding: 14px 38px;
	font-size: 1rem;
	line-height: 16px;
	border-radius: 3px;
	height: auto
}

.btn.btn-hero.btn-sm,
.btn-group-sm>.btn.btn-hero {
	padding: 11px 34px;
	font-size: 1rem;
	line-height: 16px;
	border-radius: 3px
}

.btn.btn-hero.btn-lg,
.btn-group-lg>.btn.btn-hero {
	padding: 15px 42px;
	font-size: 1.142857rem;
	line-height: 20px;
	border-radius: 3px
}

.btn.btn-circle {
	padding: 9px 0;
	font-size: 1rem;
	line-height: 1;
	border-radius: inherit;
	min-width: 34px;
	height: 34px;
	text-align: center;
	border-radius: 50%!important
}

.btn.btn-circle.btn-sm,
.btn-group-sm>.btn.btn-circle {
	padding: 6px 0;
	font-size: 1rem;
	line-height: 1;
	border-radius: inherit;
	min-width: 28px;
	height: 28px
}

.btn.btn-circle.btn-lg,
.btn-group-lg>.btn.btn-circle {
	padding: 12px 0;
	font-size: 1rem;
	line-height: 1;
	border-radius: inherit;
	min-width: 40px;
	height: 40px
}

.btn.btn-circle>.si {
	top: 0
}

.btn-secondary {
	color: #212529;
	background-color: #f5f6f7;
	border-color: #d3d7dc
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus {
	color: #212529;
	background-color: #d3d7dc;
	border-color: #b7bec5
}

.btn-secondary.disabled,
.btn-secondary:disabled {
	background-color: #f5f6f7;
	border-color: #d3d7dc
}

.btn-secondary:not([disabled]):not(.disabled):active {
	background-color: #f5f6f7;
	border-color: #d3d7dc
}

.btn-secondary:not([disabled]):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
	background-color: #d3d7dc;
	background-image: none;
	border-color: #b7bec5
}

.btn-secondary.btn-noborder {
	border-color: #f5f6f7
}

.btn-secondary.btn-noborder:hover,
.btn-secondary.btn-noborder:focus,
.btn-secondary.btn-noborder.focus {
	border-color: #d3d7dc
}

.btn-secondary.btn-noborder.disabled,
.btn-secondary.btn-noborder:disabled,
.btn-secondary.btn-noborder:not([disabled]):not(.disabled):active,
.btn-secondary.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: #f5f6f7
}

.show>.btn-secondary.btn-noborder.dropdown-toggle {
	border-color: #d3d7dc
}

.btn-outline-secondary {
	color: #788693;
	background-color: transparent;
	background-image: none;
	border-color: #788693
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
	color: #fff;
	background-color: #788693;
	border-color: #788693
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
	color: #788693;
	background-color: transparent
}

.btn-outline-secondary:not([disabled]):not(.disabled):active {
	color: #788693;
	background-color: transparent;
	border-color: #788693
}

.btn-outline-secondary:not([disabled]):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
	color: #fff;
	background-color: #788693;
	border-color: #788693
}

.btn-outline-secondary.btn-noborder {
	border-color: transparent
}

.btn-outline-secondary.btn-noborder:not([disabled]):not(.disabled):active,
.btn-outline-secondary.btn-noborder:not([disabled]):not(.disabled).active {
	border-color: transparent
}

.btn-alt-primary {
	color: #0965ae;
	background-color: #d3eafd;
	border-color: #d3eafd
}

.btn-alt-primary:hover,
.btn-alt-primary:focus,
.btn-alt-primary.focus {
	color: #0965ae;
	background-color: #a3d3fa;
	border-color: #a3d3fa;
	box-shadow: none
}

.btn-alt-primary.disabled,
.btn-alt-primary:disabled {
	background-color: #d3eafd;
	border-color: #d3eafd
}

.btn-alt-primary:not([disabled]):not(.disabled):active {
	color: #0965ae;
	background-color: #d3eafd;
	border-color: #d3eafd
}

.btn-alt-primary:not([disabled]):not(.disabled).active,
.show>.btn-alt-primary.dropdown-toggle {
	color: #0965ae;
	background-color: #a3d3fa;
	border-color: #a3d3fa
}

.btn-alt-secondary {
	color: #171717;
	background-color: #eaecee;
	border-color: #eaecee
}

.btn-alt-secondary:hover,
.btn-alt-secondary:focus,
.btn-alt-secondary.focus {
	color: #171717;
	background-color: #cdd2d7;
	border-color: #cdd2d7;
	box-shadow: none
}

.btn-alt-secondary.disabled,
.btn-alt-secondary:disabled {
	background-color: #eaecee;
	border-color: #eaecee
}

.btn-alt-secondary:not([disabled]):not(.disabled):active {
	color: #171717;
	background-color: #eaecee;
	border-color: #eaecee
}

.btn-alt-secondary:not([disabled]):not(.disabled).active,
.show>.btn-alt-secondary.dropdown-toggle {
	color: #171717;
	background-color: #cdd2d7;
	border-color: #cdd2d7
}

.btn-alt-success {
	color: #5c852c;
	background-color: #ebf5df;
	border-color: #ebf5df
}

.btn-alt-success:hover,
.btn-alt-success:focus,
.btn-alt-success.focus {
	color: #5c852c;
	background-color: #d3e9b8;
	border-color: #d3e9b8;
	box-shadow: none
}

.btn-alt-success.disabled,
.btn-alt-success:disabled {
	background-color: #ebf5df;
	border-color: #ebf5df
}

.btn-alt-success:not([disabled]):not(.disabled):active {
	color: #5c852c;
	background-color: #ebf5df;
	border-color: #ebf5df
}

.btn-alt-success:not([disabled]):not(.disabled).active,
.show>.btn-alt-success.dropdown-toggle {
	color: #5c852c;
	background-color: #d3e9b8;
	border-color: #d3e9b8
}

.btn-alt-info {
	color: #13646e;
	background-color: #e3f4fc;
	border-color: #e3f4fc
}

.btn-alt-info:hover,
.btn-alt-info:focus,
.btn-alt-info.focus {
	color: #13646e;
	background-color: #b5e2f7;
	border-color: #b5e2f7;
	box-shadow: none
}

.btn-alt-info.disabled,
.btn-alt-info:disabled {
	background-color: #e3f4fc;
	border-color: #e3f4fc
}

.btn-alt-info:not([disabled]):not(.disabled):active {
	color: #13646e;
	background-color: #e3f4fc;
	border-color: #e3f4fc
}

.btn-alt-info:not([disabled]):not(.disabled).active,
.show>.btn-alt-info.dropdown-toggle {
	color: #13646e;
	background-color: #b5e2f7;
	border-color: #b5e2f7
}

.btn-alt-warning {
	color: #a87e00;
	background-color: #fcf7e6;
	border-color: #fcf7e6
}

.btn-alt-warning:hover,
.btn-alt-warning:focus,
.btn-alt-warning.focus {
	color: #a87e00;
	background-color: #f7e8b8;
	border-color: #f7e8b8;
	box-shadow: none
}

.btn-alt-warning.disabled,
.btn-alt-warning:disabled {
	background-color: #fcf7e6;
	border-color: #fcf7e6
}

.btn-alt-warning:not([disabled]):not(.disabled):active {
	color: #a87e00;
	background-color: #fcf7e6;
	border-color: #fcf7e6
}

.btn-alt-warning:not([disabled]):not(.disabled).active,
.show>.btn-alt-warning.dropdown-toggle {
	color: #a87e00;
	background-color: #f7e8b8;
	border-color: #f7e8b8
}

.btn-alt-danger {
	color: #af1310;
	background-color: #fae9e8;
	border-color: #fae9e8
}

.btn-alt-danger:hover,
.btn-alt-danger:focus,
.btn-alt-danger.focus {
	color: #af1310;
	background-color: #f1c1be;
	border-color: #f1c1be;
	box-shadow: none
}

.btn-alt-danger.disabled,
.btn-alt-danger:disabled {
	background-color: #fae9e8;
	border-color: #fae9e8
}

.btn-alt-danger:not([disabled]):not(.disabled):active {
	color: #af1310;
	background-color: #fae9e8;
	border-color: #fae9e8
}

.btn-alt-danger:not([disabled]):not(.disabled).active,
.show>.btn-alt-danger.dropdown-toggle {
	color: #af1310;
	background-color: #f1c1be;
	border-color: #f1c1be
}

.btn-dual-secondary {
	color: #1f1f1f;
	background-color: transparent;
	border-color: transparent
}

.btn-dual-secondary:hover,
.btn-dual-secondary:focus,
.btn-dual-secondary.focus {
	color: #1f1f1f;
	background-color: #d9dde0;
	border-color: #d9dde0;
	box-shadow: none
}

.btn-dual-secondary.disabled,
.btn-dual-secondary:disabled {
	background-color: transparent;
	border-color: transparent
}

.btn-dual-secondary:not([disabled]):not(.disabled):active {
	color: #1f1f1f;
	background-color: transparent;
	border-color: transparent
}

.btn-dual-secondary:not([disabled]):not(.disabled).active,
.show>.btn-dual-secondary.dropdown-toggle {
	color: #1f1f1f;
	background-color: #d9dde0;
	border-color: #d9dde0
}

.page-header-inverse #page-header .btn-dual-secondary,
.sidebar-inverse #sidebar .btn-dual-secondary {
	color: #fff;
	background-color: transparent;
	border-color: transparent
}

.page-header-inverse #page-header .btn-dual-secondary:hover,
.page-header-inverse #page-header .btn-dual-secondary:focus,
.page-header-inverse #page-header .btn-dual-secondary.focus,
.sidebar-inverse #sidebar .btn-dual-secondary:hover,
.sidebar-inverse #sidebar .btn-dual-secondary:focus,
.sidebar-inverse #sidebar .btn-dual-secondary.focus {
	color: #fff;
	background-color: #212529;
	border-color: #212529;
	box-shadow: none
}

.page-header-inverse #page-header .btn-dual-secondary.disabled,
.page-header-inverse #page-header .btn-dual-secondary:disabled,
.sidebar-inverse #sidebar .btn-dual-secondary.disabled,
.sidebar-inverse #sidebar .btn-dual-secondary:disabled {
	background-color: transparent;
	border-color: transparent
}

.page-header-inverse #page-header .btn-dual-secondary:not([disabled]):not(.disabled):active,
.sidebar-inverse #sidebar .btn-dual-secondary:not([disabled]):not(.disabled):active {
	color: #fff;
	background-color: transparent;
	border-color: transparent
}

.page-header-inverse #page-header .btn-dual-secondary:not([disabled]):not(.disabled).active,
.show>.page-header-inverse #page-header .btn-dual-secondary.dropdown-toggle,
.sidebar-inverse #sidebar .btn-dual-secondary:not([disabled]):not(.disabled).active,
.show>.sidebar-inverse #sidebar .btn-dual-secondary.dropdown-toggle {
	color: #fff;
	background-color: #212529;
	border-color: #212529
}

.fade {
	transition: opacity .15s ease-out
}

.fade.fade-up {
	transition: opacity .15s ease-out, -webkit-transform .3s ease-out;
	transition: opacity .15s ease-out, transform .3s ease-out;
	transition: opacity .15s ease-out, transform .3s ease-out, -webkit-transform .3s ease-out;
	-webkit-transform: translateY(100px);
	transform: translateY(100px)
}

.fade.fade-up.show {
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.fade.fade-right {
	transition: opacity .15s ease-out, -webkit-transform .3s ease-out;
	transition: opacity .15s ease-out, transform .3s ease-out;
	transition: opacity .15s ease-out, transform .3s ease-out, -webkit-transform .3s ease-out;
	-webkit-transform: translateX(-100px);
	transform: translateX(-100px)
}

.fade.fade-right.show {
	-webkit-transform: translateX(0);
	transform: translateX(0)
}

.fade.fade-left {
	transition: opacity .15s ease-out, -webkit-transform .3s ease-out;
	transition: opacity .15s ease-out, transform .3s ease-out;
	transition: opacity .15s ease-out, transform .3s ease-out, -webkit-transform .3s ease-out;
	-webkit-transform: translateX(100px);
	transform: translateX(100px)
}

.fade.fade-left.show {
	-webkit-transform: translateX(0);
	transform: translateX(0)
}

.dropdown-menu {
	padding-right: 8px;
	padding-left: 8px
}

.dropdown-item {
	margin-bottom: 5px;
	border-radius: 4px
}

.dropdown-header {
	margin-bottom: 5px;
	text-transform: uppercase
}

.custom-control-label::before,
.custom-control-label::after {
	top: 3px
}

.css-control {
	position: relative;
	display: inline-block;
	padding: 6px 0;
	margin: 0;
	font-weight: 400;
	font-size: 1rem;
	cursor: pointer
}

.css-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0
}

.css-control-input:focus~.css-control-indicator {
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.35)
}

.css-control-input~.css-control-indicator {
	position: relative;
	display: inline-block;
	margin-top: -3px;
	margin-right: 3px;
	vertical-align: middle
}

.css-control-input~.css-control-indicator::after {
	position: absolute;
	content: ""
}

.css-control.css-control-sm {
	padding: 3px 0 4px;
	font-size: 1rem
}

.css-control.css-control-lg {
	padding: 7px 0;
	font-size: 1.142857rem
}

.css-control.disabled {
	opacity: .5;
	cursor: not-allowed
}

.css-control+.css-control {
	margin-left: 10px
}

.css-radio .css-control-input~.css-control-indicator {
	width: 20px;
	height: 20px;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 50%
}

.css-radio .css-control-input~.css-control-indicator::after {
	top: 2px;
	right: 2px;
	bottom: 2px;
	left: 2px;
	background-color: #fff;
	border-radius: 50%;
	opacity: 0;
	transition: opacity .15s ease-out
}

.css-radio .css-control-input:checked~.css-control-indicator::after {
	opacity: 1
}

.css-radio:hover .css-control-input~.css-control-indicator {
	border-color: #eaecee
}

.css-radio.css-control-sm .css-control-input~.css-control-indicator {
	width: 16px;
	height: 16px
}

.css-radio.css-control-lg .css-control-input~.css-control-indicator {
	width: 26px;
	height: 26px
}

.css-radio.css-control-primary .css-control-input:checked~.css-control-indicator::after {
	background-color: #42a5f5
}

.css-radio.css-control-secondary .css-control-input:checked~.css-control-indicator::after {
	background-color: #6c757d
}

.css-radio.css-control-info .css-control-input:checked~.css-control-indicator::after {
	background-color: #26c6da
}

.css-radio.css-control-success .css-control-input:checked~.css-control-indicator::after {
	background-color: #9ccc65
}

.css-radio.css-control-warning .css-control-input:checked~.css-control-indicator::after {
	background-color: #ffca28
}

.css-radio.css-control-danger .css-control-input:checked~.css-control-indicator::after {
	background-color: #ef5350
}

.css-checkbox .css-control-input~.css-control-indicator {
	width: 20px;
	height: 20px;
	background-color: #fff;
	border: 1px solid #ddd;
	transition: background-color .15s
}

.css-checkbox .css-control-input~.css-control-indicator::after {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	font-family: "FontAwesome";
	font-size: 10px;
	color: #fff;
	line-height: 18px;
	content: "\f00c";
	text-align: center
}

.css-checkbox:hover .css-control-input~.css-control-indicator {
	border-color: #eaecee
}

.css-checkbox.css-control-sm .css-control-input~.css-control-indicator {
	width: 16px;
	height: 16px
}

.css-checkbox.css-control-sm .css-control-input~.css-control-indicator::after {
	font-size: 8px;
	line-height: 15px
}

.css-checkbox.css-control-lg {
	padding: 6px 0 7px
}

.css-checkbox.css-control-lg .css-control-input~.css-control-indicator {
	width: 28px;
	height: 28px
}

.css-checkbox.css-control-lg .css-control-input~.css-control-indicator::after {
	font-size: 14px;
	line-height: 26px
}

.css-checkbox.css-checkbox-rounded .css-control-input~.css-control-indicator {
	border-radius: 2px
}

.css-checkbox.css-control-primary .css-control-input:checked~.css-control-indicator {
	background-color: #42a5f5;
	border-color: #0c80df
}

.css-checkbox.css-control-secondary .css-control-input:checked~.css-control-indicator {
	background-color: #6c757d;
	border-color: #494f54
}

.css-checkbox.css-control-info .css-control-input:checked~.css-control-indicator {
	background-color: #26c6da;
	border-color: #1a8b99
}

.css-checkbox.css-control-success .css-control-input:checked~.css-control-indicator {
	background-color: #9ccc65;
	border-color: #76ac39
}

.css-checkbox.css-control-warning .css-control-input:checked~.css-control-indicator {
	background-color: #ffca28;
	border-color: #dba500
}

.css-checkbox.css-control-danger .css-control-input:checked~.css-control-indicator {
	background-color: #ef5350;
	border-color: #de1814
}

.css-switch {
	padding: 3px 0
}

.css-switch .css-control-input~.css-control-indicator {
	width: 51px;
	height: 30px;
	background-color: #eee;
	border-radius: 30px;
	transition: background-color .3s
}

.css-switch .css-control-input~.css-control-indicator::after {
	top: 2px;
	bottom: 2px;
	left: 2px;
	width: 26px;
	background-color: #fff;
	border-radius: 50%;
	box-shadow: 1px 0 3px rgba(0, 0, 0, 0.1);
	transition: -webkit-transform .15s ease-out;
	transition: transform .15s ease-out;
	transition: transform .15s ease-out, -webkit-transform .15s ease-out
}

.css-switch .css-control-input:checked~.css-control-indicator {
	background-color: #ddd
}

.css-switch .css-control-input:checked~.css-control-indicator::after {
	box-shadow: -2px 0 3px rgba(0, 0, 0, 0.3);
	-webkit-transform: translateX(20px);
	transform: translateX(20px)
}

.css-switch.css-control-sm {
	padding: 3px 0
}

.css-switch.css-control-sm .css-control-input~.css-control-indicator {
	width: 36px;
	height: 20px
}

.css-switch.css-control-sm .css-control-input~.css-control-indicator::after {
	width: 16px
}

.css-switch.css-control-sm .css-control-input:checked~.css-control-indicator::after {
	-webkit-transform: translateX(15px);
	transform: translateX(15px)
}

.css-switch.css-control-lg {
	padding: 4px 0
}

.css-switch.css-control-lg .css-control-input~.css-control-indicator {
	width: 65px;
	height: 34px
}

.css-switch.css-control-lg .css-control-input~.css-control-indicator::after {
	width: 30px
}

.css-switch.css-control-lg .css-control-input:checked~.css-control-indicator::after {
	-webkit-transform: translateX(30px);
	transform: translateX(30px)
}

.css-switch.css-switch-square .css-control-input~.css-control-indicator,
.css-switch.css-switch-square .css-control-input~.css-control-indicator::after {
	border-radius: 0
}

.css-switch.css-control-primary .css-control-input:checked~.css-control-indicator {
	background-color: #42a5f5
}

.css-switch.css-control-secondary .css-control-input:checked~.css-control-indicator {
	background-color: #6c757d
}

.css-switch.css-control-info .css-control-input:checked~.css-control-indicator {
	background-color: #26c6da
}

.css-switch.css-control-success .css-control-input:checked~.css-control-indicator {
	background-color: #9ccc65
}

.css-switch.css-control-warning .css-control-input:checked~.css-control-indicator {
	background-color: #ffca28
}

.css-switch.css-control-danger .css-control-input:checked~.css-control-indicator {
	background-color: #ef5350
}

.nav-link {
	color: #575757;
	font-weight: 600
}

.nav-link:hover,
.nav-link:focus {
	color: #42a5f5
}

.nav-tabs-block {
	background-color: #fbfbfc;
	border-bottom: none
}

.nav-tabs-block .nav-item {
	margin-bottom: 0
}

.nav-tabs-block .nav-link {
	padding-top: 12px;
	padding-bottom: 12px;
	border-color: transparent;
	border-radius: 0
}

.nav-tabs-block .nav-link:hover,
.nav-tabs-block .nav-link:focus {
	color: #42a5f5;
	background-color: transparent;
	border-color: transparent
}

.nav-tabs-block .nav-link.active,
.nav-tabs-block .nav-item.show .nav-link {
	color: #575757;
	background-color: #fff;
	border-color: transparent
}

.nav-tabs-alt {
	background-color: transparent;
	border-bottom-color: #eaecee
}

.nav-tabs-alt .nav-item {
	margin-bottom: -3px
}

.nav-tabs-alt .nav-link {
	padding-top: 12px;
	padding-bottom: 12px;
	background-color: transparent;
	border-color: transparent
}

.nav-tabs-alt .nav-link:hover,
.nav-tabs-alt .nav-link:focus {
	color: #42a5f5;
	background-color: transparent;
	border-color: transparent;
	box-shadow: inset 0 -2px #42a5f5
}

.nav-tabs-alt .nav-link.active,
.nav-tabs-alt .nav-item.show .nav-link {
	background-color: transparent;
	border-color: transparent;
	box-shadow: inset 0 -2px #42a5f5
}

.nav-users {
	margin: 0;
	padding: 0;
	list-style: none
}

.nav-users>li:last-child>a {
	border-bottom: none
}

.nav-users a {
	position: relative;
	padding: 12px 8px 8px 71px;
	display: block;
	min-height: 62px;
	font-weight: 600;
	border-bottom: 1px solid #f5f6f7
}

.nav-users a>img {
	position: absolute;
	left: 12px;
	top: 10px;
	width: 42px;
	height: 42px;
	border-radius: 50%
}

.nav-users a>i {
	position: absolute;
	left: 40px;
	top: 40px;
	display: inline-block;
	width: 18px;
	height: 18px;
	line-height: 18px;
	text-align: center;
	background-color: #fff;
	border-radius: 50%
}

.nav-users a:hover {
	background-color: #fbfbfc
}

.breadcrumb-item+.breadcrumb-item::before {
	font-family: "FontAwesome";
	content: "\f105"
}

.page-link {
	height: 34px;
	line-height: 16px;
	font-size: 1rem;
	font-weight: 600
}

.page-link:focus {
	color: #171717;
	background-color: #eaecee;
	border-color: #eaecee
}

.pagination-lg .page-link {
	height: 40px;
	line-height: 20px;
	font-size: 1.142857rem
}

.pagination-sm .page-link {
	height: 28px;
	line-height: 16px;
	font-size: 1rem
}

.badge-secondary {
	color: #fff;
	background-color: rgba(0, 0, 0, 0.33)
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: rgba(0, 0, 0, 0.33)
}

.modal-content {
	border-radius: 0
}

.modal-header {
	padding-top: 16px;
	padding-bottom: 16px
}

.modal-footer {
	padding-top: 10px;
	padding-bottom: 10px;
	border-top-width: 1px
}

.modal-dialog.modal-dialog-top {
	margin-top: 0!important
}

.modal.fade .modal-dialog {
	transition-duration: .35s
}

.modal.fade .modal-dialog.modal-dialog-popin {
	-webkit-transform: scale(1.2, 1.2);
	transform: scale(1.2, 1.2)
}

.modal.fade .modal-dialog.modal-dialog-popout {
	-webkit-transform: scale(0.8, 0.8);
	transform: scale(0.8, 0.8)
}

.modal.fade .modal-dialog.modal-dialog-slideup {
	-webkit-transform: translate(0, 25%);
	transform: translate(0, 25%)
}

.modal.fade .modal-dialog.modal-dialog-slideright {
	-webkit-transform: translate(-25%, 0);
	transform: translate(-25%, 0)
}

.modal.fade .modal-dialog.modal-dialog-slideleft {
	-webkit-transform: translate(25%, 0);
	transform: translate(25%, 0)
}

.modal.fade .modal-dialog.modal-dialog-fromright {
	-webkit-transform: translateX(25%) rotate(15deg) scale(0.8);
	transform: translateX(25%) rotate(15deg) scale(0.8)
}

.modal.fade .modal-dialog.modal-dialog-fromleft {
	-webkit-transform: translateX(-25%) rotate(-15deg) scale(0.8);
	transform: translateX(-25%) rotate(-15deg) scale(0.8)
}

.modal.show .modal-dialog.modal-dialog-popin,
.modal.show .modal-dialog.modal-dialog-popout {
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1)
}

.modal.show .modal-dialog.modal-dialog-slideup,
.modal.show .modal-dialog.modal-dialog-slideright,
.modal.show .modal-dialog.modal-dialog-slideleft {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0)
}

.modal.show .modal-dialog.modal-dialog-fromright,
.modal.show .modal-dialog.modal-dialog-fromleft {
	-webkit-transform: translateX(0) rotate(0) scale(1);
	transform: translateX(0) rotate(0) scale(1)
}

.popover-header {
	padding-bottom: 1px;
	font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	border-bottom: none
}

* {
	box-sizing: border-box;
	-webkit-margin-before: 0;
	margin-block-start: 0;
	-webkit-margin-after: 0;
	margin-block-end: 0;
	-webkit-margin-start: 0;
	margin-inline-start: 0;
	-webkit-margin-end: 0;
	margin-inline-end: 0;
}

html, body{
	height:100%;
	overflow: hidden;
	margin: 0;
    padding: 0;
}

.height100percent{
	height:100%;
}

.flex-wraper{
	-webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -moz-touch-callout: none;
    -ms-touch-callout: none;
    touch-callout: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: default;
}

.flex-container{
	position: relative;
    flex-grow: 1;
    display: block;
    padding: 0;
    margin: 0;
}

#page-container {
	display: block;
    padding: 0;
    margin: 0;


	width: 100%;
	
	position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    flex-grow: 1;

  
}


.wrapper-block{
	flex: 1 1 0;
    overflow: auto;
    padding: 0;
    height: 100%;
}

#main-container,
#page-footer {
	overflow-x: hidden
}

.content {
	margin: 0 auto;
	padding: 10px 10px 1px;
	width: 100%;
	overflow-x: visible
}

.content .pull-t {
	margin-top: -10px
}

.content .pull-b {
	margin-bottom: -1px
}

.content .pull-t-b {
	margin-top: -10px;
	margin-bottom: -1px
}

.content .pull-r-l {
	margin-right: -10px;
	margin-left: -10px
}

.content .pull-all {
	margin: -10px -10px -1px
}

.content.content-full {
	padding-bottom: 10px
}

.content.content-full .pull-b,
.content.content-full .pull-t-b,
.content.content-full .pull-all {
	margin-bottom: -10px
}

.content p,
.content .push,
.content .block,
.content .items-push>div {
	margin-bottom: 10px
}

.content .items-push-2x>div {
	margin-bottom: 20px
}

.content .items-push-3x>div {
	margin-bottom: 30px
}

@media (min-width: 768px) {
	.content {
		margin: 0 auto;
		padding: 24px 24px 1px;
		width: 100%;
		overflow-x: visible
	}
	.content .pull-t {
		margin-top: -24px
	}
	.content .pull-b {
		margin-bottom: -1px
	}
	.content .pull-t-b {
		margin-top: -24px;
		margin-bottom: -1px
	}
	.content .pull-r-l {
		margin-right: -24px;
		margin-left: -24px
	}
	.content .pull-all {
		margin: -24px -24px -1px
	}
	.content.content-full {
		padding-bottom: 24px
	}
	.content.content-full .pull-b,
	.content.content-full .pull-t-b,
	.content.content-full .pull-all {
		margin-bottom: -24px
	}
	.content p,
	.content .push,
	.content .block,
	.content .items-push>div {
		margin-bottom: 24px
	}
	.content .items-push-2x>div {
		margin-bottom: 48px
	}
	.content .items-push-3x>div {
		margin-bottom: 72px
	}
}

@media (min-width: 1200px) {
	.content.content-narrow {
		max-width: 92%
	}
}

.content.content-boxed {
	max-width: 1200px
}

.content-side {
	margin: 0 auto;
	padding: 18px 18px 1px;
	width: 100%;
	overflow-x: hidden
}

.content-side .pull-t {
	margin-top: -18px
}

.content-side .pull-b {
	margin-bottom: -1px
}

.content-side .pull-t-b {
	margin-top: -18px;
	margin-bottom: -1px
}

.content-side .pull-r-l {
	margin-right: -18px;
	margin-left: -18px
}

.content-side .pull-all {
	margin: -18px -18px -1px
}

.content-side.content-side-full {
	padding-bottom: 18px
}

.content-side.content-side-full .pull-b,
.content-side.content-side-full .pull-t-b,
.content-side.content-side-full .pull-all {
	margin-bottom: -18px
}

.content-side p,
.content-side .push,
.content-side .block,
.content-side .items-push>div {
	margin-bottom: 18px
}

.content-side .items-push-2x>div {
	margin-bottom: 36px
}

.content-side .items-push-3x>div {
	margin-bottom: 54px
}

.content-side-user {
	height: 68px;
	background-color: #fbfbfc;
	overflow: hidden
}

.content.content-top {
	padding-top: 76px
}

@media (min-width: 992px) {
	.content.content-top {
		padding-top: 90px
	}
}

.section-top {
	padding-top: 66px
}

#page-header {
	position: relative;
	margin: 0 auto;
	width: 100%;
	background-color: #fff
}

.overlay-header {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #fff;
	opacity: 0;
	transition: opacity .2s ease-in-out, -webkit-transform .2s ease-in-out;
	transition: transform .2s ease-in-out, opacity .2s ease-in-out;
	transition: transform .2s ease-in-out, opacity .2s ease-in-out, -webkit-transform .2s ease-in-out;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
	will-change: opacity, transform
}

.overlay-header.show {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.content-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 auto;
	padding: 16px 18px;
	height: 66px
}

.content-header.content-header-fullrow {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch
}

.content-header-section {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto
}

.content-header-section.content-header-section-wrap {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto
}

.content-header-item {
	display: inline-block;
	height: 34px;
	line-height: 34px;
	vertical-align: middle
}

.content-header-item-align {
	vertical-align: middle
}

.content-header-logo {
	height: 34px
}

#page-header>.content-header {
	padding-left: 10px;
	padding-right: 10px
}

@media (min-width: 768px) {
	#page-header>.content-header {
		padding-left: 24px;
		padding-right: 24px
	}
}

.sidebar-mini-visible,
.sidebar-mini-visible-b {
	display: none
}

.sidebar-mini-show {
	opacity: 0
}

.sidebar-mini-show,
.sidebar-mini-hide {
	transition: opacity .26s cubic-bezier(0.47, 0, 0.745, 0.715)
}

.sidebar-mini-show.sidebar-mini-notrans,
.sidebar-mini-hide.sidebar-mini-notrans {
	transition: none
}

#sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 1033;
	width: 100%;
	background-color: #fff;
	overflow-y: auto;
	-webkit-transform: translateX(-100%) translateY(0) translateZ(0);
	transform: translateX(-100%) translateY(0) translateZ(0);
	-webkit-overflow-scrolling: touch;
	will-change: transform
}

.side-trans-enabled #sidebar {
	transition: -webkit-transform .26s cubic-bezier(0.47, 0, 0.745, 0.715);
	transition: transform .26s cubic-bezier(0.47, 0, 0.745, 0.715);
	transition: transform .26s cubic-bezier(0.47, 0, 0.745, 0.715), -webkit-transform .26s cubic-bezier(0.47, 0, 0.745, 0.715)
}

.sidebar-r #sidebar {
	right: 0;
	left: auto;
	-webkit-transform: translateX(100%) translateY(0) translateZ(0);
	transform: translateX(100%) translateY(0) translateZ(0)
}

@media (max-width: 991.98px) {
	.sidebar-o-xs #sidebar {
		-webkit-transform: translateX(0) translateY(0) translateZ(0);
		transform: translateX(0) translateY(0) translateZ(0)
	}
}

@media (min-width: 992px) {
	#sidebar {
		width: 220px
	}
	.sidebar-o #sidebar {
		-webkit-transform: translateX(0) translateY(0) translateZ(0);
		transform: translateX(0) translateY(0) translateZ(0)
	}
	.sidebar-o.sidebar-mini #sidebar {
		overflow-x: hidden;
		-webkit-transform: translateX(-166px) translateY(0) translateZ(0);
		transform: translateX(-166px) translateY(0) translateZ(0);
		transition: -webkit-transform .26s cubic-bezier(0.47, 0, 0.745, 0.715);
		transition: transform .26s cubic-bezier(0.47, 0, 0.745, 0.715);
		transition: transform .26s cubic-bezier(0.47, 0, 0.745, 0.715), -webkit-transform .26s cubic-bezier(0.47, 0, 0.745, 0.715)
	}
	.sidebar-r.sidebar-o.sidebar-mini #sidebar {
		-webkit-transform: translateX(166px) translateY(0) translateZ(0);
		transform: translateX(166px) translateY(0) translateZ(0)
	}
	.sidebar-o.sidebar-mini #sidebar .sidebar-content {
		width: 220px;
		-webkit-transform: translateX(166px) translateY(0) translateZ(0);
		transform: translateX(166px) translateY(0) translateZ(0);
		transition: -webkit-transform .26s cubic-bezier(0.47, 0, 0.745, 0.715);
		transition: transform .26s cubic-bezier(0.47, 0, 0.745, 0.715);
		transition: transform .26s cubic-bezier(0.47, 0, 0.745, 0.715), -webkit-transform .26s cubic-bezier(0.47, 0, 0.745, 0.715);
		will-change: transform
	}
	.sidebar-o.sidebar-mini #sidebar:hover,
	.sidebar-o.sidebar-mini #sidebar:hover .sidebar-content,
	.sidebar-r.sidebar-o.sidebar-mini #sidebar .sidebar-content {
		-webkit-transform: translateX(0) translateY(0) translateZ(0);
		transform: translateX(0) translateY(0) translateZ(0)
	}
	.sidebar-o.sidebar-mini #sidebar .sidebar-mini-hide {
		opacity: 0
	}
	.sidebar-o.sidebar-mini #sidebar .sidebar-mini-show {
		opacity: 1
	}
	.sidebar-o.sidebar-mini #sidebar .sidebar-mini-hidden {
		display: none
	}
	.sidebar-o.sidebar-mini #sidebar .sidebar-mini-visible {
		display: inline-block
	}
	.sidebar-o.sidebar-mini #sidebar .sidebar-mini-visible-b {
		display: block
	}
	.sidebar-o.sidebar-mini #sidebar .nav-main>li.open>ul {
		display: none
	}
	.sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-hide {
		opacity: 1
	}
	.sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-show {
		opacity: 0
	}
	.sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-hidden {
		display: inline-block
	}
	.sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-hidden-b {
		display: block
	}
	.sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-visible,
	.sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-visible-b {
		display: none;
		opacity: 0
	}
	.sidebar-o.sidebar-mini #sidebar:hover .nav-main>li.open>ul {
		display: block
	}
}

#side-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1033;
	width: 100%;
	background-color: #fff;
	overflow-y: auto;
	-webkit-transform: translateX(100%) translateY(0) translateZ(0);
	transform: translateX(100%) translateY(0) translateZ(0);
	-webkit-overflow-scrolling: touch;
	will-change: transform
}

.side-trans-enabled #side-overlay {
	transition: -webkit-transform .26s cubic-bezier(0.47, 0, 0.745, 0.715);
	transition: transform .26s cubic-bezier(0.47, 0, 0.745, 0.715);
	transition: transform .26s cubic-bezier(0.47, 0, 0.745, 0.715), -webkit-transform .26s cubic-bezier(0.47, 0, 0.745, 0.715)
}

.sidebar-r #side-overlay {
	right: auto;
	left: 0;
	-webkit-transform: translateX(-100%) translateY(0) translateZ(0);
	transform: translateX(-100%) translateY(0) translateZ(0)
}

.side-overlay-o #side-overlay {
	-webkit-transform: translateX(0) translateY(0) translateZ(0);
	transform: translateX(0) translateY(0) translateZ(0)
}

@media (min-width: 992px) {
	#side-overlay {
		width: 320px;
		box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
		-webkit-transform: translateX(110%) translateY(0) translateZ(0);
		transform: translateX(110%) translateY(0) translateZ(0)
	}
	.sidebar-r #side-overlay {
		-webkit-transform: translateX(-110%) translateY(0) translateZ(0);
		transform: translateX(-110%) translateY(0) translateZ(0)
	}
	.side-overlay-hover #side-overlay {
		-webkit-transform: translateX(302px) translateY(0) translateZ(0);
		transform: translateX(302px) translateY(0) translateZ(0)
	}
	.sidebar-r.side-overlay-hover #side-overlay {
		-webkit-transform: translateX(-302px) translateY(0) translateZ(0);
		transform: translateX(-302px) translateY(0) translateZ(0)
	}
	.side-overlay-hover #side-overlay:hover,
	.side-overlay-o #side-overlay,
	.side-overlay-o.side-overlay-hover #side-overlay {
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
		-webkit-transform: translateX(0) translateY(0) translateZ(0);
		transform: translateX(0) translateY(0) translateZ(0)
	}
}

@media (min-width: 1200px) {
	#page-container.main-content-narrow>#page-header .content-header,
	#page-container.main-content-narrow>#page-header .content,
	#page-container.main-content-narrow>#main-container .content,
	#page-container.main-content-narrow>#page-footer .content {
		max-width: 92%
	}
}

#page-container.main-content-boxed>#page-header .content-header,
#page-container.main-content-boxed>#page-header .content,
#page-container.main-content-boxed>#main-container .content,
#page-container.main-content-boxed>#page-footer .content {
	max-width: 1200px
}

@media (min-width: 992px) {
	#page-container.page-header-modern #page-header {
		background-color: #f5f6f7;
		box-shadow: none!important
	}
	#page-container.page-header-modern #page-header>.content-header {
		padding-top: 30px;
		padding-bottom: 2px
	}
}

#page-container.page-header-inverse #page-header {
	color: #d3d7dc;
	background-color: #343a40
}

#page-container.page-header-glass #page-header {
	position: absolute;
	background-color: transparent
}

#page-container.page-header-glass.page-header-fixed #page-header {
	transition: background-color .2s linear;
	will-change: background-color;
	box-shadow: none
}

#page-container.page-header-glass.page-header-fixed.page-header-scroll #page-header {
	background-color: #fff;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.02)
}

#page-container.page-header-glass.page-header-fixed.page-header-scroll.page-header-inverse #page-header {
	background-color: #343a40;
	box-shadow: none
}

#page-container.page-header-glass #main-container,
#page-container.page-header-glass.page-header-fixed #main-container {
	padding-top: 0
}

#page-container.page-header-fixed #page-header {
	position: fixed;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.02)
}

#page-container.page-header-fixed #page-header>.content-header {
	padding-top: 16px;
	padding-bottom: 16px
}

#page-container.page-header-fixed.page-header-inverse #page-header {
	box-shadow: none
}

#page-container.page-header-fixed #main-container {
	padding-top: 66px
}

#page-container.page-header-fixed #page-header,
#page-container.page-header-glass #page-header {
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
	min-width: 320px;
	max-width: 100%;
	width: auto
}

@media (min-width: 992px) {
	#page-container.page-header-fixed.sidebar-o #page-header,
	#page-container.page-header-glass.sidebar-o #page-header {
		left: 220px
	}
	#page-container.page-header-fixed.sidebar-r.sidebar-o #page-header,
	#page-container.page-header-glass.sidebar-r.sidebar-o #page-header {
		right: 220px;
		left: 0
	}
	#page-container.page-header-fixed.sidebar-o.sidebar-mini #page-header,
	#page-container.page-header-glass.sidebar-o.sidebar-mini #page-header {
		left: 54px
	}
	#page-container.page-header-fixed.sidebar-r.sidebar-o.sidebar-mini #page-header,
	#page-container.page-header-glass.sidebar-r.sidebar-o.sidebar-mini #page-header {
		right: 54px;
		left: 0
	}
}

#page-container.page-header-modern #sidebar {
	box-shadow: 5px 0 10px rgba(0, 0, 0, 0.02)
}

#page-container.sidebar-inverse #sidebar {
	color: #eaecee;
	background-color: #343a40;
	box-shadow: none
}

#page-container.sidebar-inverse #sidebar .content-side-user {
	background-color: #2d3238
}

@media (min-width: 992px) {
	#page-container.sidebar-o {
		padding-left: 220px
	}
	#page-container.sidebar-r.sidebar-o {
		padding-right: 220px;
		padding-left: 0
	}
	#page-container.sidebar-o.sidebar-mini {
		padding-left: 54px
	}
	#page-container.sidebar-r.sidebar-o.sidebar-mini {
		padding-right: 54px;
		padding-left: 0
	}
	#page-container.side-scroll #sidebar,
	#page-container.side-scroll #side-overlay {
		overflow-y: hidden
	}
}

.hero {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	min-height: 500px
}

.hero.hero-sm {
	min-height: 300px
}

.hero.hero-lg {
	min-height: 800px
}

.hero-inner {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: 100%
}

.hero-static {
	min-height: 100vh
}

.block {
	margin-bottom: 24px;
	background-color: #fff
}

.block .block,
.content-side .block {
	box-shadow: none
}

.block-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 14px 18px;
	transition: opacity .2s ease-out
}

.block-header.block-header-rtl {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse
}

.block-header.block-header-rtl .block-title {
	text-align: right
}

.block-header.block-header-rtl .block-options {
	padding-right: 10px;
	padding-left: 0
}

.block-header-default {
	background-color: #fbfbfc
}

.block-title {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	min-height: 28px;
	margin: 0;
	font-size: 1.142857rem;
	font-weight: 400;
	line-height: 28px
}

.block-title small {
	font-size: 1rem;
	font-weight: 400;
	line-height: 22px;
	color: #646464
}

.block-content {
	transition: opacity .2s ease-out;
	margin: 0 auto;
	padding: 18px 18px 1px;
	width: 100%;
	overflow-x: visible
}

.block-content .pull-t {
	margin-top: -18px
}

.block-content .pull-b {
	margin-bottom: -1px
}

.block-content .pull-t-b {
	margin-top: -18px;
	margin-bottom: -1px
}

.block-content .pull-r-l {
	margin-right: -18px;
	margin-left: -18px
}

.block-content .pull-all {
	margin: -18px -18px -1px
}

.block-content.block-content-full {
	padding-bottom: 18px
}

.block-content.block-content-full .pull-b,
.block-content.block-content-full .pull-t-b,
.block-content.block-content-full .pull-all {
	margin-bottom: -18px
}

.block-content p,
.block-content .push,
.block-content .block,
.block-content .items-push>div {
	margin-bottom: 18px
}

.block-content .items-push-2x>div {
	margin-bottom: 36px
}

.block-content .items-push-3x>div {
	margin-bottom: 54px
}

.block-content.block-content-sm {
	padding-top: 9px
}

.block-content.block-content-sm .pull-t,
.block-content.block-content-sm .pull-t-b,
.block-content.block-content-sm .pull-all {
	margin-top: -9px
}

.block-content.block-content-sm.block-content-full {
	padding-bottom: 9px
}

.block-content.block-content-sm.block-content-full .pull-b,
.block-content.block-content-sm.block-content-full .pull-t-b,
.block-content.block-content-sm.block-content-full .pull-all {
	margin-bottom: -9px
}

.block-table {
	display: table;
	border-collapse: collapse;
	width: 100%
}

.block-table .block-row {
	display: table-row
}

.block-table .block-cell {
	display: table-cell;
	padding: 18px;
	vertical-align: middle
}

.block.block-bordered {
	border: 1px solid #eaecee;
	box-shadow: none
}

.block.block-rounded {
	border-radius: 4px
}

.block.block-rounded>.block-header {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px
}

.block.block-rounded>.block-header:last-child {
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px
}

.block.block-rounded>.block-content:first-child {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px
}

.block.block-rounded>.block-content:last-child {
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px
}

.block.block-themed>.block-header {
	border-bottom: none;
	color: #fff;
	background-color: #42a5f5
}

.block.block-themed>.block-header>.block-title {
	color: rgba(255, 255, 255, 0.9)
}

.block.block-themed>.block-header>.block-title small {
	color: rgba(255, 255, 255, 0.7)
}

.block.block-transparent {
	background-color: transparent;
	box-shadow: none
}

.block.block-shadow {
	box-shadow: 0 15px 30px rgba(33, 37, 41, 0.05)
}

.block.block-mode-hidden.block-bordered>.block-header {
	border-bottom: none
}

.block.block-mode-hidden>.block-content {
	display: none
}

.block.block-mode-loading {
	position: relative;
	overflow: hidden
}

.block.block-mode-loading>.block-header,
.block.block-mode-loading>.block-content,
.block.block-mode-loading>.nav-tabs {
	opacity: .05
}

.block.block-mode-loading::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	z-index: 1;
	content: " "
}

.block.block-mode-loading::after {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -25px 0 0 -25px;
	width: 50px;
	height: 50px;
	line-height: 50px;
	color: #343a40;
	font-family: Simple-Line-Icons;
	font-size: 18px;
	text-align: center;
	z-index: 2;
	content: "\e09a";
	-webkit-animation: fa-spin 1.75s infinite linear;
	animation: fa-spin 1.75s infinite linear;
	border-radius: 25px;
	box-shadow: 0 0 15px 15px rgba(52, 58, 64, 0.05)
}

.block.block-mode-loading.block-mode-hidden::after {
	margin: -15px 0 0 -15px;
	width: 30px;
	height: 30px;
	line-height: 30px;
	box-shadow: 0 0 8px 8px rgba(52, 58, 64, 0.05)
}

.block.block-mode-loading.block-mode-loading-inverse::after {
	background-color: #343a40;
	color: #fff;
	box-shadow: 0 0 15px 15px rgba(255, 255, 255, 0.5)
}

.block.block-mode-loading.block-mode-loading-location::after {
	content: "\e06e"
}

.block.block-mode-loading.block-mode-loading-energy::after {
	content: "\e020"
}

.block.block-mode-loading.block-mode-loading-refresh::after {
	font-family: "FontAwesome";
	content: "\f021"
}

.block.block-mode-loading.block-mode-loading-sun::after {
	font-family: "FontAwesome";
	content: "\f185"
}

.block.block-mode-loading.block-mode-loading-repeat::after {
	font-family: "FontAwesome";
	content: "\f01e"
}

.lt-ie10 .block.block-mode-loading::after {
	content: "Loading.."!important
}

.block.block-mode-fullscreen {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1034;
	margin-bottom: 0;
	overflow-y: auto;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-overflow-scrolling: touch
}

.block.block-mode-fullscreen.block-bordered {
	border: none
}

.block.block-mode-fullscreen.block-rounded {
	border-radius: 0
}

.block.block-mode-fullscreen.block-transparent {
	background-color: #fff
}

.block.block-mode-pinned {
	position: fixed;
	right: 10px;
	bottom: 0;
	margin-bottom: 0!important;
	width: 100%;
	max-width: 300px;
	z-index: 1031;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.1)
}

.block.block-mode-pinned>.block-content {
	max-height: 250px;
	overflow-y: auto
}

a.block {
	display: block;
	color: #575757;
	font-weight: 400;
	transition: all .12s ease-out
}

a.block:hover {
	color: #575757;
	opacity: 1
}

a.block:active {
	opacity: 1
}

a.block.block-link-rotate:hover {
	-webkit-transform: rotate(1deg);
	transform: rotate(1deg);
	opacity: 1
}

a.block.block-link-rotate:active {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

a.block.block-link-pop:hover {
	box-shadow: 0 2px 30px #dee2e5;
	-webkit-transform: translateY(-3px);
	transform: translateY(-3px);
	opacity: 1
}

a.block.block-link-pop:active {
	box-shadow: 0 2px 10px #f2f3f5;
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

a.block.block-link-shadow:hover {
	opacity: 1
}

a.block.block-link-shadow:active {
	box-shadow: 0 0 10px #eaecee
}

.block.block-fx-rotate {
	-webkit-transform: rotate(1deg);
	transform: rotate(1deg);
	opacity: 1
}

.block.block-fx-pop {
	box-shadow: 0 2px 30px #dee2e5;
	-webkit-transform: translateY(-3px);
	transform: translateY(-3px);
	opacity: 1
}

.block.block-fx-shadow {
	box-shadow: 0 0 36px #dee2e5;
	opacity: 1
}

.block-options {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	padding-left: 10px;
	min-height: 28px
}

.block-options .block-options-item {
	padding: 6px 5px;
	line-height: 1.2
}

.block.block-themed>.block-header .block-options .block-options-item {
	color: #fff
}

.block-options .block-options-item,
.block-options .dropdown,
.block-options .dropup,
.block-options .dropright,
.block-options .dropleft {
	display: inline-block
}

.block-sticky-options {
	position: relative
}

.block-sticky-options .block-options {
	position: absolute;
	top: 14px;
	right: 18px
}

.block-sticky-options .block-options.block-options-left {
	right: auto;
	left: 18px;
	padding-right: 10px;
	padding-left: 0
}

.btn-block-option {
	display: inline-block;
	padding: 6px 5px;
	line-height: 1.2;
	color: #6c757d;
	background: none;
	border: none;
	cursor: pointer
}

.btn-block-option .si {
	position: relative;
	top: 1px
}

.btn-block-option:hover {
	text-decoration: none;
	color: #343a40
}

a.btn-block-option:focus,
.active>a.btn-block-option,
.show>button.btn-block-option {
	text-decoration: none;
	color: #343a40
}

.btn-block-option:active {
	color: #6c757d
}

a.btn-block-option:focus,
.active>a.btn-block-option,
.show>button.btn-block-option {
	text-decoration: none;
	color: #343a40
}

.block.block-themed>.block-header>.block-options .btn-block-option,
.block.block-themed>.block-content>.block-options .btn-block-option {
	color: #fff;
	opacity: .7
}

.block.block-themed>.block-header>.block-options .btn-block-option:hover,
.block.block-themed>.block-content>.block-options .btn-block-option:hover {
	color: #fff;
	opacity: 1
}

.block.block-themed>.block-header>.block-options .btn-block-option:active,
.block.block-themed>.block-content>.block-options .btn-block-option:active {
	color: #fff;
	opacity: .6
}

.block.block-themed>.block-header>.block-options a.btn-block-option:focus,
.block.block-themed>.block-header>.block-options .active>a.btn-block-option,
.block.block-themed>.block-header>.block-options .show>button.btn-block-option,
.block.block-themed>.block-content>.block-options a.btn-block-option:focus,
.block.block-themed>.block-content>.block-options .active>a.btn-block-option,
.block.block-themed>.block-content>.block-options .show>button.btn-block-option {
	color: #fff;
	opacity: 1
}

#page-loader {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #42a5f5;
	z-index: 999998;
	transition: -webkit-transform .35s ease-out;
	transition: transform .35s ease-out;
	transition: transform .35s ease-out, -webkit-transform .35s ease-out;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
	will-change: transform
}

#page-loader.show {
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

#page-loader::after,
#page-loader::before {
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	border-radius: 100%;
	content: "";
	z-index: 999999
}

#page-loader::after {
	margin-top: -100px;
	margin-left: -100px;
	width: 200px;
	height: 200px;
	background-color: rgba(255, 255, 255, 0.5);
	border: 3px solid rgba(255, 255, 255, 0.75);
	-webkit-animation: page-loader-inner 1.5s infinite ease-out;
	animation: page-loader-inner 1.5s infinite ease-out
}

#page-loader::before {
	margin-top: -30px;
	margin-left: -30px;
	width: 60px;
	height: 60px;
	background-color: #fff;
	-webkit-animation: page-loader-outer 1.5s infinite ease-in;
	animation: page-loader-outer 1.5s infinite ease-in
}

.lt-ie10 #page-loader::after {
	text-align: center;
	content: "Loading..";
	background-color: transparent
}

.lt-ie10 #page-loader::before {
	display: none
}

@-webkit-keyframes page-loader-inner {
	0%,
	40% {
		-webkit-transform: scale(0);
		transform: scale(0)
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0
	}
}

@keyframes page-loader-inner {
	0%,
	40% {
		-webkit-transform: scale(0);
		transform: scale(0)
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0
	}
}

@-webkit-keyframes page-loader-outer {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
	40%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 0
	}
}

@keyframes page-loader-outer {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
	40%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 0
	}
}

.nav-main {
	margin: 0 -18px;
	padding: 0;
	list-style: none
}

.nav-main .nav-main-heading {
	padding: 24px 18px 6px;
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
	opacity: .5
}

.nav-main a {
	position: relative;
	display: block;
	padding: 15px 18px 15px 40px;
	font-size: 13px;
	font-weight: 600;
	color: rgba(87, 87, 87, 0.8);
	text-transform: uppercase
}

.sidebar-inverse #sidebar .nav-main a {
	color: rgba(234, 236, 238, 0.6)
}

.nav-main a>i {
	position: absolute;
	top: 50%;
	right: auto;
	left: 18px;
	-webkit-transform: translateX(0) translateY(-50%);
	transform: translateX(0) translateY(-50%);
	font-size: 14px;
	color: #343a40
}

.sidebar-inverse #sidebar .nav-main a>i {
	color: #eaecee
}

.nav-main a:hover,
.nav-main a:focus,
.nav-main a.active {
	color: #000;
	background-color: #fbfbfc
}

.sidebar-inverse #sidebar .nav-main a:hover,
.sidebar-inverse #sidebar .nav-main a:focus,
.sidebar-inverse #sidebar .nav-main a.active {
	color: #fff
}

.nav-main a:hover>i,
.nav-main a:focus>i,
.nav-main a.active>i {
	color: #42a5f5
}

.sidebar-inverse #sidebar .nav-main a:hover>i,
.sidebar-inverse #sidebar .nav-main a:focus>i,
.sidebar-inverse #sidebar .nav-main a.active>i {
	color: #42a5f5
}

.nav-main a.nav-submenu {
	padding-right: 35px
}

.nav-main a.nav-submenu::before,
.nav-main a.nav-submenu::after {
	position: absolute;
	top: 50%;
	right: 15px;
	margin-top: -11px;
	width: 20px;
	height: 20px;
	display: block;
	line-height: 20px;
	text-align: center;
	font-family: "FontAwesome";
	font-size: 14px;
	transition: all .2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
}

.nav-main a.nav-submenu::before {
	content: "\f104";
	opacity: .4
}

.nav-main a.nav-submenu::after {
	content: "\f103";
	opacity: 0;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.nav-main ul {
	margin: 0;
	padding: 0 0 0 40px;
	height: 0;
	list-style: none;
	overflow: hidden
}

.nav-main ul>li {
	opacity: 0;
	transition: all .2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	-webkit-transform: translateY(-15px);
	transform: translateY(-15px)
}

.nav-main ul .nav-main-heading {
	padding-left: 0;
	padding-right: 0
}

.nav-main ul a {
	padding: 5px 8px 5px 0;
	text-transform: none;
	color: rgba(87, 87, 87, 0.5)
}

.nav-main ul a:hover,
.nav-main ul a:focus {
	background-color: transparent
}

.nav-main ul a>i {
	margin-right: 10px
}

.nav-main ul ul {
	padding-left: 12px
}

.nav-main li.open>a.nav-submenu {
	color: #000
}

.sidebar-inverse #sidebar .nav-main li.open>a.nav-submenu {
	color: #fff
}

.nav-main li.open>a.nav-submenu>i {
	color: #42a5f5
}

.sidebar-inverse #sidebar .nav-main li.open>a.nav-submenu>i {
	color: #42a5f5
}

.nav-main li.open>a.nav-submenu::before {
	opacity: 0;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg)
}

.nav-main li.open>a.nav-submenu::after {
	opacity: .4;
	-webkit-transform: rotate(0);
	transform: rotate(0)
}

.nav-main li.open>ul {
	height: auto;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: #fbfbfc
}

.sidebar-inverse #sidebar .nav-main li.open>ul {
	background-color: #2d3238
}

.nav-main li.open>ul>li {
	opacity: 1;
	-webkit-transform: translateX(0);
	transform: translateX(0)
}

.nav-main-header {
	display: none
}

@media (min-width: 992px) {
	.nav-main-header {
		margin: 0 -3px;
		padding: 0;
		display: inline-block;
		list-style: none;
		vertical-align: middle
	}
	.nav-main-header li {
		position: relative;
		display: inline-block;
		margin: 0 3px
	}
	.nav-main-header a {
		position: relative;
		display: inline-block;
		padding: 9px 14px;
		font-weight: 600;
		line-height: 16px;
		border-radius: 3px
	}
	.nav-main-header a>i {
		margin-right: 8px
	}
	.nav-main-header a>i.si {
		position: relative;
		top: 1px
	}
	.nav-main-header a.nav-submenu {
		padding-right: 28px
	}
	.nav-main-header a.nav-submenu::before {
		position: absolute;
		top: 50%;
		right: 6px;
		margin-top: -10px;
		width: 20px;
		height: 20px;
		display: block;
		text-align: center;
		line-height: 20px;
		font-family: "FontAwesome";
		content: "\f107";
		opacity: .5
	}
	.nav-main-header ul {
		position: absolute;
		top: 100%;
		left: 0;
		margin: 0;
		padding: 15px 0;
		display: none;
		z-index: 1032
	}
	.nav-main-header ul li {
		display: block;
		margin: 0
	}
	.nav-main-header ul a {
		display: block;
		border-radius: 0;
		min-width: 160px
	}
	.nav-main-header ul a.nav-submenu::before {
		content: "\f105"
	}
	.nav-main-header ul ul {
		top: -15px;
		left: 100%
	}
	.nav-main-header li:hover>a.nav-submenu {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0
	}
	.nav-main-header li:hover>ul {
		display: block
	}
	.nav-main-header>li:last-child ul {
		right: 0;
		left: auto
	}
	.nav-main-header>li:last-child ul a.nav-submenu::before {
		content: "\f104"
	}
	.nav-main-header>li:last-child ul ul {
		right: 100%;
		left: auto
	}
	.nav-main-header a {
		color: #717171
	}
	.nav-main-header ul a {
		color: #8f8f8f
	}
	.nav-main-header a:hover,
	.nav-main-header a:focus,
	.nav-main-header a.active,
	.nav-main-header li.open>a.nav-submenu,
	.nav-main-header li:hover>a.nav-submenu {
		color: #000;
		background-color: #eaecee
	}
	.nav-main-header ul,
	.nav-main-header>li:hover>a.nav-submenu {
		background-color: #e1e4e7
	}
	.page-header-inverse #page-header .nav-main-header a,
	.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a {
		color: #d0d5da
	}
	.page-header-inverse #page-header .nav-main-header ul a,
	.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header ul a {
		color: #b7bec5
	}
	.page-header-inverse #page-header .nav-main-header a:hover,
	.page-header-inverse #page-header .nav-main-header a:focus,
	.page-header-inverse #page-header .nav-main-header a.active,
	.page-header-inverse #page-header .nav-main-header li.open>a.nav-submenu,
	.page-header-inverse #page-header .nav-main-header li:hover>a.nav-submenu,
	.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a:hover,
	.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a:focus,
	.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a.active,
	.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header li.open>a.nav-submenu,
	.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header li:hover>a.nav-submenu {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.25)
	}
	.page-header-inverse #page-header .nav-main-header ul,
	.page-header-inverse #page-header .nav-main-header>li:hover>a.nav-submenu,
	.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header ul,
	.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header>li:hover>a.nav-submenu {
		background-color: #343a40
	}
	.page-header-inverse.page-header-glass #page-header .nav-main-header ul,
	.page-header-inverse.page-header-glass #page-header .nav-main-header>li:hover>a.nav-submenu {
		background-color: #292d32
	}
	.nav-main-header .nav-main-heading {
		display: none
	}
	.nav-main-header.nav-main-header-no-icons a>i {
		display: none
	}
}

.form-material {
	position: relative;
	padding-top: 26px
}

.form-material>label {
	position: absolute;
	top: 6px;
	left: 0;
	margin-bottom: 0
}

.form-material.floating>label {
	font-size: 1rem;
	color: #7e808c;
	font-weight: 500;
	cursor: text;
	z-index: 2;
	transition: all .2s ease-out;
	-webkit-transform: translateY(25px);
	transform: translateY(25px)
}

.form-material.form-material-sm.floating>label {
	font-size: 1rem;
	-webkit-transform: translateY(22px);
	transform: translateY(22px)
}

.form-material.form-material-lg.floating>label {
	font-size: 1.285714rem;
	-webkit-transform: translateY(27px);
	transform: translateY(27px)
}

.form-material>label,
.form-material>.form-control:focus+label,
.form-material.floating.open>label {
	font-size: 1rem;
	font-weight: 600;
	cursor: default;
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.form-material.floating>.form-control:-webkit-autofill+label {
	font-size: 1rem;
	font-weight: 600;
	cursor: default;
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.form-material.floating>.form-control[disabled]+label,
.form-material.floating>.form-control[readonly]+label,
fieldset[disabled] .form-material.floating>label {
	cursor: not-allowed
}

.form-material>.form-control {
	padding-left: 0;
	padding-right: 0;
	border-color: transparent;
	border-radius: 0;
	background-color: transparent;
	box-shadow: 0 1px 0 #dcdfe3;
	transition: box-shadow .3s ease-out
}

.form-material>.form-control~.input-group-append {
	box-shadow: 0 1px 0 #dcdfe3;
	transition: box-shadow .3s ease-out
}

.form-material>.form-control:focus {
	background-color: transparent;
	border-color: transparent;
	box-shadow: 0 2px 0 #a3acb5
}

.form-material>.form-control:focus+label {
	color: #575757
}

.form-material>.form-control:focus~.input-group-append {
	box-shadow: 0 2px 0 #a3acb5
}

.form-material>select.form-control {
	border-radius: 1px
}

.form-material .form-control[disabled],
.form-material .form-control[readonly],
.form-material fieldset[disabled] .form-control {
	background-color: #fff;
	border-bottom: 1px dashed #ccc;
	box-shadow: none
}

.form-material.input-group .input-group-text {
	border: none;
	background-color: transparent
}

.form-material.form-material-primary>.form-control:focus {
	box-shadow: 0 2px #42a5f5
}

.form-material.form-material-primary>.form-control:focus+label {
	color: #42a5f5
}

.form-material.form-material-primary>.form-control:focus~.input-group-append {
	color: #42a5f5;
	box-shadow: 0 2px #42a5f5
}

.form-material.form-material-success>.form-control:focus {
	box-shadow: 0 2px #9ccc65
}

.form-material.form-material-success>.form-control:focus+label {
	color: #9ccc65
}

.form-material.form-material-success>.form-control:focus~.input-group-append {
	color: #9ccc65;
	box-shadow: 0 2px #9ccc65
}

.form-material.form-material-info>.form-control:focus {
	box-shadow: 0 2px #26c6da
}

.form-material.form-material-info>.form-control:focus+label {
	color: #26c6da
}

.form-material.form-material-info>.form-control:focus~.input-group-append {
	color: #26c6da;
	box-shadow: 0 2px #26c6da
}

.form-material.form-material-warning>.form-control:focus {
	box-shadow: 0 2px #ffca28
}

.form-material.form-material-warning>.form-control:focus+label {
	color: #ffca28
}

.form-material.form-material-warning>.form-control:focus~.input-group-append {
	color: #ffca28;
	box-shadow: 0 2px #ffca28
}

.form-material.form-material-danger>.form-control:focus {
	box-shadow: 0 2px #ef5350
}

.form-material.form-material-danger>.form-control:focus+label {
	color: #ef5350
}

.form-material.form-material-danger>.form-control:focus~.input-group-append {
	color: #ef5350;
	box-shadow: 0 2px #ef5350
}

.is-valid .form-material>.form-control {
	box-shadow: 0 1px 0 #9ccc65
}

.is-valid .form-material>.form-control~.input-group-append {
	color: #9ccc65;
	box-shadow: 0 1px 0 #9ccc65
}

.is-valid .form-material>.form-control:focus {
	box-shadow: 0 2px #9ccc65
}

.is-valid .form-material>.form-control:focus+label {
	color: #9ccc65
}

.is-valid .form-material>.form-control:focus~.input-group-append {
	color: #9ccc65;
	box-shadow: 0 2px #9ccc65
}

.is-valid .form-material~.invalid-feedback,
.is-valid .form-material~.invalid-tooltip {
	display: block
}

.is-valid .form-material label {
	color: #9ccc65
}

.is-invalid .form-material>.form-control {
	box-shadow: 0 1px 0 #ef5350
}

.is-invalid .form-material>.form-control~.input-group-append {
	color: #ef5350;
	box-shadow: 0 1px 0 #ef5350
}

.is-invalid .form-material>.form-control:focus {
	box-shadow: 0 2px #ef5350
}

.is-invalid .form-material>.form-control:focus+label {
	color: #ef5350
}

.is-invalid .form-material>.form-control:focus~.input-group-append {
	color: #ef5350;
	box-shadow: 0 2px #ef5350
}

.is-invalid .form-material~.invalid-feedback,
.is-invalid .form-material~.invalid-tooltip {
	display: block
}

.is-invalid .form-material label {
	color: #ef5350
}

.img-avatar {
	display: inline-block!important;
	margin-top: 12px;
	width: 65px;
	border-radius: 50%
}

.img-avatar.img-avatar16 {
	width: 16px;
	height: 16px
}

.img-avatar.img-avatar20 {
	width: 20px;
	height: 20px
}

.img-avatar.img-avatar32 {
	width: 32px;
	height: 32px
}

.img-avatar.img-avatar48 {
	width: 48px;
	height: 48px
}

.img-avatar.img-avatar96 {
	width: 96px;
	height: 96px
}

.img-avatar.img-avatar128 {
	width: 128px;
	height: 128px
}

.img-avatar-thumb {
	margin: 5px;
	box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4)
}

.img-avatar.img-avatar-rounded {
	border-radius: 3px
}

.img-avatar.img-avatar-square {
	border-radius: 0
}

.img-thumb {
	padding: 6px;
	background-color: #fff;
	border-radius: 3px
}

.img-link {
	display: inline-block;
	transition: opacity .25s ease-out, -webkit-transform .25s ease-out;
	transition: transform .25s ease-out, opacity .25s ease-out;
	transition: transform .25s ease-out, opacity .25s ease-out, -webkit-transform .25s ease-out
}

.img-link:hover {
	-webkit-transform: scale(1.04);
	transform: scale(1.04);
	opacity: .75
}

.img-link:active {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1
}

.img-link.img-link-simple:hover {
	-webkit-transform: scale(1);
	transform: scale(1)
}

.img-link-zoom-in {
	cursor: -webkit-zoom-in;
	cursor: zoom-in
}

.img-fluid.img-fluid-100,
.img-fluid-100 .img-fluid {
	width: 100%
}

.list {
	margin: 0;
	padding: 0;
	list-style: none
}

.list>li {
	position: relative
}

.list.list-simple>li,
.list-li-push>li {
	margin-bottom: 20px
}

.list.list-simple-mini>li,
.list-li-push-sm>li {
	margin-bottom: 10px
}

.list-timeline {
	position: relative;
	padding-top: 20px
}

.list-timeline>li {
	margin-bottom: 10px
}

.list-timeline .list-timeline-time {
	margin: 0;
	padding: 10px 20px;
	min-height: 50px;
	text-align: right;
	color: #999;
	line-height: 30px;
	font-style: italic;
	background-color: #fbfbfc;
	border-radius: 2px
}

.list-timeline .list-timeline-icon {
	position: absolute;
	top: 8px;
	left: 10px;
	width: 34px;
	height: 34px;
	line-height: 34px;
	color: #fff;
	text-align: center;
	border-radius: 10px
}

.list-timeline .list-timeline-content {
	padding: 10px 10px 1px
}

.list-timeline .list-timeline-content>p:first-child {
	margin-bottom: 0
}

@media (min-width: 768px) {
	.list-timeline-modern::before {
		position: absolute;
		top: 0;
		left: 119px;
		bottom: 0;
		display: block;
		width: 6px;
		content: "";
		background-color: #fbfbfc;
		z-index: 1
	}
	.list-timeline-modern>li {
		min-height: 40px;
		z-index: 2
	}
	.list-timeline-modern>li:last-child {
		margin-bottom: 0
	}
	.list-timeline-modern .list-timeline-time {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		padding-right: 0;
		padding-left: 0;
		width: 90px;
		font-size: .928571rem;
		line-height: normal;
		background-color: transparent
	}
	.list-timeline-modern .list-timeline-icon {
		top: 3px;
		left: 105px;
		z-index: 2!important
	}
	.list-timeline-modern .list-timeline-content {
		padding-left: 155px
	}
}

.list-activity>li {
	margin-bottom: 10px;
	padding-bottom: 7px;
	padding-left: 35px;
	border-bottom: 1px solid #f5f6f7
}

.list-activity>li>i:first-child {
	position: absolute;
	left: 0;
	top: 0;
	display: inline-block;
	width: 20px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	text-align: center
}

.list-activity>li:last-child {
	border-bottom: none
}

.list-events>li {
	margin-bottom: 7px;
	padding: 10px 30px 10px 7px;
	font-size: .928571rem;
	font-weight: 600;
	background-color: #e3f4fc;
	border-left: 3px dotted rgba(0, 0, 0, 0.1);
	transition: -webkit-transform .25s ease-out;
	transition: transform .25s ease-out;
	transition: transform .25s ease-out, -webkit-transform .25s ease-out
}

.list-events>li::before {
	position: absolute;
	top: 50%;
	right: auto;
	left: auto;
	-webkit-transform: translateX(0) translateY(-50%);
	transform: translateX(0) translateY(-50%);
	right: 10px;
	display: inline-block;
	font-family: "FontAwesome";
	color: rgba(0, 0, 0, 0.3);
	content: "\f073"
}

.list-events>li:hover {
	cursor: move;
	border-left-color: rgba(0, 0, 0, 0.3);
	-webkit-transform: translateX(-5px);
	transform: translateX(-5px)
}

.item {
	display: block;
	width: 60px;
	height: 60px;
	text-align: center;
	font-size: 28px;
	font-weight: 300;
	line-height: 60px
}

a.item:hover,
a.item:focus {
	opacity: .6
}

.item.item-circle {
	border-radius: 50%
}

.item.item-rounded {
	border-radius: 3px
}

.item.item-rounded-big {
	border-radius: 24px
}

.item.item-rounded-big.item-2x {
	border-radius: 35px
}

.item.item-rounded-big.item-3x {
	border-radius: 50px
}

.item.item-2x {
	width: 100px;
	height: 100px;
	line-height: 100px
}

.item.item-3x {
	width: 150px;
	height: 150px;
	line-height: 150px
}

.ribbon {
	position: relative;
	min-height: 10px
}

.ribbon-box {
	position: absolute;
	top: 10px;
	right: 0;
	padding: 0 10px;
	height: 22px;
	line-height: 21px;
	color: #fff;
	font-weight: 600;
	z-index: 0;
	margin-top: .5rem
}

.ribbon-box::before {
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	content: ""
}

.ribbon-bookmark .ribbon-box {
	padding-left: 10px
}

.ribbon-bookmark .ribbon-box::before {
	top: 0;
	right: 100%;
	height: 22px;
	border: 11px solid;
	border-left-width: 8px;
	border-right-width: 0
}

.ribbon-modern .ribbon-box {
	top: 10px;
	right: -8px;
	padding-left: 12px;
	padding-right: 12px;
	border-radius: 16px
}

.ribbon-modern .ribbon-box::before {
	display: none
}

.ribbon-left .ribbon-box {
	right: auto;
	left: 0
}

.ribbon-left.ribbon-bookmark .ribbon-box {
	padding-left: 12px;
	padding-right: 10px
}

.ribbon-left.ribbon-bookmark .ribbon-box::before {
	right: auto;
	left: 100%;
	border-left-width: 0;
	border-right-width: 8px
}

.ribbon-left.ribbon-modern .ribbon-box {
	left: -8px
}

.ribbon-bottom .ribbon-box {
	top: auto;
	bottom: 10px
}

.ribbon-primary .ribbon-box {
	background-color: #42a5f5
}

.ribbon-primary.ribbon-bookmark .ribbon-box::before {
	border-color: #42a5f5;
	border-left-color: transparent
}

.ribbon-primary.ribbon-bookmark.ribbon-left .ribbon-box::before {
	border-color: #42a5f5;
	border-right-color: transparent
}

.ribbon-primary.ribbon-modern .ribbon-box::before {
	border-color: #42a5f5;
	border-left-color: transparent;
	border-bottom-color: transparent
}

.ribbon-primary.ribbon-modern.ribbon-bottom .ribbon-box::before {
	border-color: #42a5f5;
	border-top-color: transparent;
	border-left-color: transparent
}

.ribbon-primary.ribbon-modern.ribbon-left .ribbon-box::before {
	border-color: #42a5f5;
	border-right-color: transparent;
	border-bottom-color: transparent
}

.ribbon-primary.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
	border-color: #42a5f5;
	border-top-color: transparent;
	border-right-color: transparent
}

.ribbon-success .ribbon-box {
	background-color: #9ccc65
}

.ribbon-success.ribbon-bookmark .ribbon-box::before {
	border-color: #9ccc65;
	border-left-color: transparent
}

.ribbon-success.ribbon-bookmark.ribbon-left .ribbon-box::before {
	border-color: #9ccc65;
	border-right-color: transparent
}

.ribbon-success.ribbon-modern .ribbon-box::before {
	border-color: #9ccc65;
	border-left-color: transparent;
	border-bottom-color: transparent
}

.ribbon-success.ribbon-modern.ribbon-bottom .ribbon-box::before {
	border-color: #9ccc65;
	border-top-color: transparent;
	border-left-color: transparent
}

.ribbon-success.ribbon-modern.ribbon-left .ribbon-box::before {
	border-color: #9ccc65;
	border-right-color: transparent;
	border-bottom-color: transparent
}

.ribbon-success.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
	border-color: #9ccc65;
	border-top-color: transparent;
	border-right-color: transparent
}

.ribbon-info .ribbon-box {
	background-color: #26c6da
}

.ribbon-info.ribbon-bookmark .ribbon-box::before {
	border-color: #26c6da;
	border-left-color: transparent
}

.ribbon-info.ribbon-bookmark.ribbon-left .ribbon-box::before {
	border-color: #26c6da;
	border-right-color: transparent
}

.ribbon-info.ribbon-modern .ribbon-box::before {
	border-color: #26c6da;
	border-left-color: transparent;
	border-bottom-color: transparent
}

.ribbon-info.ribbon-modern.ribbon-bottom .ribbon-box::before {
	border-color: #26c6da;
	border-top-color: transparent;
	border-left-color: transparent
}

.ribbon-info.ribbon-modern.ribbon-left .ribbon-box::before {
	border-color: #26c6da;
	border-right-color: transparent;
	border-bottom-color: transparent
}

.ribbon-info.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
	border-color: #26c6da;
	border-top-color: transparent;
	border-right-color: transparent
}

.ribbon-warning .ribbon-box {
	background-color: #ffc107
}

.ribbon-warning.ribbon-bookmark .ribbon-box::before {
	border-color: #ffc107;
	border-left-color: transparent
}

.ribbon-warning.ribbon-bookmark.ribbon-left .ribbon-box::before {
	border-color: #ffc107;
	border-right-color: transparent
}

.ribbon-warning.ribbon-modern .ribbon-box::before {
	border-color: #ffca28;
	border-left-color: transparent;
	border-bottom-color: transparent
}

.ribbon-warning.ribbon-modern.ribbon-bottom .ribbon-box::before {
	border-color: #ffc107;
	border-top-color: transparent;
	border-left-color: transparent
}

.ribbon-warning.ribbon-modern.ribbon-left .ribbon-box::before {
	border-color: #ffc107;
	border-right-color: transparent;
	border-bottom-color: transparent
}

.ribbon-warning.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
	border-color: #ffc107;
	border-top-color: transparent;
	border-right-color: transparent
}

.ribbon-danger .ribbon-box {
	background-color: #ef5350
}

.ribbon-danger.ribbon-bookmark .ribbon-box::before {
	border-color: #ef5350;
	border-left-color: transparent
}

.ribbon-danger.ribbon-bookmark.ribbon-left .ribbon-box::before {
	border-color: #ef5350;
	border-right-color: transparent
}

.ribbon-danger.ribbon-modern .ribbon-box::before {
	border-color: #ef5350;
	border-left-color: transparent;
	border-bottom-color: transparent
}

.ribbon-danger.ribbon-modern.ribbon-bottom .ribbon-box::before {
	border-color: #ef5350;
	border-top-color: transparent;
	border-left-color: transparent
}

.ribbon-danger.ribbon-modern.ribbon-left .ribbon-box::before {
	border-color: #ef5350;
	border-right-color: transparent;
	border-bottom-color: transparent
}

.ribbon-danger.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
	border-color: #ef5350;
	border-top-color: transparent;
	border-right-color: transparent
}

.ribbon-crystal .ribbon-box {
	background-color: rgba(255, 255, 255, 0.35)
}

.ribbon-crystal.ribbon-bookmark .ribbon-box::before {
	border-color: rgba(255, 255, 255, 0.35);
	border-left-color: transparent
}

.ribbon-crystal.ribbon-bookmark.ribbon-left .ribbon-box::before {
	border-color: rgba(255, 255, 255, 0.35);
	border-right-color: transparent
}

.options-container {
	position: relative;
	overflow: hidden;
	z-index: 1;
	display: block
}

.options-container .options-item {
	transition: -webkit-transform .35s ease-out;
	transition: transform .35s ease-out;
	transition: transform .35s ease-out, -webkit-transform .35s ease-out;
	will-change: transform
}

.options-container .options-overlay {
	position: absolute;
	top: -2px;
	right: -2px;
	bottom: -2px;
	left: -2px;
	z-index: 2;
	content: "";
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease-in;
	will-change: opacity, transform
}

.options-container .options-overlay-content {
	position: absolute;
	top: 50%;
	right: auto;
	left: auto;
	-webkit-transform: translateX(0) translateY(-50%);
	transform: translateX(0) translateY(-50%);
	right: 0;
	left: 0;
	text-align: center
}

.options-container:hover .options-overlay {
	opacity: 1;
	visibility: visible
}

@media (max-width: 767.98px) {
	.options-container .options-overlay {
		display: none
	}
	.options-container:hover .options-overlay {
		display: block
	}
}

.fx-item-zoom-in:hover .options-item {
	-webkit-transform: scale(1.2, 1.2);
	transform: scale(1.2, 1.2)
}

.fx-item-rotate-r:hover .options-item {
	-webkit-transform: scale(1.4) rotate(8deg);
	transform: scale(1.4) rotate(8deg)
}

.fx-item-rotate-l:hover .options-item {
	-webkit-transform: scale(1.4) rotate(-8deg);
	transform: scale(1.4) rotate(-8deg)
}

.fx-overlay-slide-top .options-overlay {
	-webkit-transform: translateY(100%);
	transform: translateY(100%)
}

.fx-overlay-slide-top:hover .options-overlay {
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.fx-overlay-slide-right .options-overlay {
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%)
}

.fx-overlay-slide-right:hover .options-overlay {
	-webkit-transform: translateX(0);
	transform: translateX(0)
}

.fx-overlay-slide-down .options-overlay {
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%)
}

.fx-overlay-slide-down:hover .options-overlay {
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.fx-overlay-slide-left .options-overlay {
	-webkit-transform: translateX(100%);
	transform: translateX(100%)
}

.fx-overlay-slide-left:hover .options-overlay {
	-webkit-transform: translateX(0);
	transform: translateX(0)
}

.fx-overlay-zoom-in .options-overlay {
	-webkit-transform: scale(0, 0);
	transform: scale(0, 0)
}

.fx-overlay-zoom-in:hover .options-overlay {
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1)
}

.fx-overlay-zoom-out .options-overlay {
	-webkit-transform: scale(2, 2);
	transform: scale(2, 2)
}

.fx-overlay-zoom-out:hover .options-overlay {
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1)
}

.scrumboard {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	overflow-x: auto;
	opacity: 0;
	-webkit-overflow-scrolling: touch;
	padding: 10px 10px 1px
}

@media (min-width: 768px) {
	.scrumboard {
		padding: 24px 24px 1px
	}
}

.scrumboard .scrumboard-col {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: 320px;
	margin-right: 10px
}

@media (min-width: 768px) {
	.scrumboard .scrumboard-col {
		margin-right: 24px
	}
}

.scrumboard .scrumboard-item {
	position: relative;
	min-height: 42px;
	padding: 10px 87px 10px 10px;
	margin-bottom: 15px;
	font-weight: 600;
	color: #a87e00;
	background-color: #fcf7e6;
	border-bottom: 1px solid rgba(168, 126, 0, 0.1);
	box-shadow: 0 5px 8px rgba(168, 126, 0, 0.05)
}

.scrumboard .scrumboard-item-options {
	position: absolute;
	top: 7px;
	right: 7px
}

.scrumboard .scrumboard-item-handler {
	cursor: move
}

.scrumboard .scrumboard-item-placeholder {
	min-height: 42px;
	border: 1px dashed #ffca28
}

@media print {
	#page-container,
	#main-container {
		padding: 0!important
	}
	#page-header,
	#page-footer,
	#sidebar,
	#side-overlay,
	.block-options {
		display: none!important
	}
}

.bg-primary-op {
	background-color: rgba(66, 165, 245, 0.75)!important
}

a.bg-primary-op:hover,
a.bg-primary-op:focus,
button.bg-primary-op:hover,
button.bg-primary-op:focus {
	background-color: rgba(18, 142, 242, 0.75)!important
}

.bg-primary-dark {
	background-color: #343a40!important
}

a.bg-primary-dark:hover,
a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
	background-color: #1d2124!important
}

.bg-primary-dark-op {
	background-color: rgba(52, 58, 64, 0.8)!important
}

a.bg-primary-dark-op:hover,
a.bg-primary-dark-op:focus,
button.bg-primary-dark-op:hover,
button.bg-primary-dark-op:focus {
	background-color: rgba(29, 33, 36, 0.8)!important
}

.bg-primary-darker {
	background-color: #212529!important
}

a.bg-primary-darker:hover,
a.bg-primary-darker:focus,
button.bg-primary-darker:hover,
button.bg-primary-darker:focus {
	background-color: #0a0c0d!important
}

.bg-primary-light {
	background-color: #8bc8f9!important
}

a.bg-primary-light:hover,
a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
	background-color: #5ab1f6!important
}

.bg-primary-lighter {
	background-color: #d3eafd!important
}

a.bg-primary-lighter:hover,
a.bg-primary-lighter:focus,
button.bg-primary-lighter:hover,
button.bg-primary-lighter:focus {
	background-color: #a3d3fa!important
}

.bg-body {
	background-color: #f5f6f7!important
}

a.bg-body:hover,
a.bg-body:focus,
button.bg-body:hover,
button.bg-body:focus {
	background-color: #d9dde0!important
}

.bg-body-light {
	background-color: #fbfbfc!important
}

a.bg-body-light:hover,
a.bg-body-light:focus,
button.bg-body-light:hover,
button.bg-body-light:focus {
	background-color: #dee2e5!important
}

.bg-body-dark {
	background-color: #eaecee!important
}

a.bg-body-dark:hover,
a.bg-body-dark:focus,
button.bg-body-dark:hover,
button.bg-body-dark:focus {
	background-color: #cdd2d7!important
}

.bg-muted {
	background-color: #6c757d!important
}

a.bg-muted:hover,
a.bg-muted:focus,
button.bg-muted:hover,
button.bg-muted:focus {
	background-color: #545b62!important
}

.bg-success-light {
	background-color: #ebf5df!important
}

a.bg-success-light:hover,
a.bg-success-light:focus,
button.bg-success-light:hover,
button.bg-success-light:focus {
	background-color: #d3e9b8!important
}

.bg-warning-light {
	background-color: #fcf7e6!important
}

a.bg-warning-light:hover,
a.bg-warning-light:focus,
button.bg-warning-light:hover,
button.bg-warning-light:focus {
	background-color: #f7e8b8!important
}

.bg-info-light {
	background-color: #e3f4fc!important
}

a.bg-info-light:hover,
a.bg-info-light:focus,
button.bg-info-light:hover,
button.bg-info-light:focus {
	background-color: #b5e2f7!important
}

.bg-danger-light {
	background-color: #fae9e8!important
}

a.bg-danger-light:hover,
a.bg-danger-light:focus,
button.bg-danger-light:hover,
button.bg-danger-light:focus {
	background-color: #f1c1be!important
}

.bg-white {
	background-color: #fff!important
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
	background-color: #e6e6e6!important
}

.bg-black {
	background-color: #000!important
}

a.bg-black:hover,
a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
	background-color: #000!important
}

.bg-gray {
	background-color: #ced4da!important
}

a.bg-gray:hover,
a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
	background-color: #b1bbc4!important
}

.bg-gray-dark {
	background-color: #6c757d!important
}

a.bg-gray-dark:hover,
a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
	background-color: #545b62!important
}

.bg-gray-darker {
	background-color: #343a40!important
}

a.bg-gray-darker:hover,
a.bg-gray-darker:focus,
button.bg-gray-darker:hover,
button.bg-gray-darker:focus {
	background-color: #1d2124!important
}

.bg-gray-light {
	background-color: #e9ecef!important
}

a.bg-gray-light:hover,
a.bg-gray-light:focus,
button.bg-gray-light:hover,
button.bg-gray-light:focus {
	background-color: #cbd3da!important
}

.bg-gray-lighter {
	background-color: #f8f9fa!important
}

a.bg-gray-lighter:hover,
a.bg-gray-lighter:focus,
button.bg-gray-lighter:hover,
button.bg-gray-lighter:focus {
	background-color: #dae0e5!important
}

.bg-gd-primary {
	background: #0c80df;
	background: linear-gradient(135deg, #0c80df 0%, #5ab1f6 100%)!important
}

.bg-gd-dusk {
	background: #d262e3;
	background: linear-gradient(135deg, #d262e3 0%, #42a5f5 100%)!important
}

.bg-gd-cherry {
	background: #ea6153;
	background: linear-gradient(135deg, #ea6153 0%, #d62c1a 100%)!important
}

.bg-gd-aqua {
	background: #2facb2;
	background: linear-gradient(135deg, #2facb2 0%, #70b29c 100%)!important
}

.bg-gd-emerald {
	background: #6eab4d;
	background: linear-gradient(135deg, #6eab4d 0%, #2facb2 100%)!important
}

.bg-gd-sea {
	background: #2095f3;
	background: linear-gradient(135deg, #2095f3 0%, #26c6da 100%)!important
}

.bg-gd-leaf {
	background: #ffca28;
	background: linear-gradient(135deg, #ffca28 0%, #9ccc65 100%)!important
}

.bg-gd-lake {
	background: #26c6da;
	background: linear-gradient(135deg, #26c6da 0%, #9ccc65 100%)!important
}

.bg-gd-sun {
	background: #ef5350;
	background: linear-gradient(135deg, #ef5350 0%, #ffca28 100%)!important
}

.bg-black-op {
	background-color: rgba(0, 0, 0, 0.5)!important
}

.bg-black-op-5 {
	background-color: rgba(0, 0, 0, 0.05)!important
}

.bg-black-op-10 {
	background-color: rgba(0, 0, 0, 0.1)!important
}

.bg-black-op-25 {
	background-color: rgba(0, 0, 0, 0.25)!important
}

.bg-black-op-75 {
	background-color: rgba(0, 0, 0, 0.75)!important
}

.bg-black-op-90 {
	background-color: rgba(0, 0, 0, 0.9)!important
}

.bg-black-op-95 {
	background-color: rgba(0, 0, 0, 0.95)!important
}

.bg-white-op {
	background-color: rgba(255, 255, 255, 0.5)!important
}

.bg-white-op-5 {
	background-color: rgba(255, 255, 255, 0.05)!important
}

.bg-white-op-10 {
	background-color: rgba(255, 255, 255, 0.1)!important
}

.bg-white-op-25 {
	background-color: rgba(255, 255, 255, 0.25)!important
}

.bg-white-op-75 {
	background-color: rgba(255, 255, 255, 0.75)!important
}

.bg-white-op-90 {
	background-color: rgba(255, 255, 255, 0.9)!important
}

.bg-white-op-95 {
	background-color: rgba(255, 255, 255, 0.95)!important
}

.bg-image {
	background-color: #f8f9fa;
	background-position: 0 50%;
	background-size: cover
}

.bg-image-top {
	background-position-y: 0
}

.bg-image-bottom {
	background-position-y: 100%
}

@media (min-width: 1200px) {
	.bg-image-fixed {
		background-attachment: fixed
	}
}

.bg-pattern {
	background-repeat: repeat
}

.bg-video {
	width: 100%;
	-webkit-transform: translateZ(0);
	transform: translateZ(0)
}

.border {
	border: 1px solid #eaecee!important
}

.border-t {
	border-top: 1px solid #eaecee!important
}

.border-r {
	border-right: 1px solid #eaecee!important
}

.border-b {
	border-bottom: 1px solid #eaecee!important
}

.border-l {
	border-left: 1px solid #eaecee!important
}

.border-light {
	border: 1px solid #fbfbfc!important
}

.border-light-t {
	border-top: 1px solid #fbfbfc!important
}

.border-light-r {
	border-right: 1px solid #fbfbfc!important
}

.border-light-b {
	border-bottom: 1px solid #fbfbfc!important
}

.border-light-l {
	border-left: 1px solid #fbfbfc!important
}

.border-white-op {
	border: 1px solid rgba(255, 255, 255, 0.1)!important
}

.border-white-op-t {
	border-top: 1px solid rgba(255, 255, 255, 0.1)!important
}

.border-white-op-r {
	border-right: 1px solid rgba(255, 255, 255, 0.1)!important
}

.border-white-op-b {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1)!important
}

.border-white-op-l {
	border-left: 1px solid rgba(255, 255, 255, 0.1)!important
}

.border-black-op {
	border: 1px solid rgba(0, 0, 0, 0.1)!important
}

.border-black-op-t {
	border-top: 1px solid rgba(0, 0, 0, 0.1)!important
}

.border-black-op-r {
	border-right: 1px solid rgba(0, 0, 0, 0.1)!important
}

.border-black-op-b {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1)!important
}

.border-black-op-l {
	border-left: 1px solid rgba(0, 0, 0, 0.1)!important
}

.border-2x {
	border-width: 2px!important
}

.border-3x {
	border-width: 3px!important
}

.overflow-hidden {
	overflow: hidden
}

.overflow-y-auto {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch
}

.opacity-0 {
	opacity: 0
}

.opacity-1 {
	opacity: 1
}

.click-ripple {
	display: block;
	position: absolute;
	background: rgba(255, 255, 255, 0.5);
	border-radius: 100%;
	-webkit-transform: scale(0);
	transform: scale(0)
}

.click-ripple.animate {
	-webkit-animation: click-ripple .9s ease-out;
	animation: click-ripple .9s ease-out
}

@-webkit-keyframes click-ripple {
	100% {
		opacity: 0;
		-webkit-transform: scale(3);
		transform: scale(3)
	}
}

@keyframes click-ripple {
	100% {
		opacity: 0;
		-webkit-transform: scale(3);
		transform: scale(3)
	}
}

.visible-lt-ie10 {
	display: none
}

.lt-ie10 .hidden-lt-ie10 {
	display: none!important
}

.lt-ie10 .visible-lt-ie10 {
	display: block
}

.lt-ie10 .visible-lt-ie10-ib {
	display: inline-block
}

.lt-ie10.lt-ie10-msg body {
	background-color: #fff
}

.lt-ie10.lt-ie10-msg::before {
	position: fixed;
	top: 100px;
	right: 0;
	left: 0;
	display: block;
	padding: 30px 20px;
	text-align: center;
	font-size: 1.142857rem;
	font-weight: 600;
	background: #ffca28;
	color: #755800;
	content: "Internet Explorer 9 and older versions are not supported. Please update your browser.";
	z-index: 9999999
}

.lt-ie10.lt-ie10-msg #page-container {
	display: none
}

.min-width-20 {
	min-width: 20px!important
}

.min-width-30 {
	min-width: 30px!important
}

.min-width-50 {
	min-width: 50px!important
}

.min-width-75 {
	min-width: 75px!important
}

.min-width-100 {
	min-width: 100px!important
}

.min-width-125 {
	min-width: 125px!important
}

.min-width-150 {
	min-width: 150px!important
}

.min-width-175 {
	min-width: 175px!important
}

.min-width-200 {
	min-width: 200px!important
}

.min-width-300 {
	min-width: 300px!important
}

.min-width-320 {
	min-width: 320px!important
}

.min-height-20 {
	min-height: 20px!important
}

.min-height-30 {
	min-height: 30px!important
}

.min-height-50 {
	min-height: 50px!important
}

.min-height-75 {
	min-height: 75px!important
}

.min-height-100 {
	min-height: 100px!important
}

.min-height-125 {
	min-height: 125px!important
}

.min-height-150 {
	min-height: 150px!important
}

.min-height-175 {
	min-height: 175px!important
}

.min-height-200 {
	min-height: 200px!important
}

.min-height-300 {
	min-height: 300px!important
}

.min-height-400 {
	min-height: 400px!important
}

.min-height-500 {
	min-height: 500px!important
}

.m-5 {
	margin: 5px!important
}

.mt-5,
.my-5 {
	margin-top: 5px!important
}

.mr-5,
.mx-5 {
	margin-right: 5px!important
}

.mb-5,
.my-5 {
	margin-bottom: 5px!important
}

.ml-5,
.mx-5 {
	margin-left: 5px!important
}

.m-10 {
	margin: 10px!important
}

.mt-10,
.my-10 {
	margin-top: 10px!important
}

.mr-10,
.mx-10 {
	margin-right: 10px!important
}

.mb-10,
.my-10 {
	margin-bottom: 10px!important
}

.ml-10,
.mx-10 {
	margin-left: 10px!important
}

.m-15 {
	margin: 15px!important
}

.mt-15,
.my-15 {
	margin-top: 15px!important
}

.mr-15,
.mx-15 {
	margin-right: 15px!important
}

.mb-15,
.my-15 {
	margin-bottom: 15px!important
}

.ml-15,
.mx-15 {
	margin-left: 15px!important
}

.m-20 {
	margin: 20px!important
}

.mt-20,
.my-20 {
	margin-top: 20px!important
}

.mr-20,
.mx-20 {
	margin-right: 20px!important
}

.mb-20,
.my-20 {
	margin-bottom: 20px!important
}

.ml-20,
.mx-20 {
	margin-left: 20px!important
}

.m-30 {
	margin: 30px!important
}

.mt-30,
.my-30 {
	margin-top: 30px!important
}

.mr-30,
.mx-30 {
	margin-right: 30px!important
}

.mb-30,
.my-30 {
	margin-bottom: 30px!important
}

.ml-30,
.mx-30 {
	margin-left: 30px!important
}

.m-50 {
	margin: 50px!important
}

.mt-50,
.my-50 {
	margin-top: 50px!important
}

.mr-50,
.mx-50 {
	margin-right: 50px!important
}

.mb-50,
.my-50 {
	margin-bottom: 50px!important
}

.ml-50,
.mx-50 {
	margin-left: 50px!important
}

.m-100 {
	margin: 100px!important
}

.mt-100,
.my-100 {
	margin-top: 100px!important
}

.mr-100,
.mx-100 {
	margin-right: 100px!important
}

.mb-100,
.my-100 {
	margin-bottom: 100px!important
}

.ml-100,
.mx-100 {
	margin-left: 100px!important
}

.m-150 {
	margin: 150px!important
}

.mt-150,
.my-150 {
	margin-top: 150px!important
}

.mr-150,
.mx-150 {
	margin-right: 150px!important
}

.mb-150,
.my-150 {
	margin-bottom: 150px!important
}

.ml-150,
.mx-150 {
	margin-left: 150px!important
}

.m-200 {
	margin: 200px!important
}

.mt-200,
.my-200 {
	margin-top: 200px!important
}

.mr-200,
.mx-200 {
	margin-right: 200px!important
}

.mb-200,
.my-200 {
	margin-bottom: 200px!important
}

.ml-200,
.mx-200 {
	margin-left: 200px!important
}

.m-300 {
	margin: 300px!important
}

.mt-300,
.my-300 {
	margin-top: 300px!important
}

.mr-300,
.mx-300 {
	margin-right: 300px!important
}

.mb-300,
.my-300 {
	margin-bottom: 300px!important
}

.ml-300,
.mx-300 {
	margin-left: 300px!important
}

.p-5 {
	padding: 5px!important
}

.pt-5,
.py-5 {
	padding-top: 5px!important
}

.pr-5,
.px-5 {
	padding-right: 5px!important
}

.pb-5,
.py-5 {
	padding-bottom: 5px!important
}

.pl-5,
.px-5 {
	padding-left: 5px!important
}

.p-10 {
	padding: 10px!important
}

.pt-10,
.py-10 {
	padding-top: 10px!important
}

.pr-10,
.px-10 {
	padding-right: 10px!important
}

.pb-10,
.py-10 {
	padding-bottom: 10px!important
}

.pl-10,
.px-10 {
	padding-left: 10px!important
}

.p-15 {
	padding: 15px!important
}

.pt-15,
.py-15 {
	padding-top: 15px!important
}

.pr-15,
.px-15 {
	padding-right: 15px!important
}

.pb-15,
.py-15 {
	padding-bottom: 15px!important
}

.pl-15,
.px-15 {
	padding-left: 15px!important
}

.p-20 {
	padding: 20px!important
}

.pt-20,
.py-20 {
	padding-top: 20px!important
}

.pr-20,
.px-20 {
	padding-right: 20px!important
}

.pb-20,
.py-20 {
	padding-bottom: 20px!important
}

.pl-20,
.px-20 {
	padding-left: 20px!important
}

.p-30 {
	padding: 30px!important
}

.pt-30,
.py-30 {
	padding-top: 30px!important
}

.pr-30,
.px-30 {
	padding-right: 30px!important
}

.pb-30,
.py-30 {
	padding-bottom: 30px!important
}

.pl-30,
.px-30 {
	padding-left: 30px!important
}

.p-50 {
	padding: 50px!important
}

.pt-50,
.py-50 {
	padding-top: 50px!important
}

.pt-80,
.py-80 {
	padding-top: 80px!important
}

.pr-50,
.px-50 {
	padding-right: 50px!important
}

.pb-50,
.py-50 {
	padding-bottom: 50px!important
}

.pb-56 {
	padding-bottom: 56px!important
}

.pb-80,
.py-80 {
	padding-bottom: 80px!important
}

.pl-50,
.px-50 {
	padding-left: 50px!important
}

.p-100 {
	padding: 100px!important
}

.pt-100,
.py-100 {
	padding-top: 100px!important
}

.pr-100,
.px-100 {
	padding-right: 100px!important
}

.pb-100,
.py-100 {
	padding-bottom: 100px!important
}

.pl-100,
.px-100 {
	padding-left: 100px!important
}

.p-150 {
	padding: 150px!important
}

.pt-150,
.py-150 {
	padding-top: 150px!important
}

.pr-150,
.px-150 {
	padding-right: 150px!important
}

.pb-150,
.py-150 {
	padding-bottom: 150px!important
}

.pl-150,
.px-150 {
	padding-left: 150px!important
}

.p-200 {
	padding: 200px!important
}

.pt-200,
.py-200 {
	padding-top: 200px!important
}

.pr-200,
.px-200 {
	padding-right: 200px!important
}

.pb-200,
.py-200 {
	padding-bottom: 200px!important
}

.pl-200,
.px-200 {
	padding-left: 200px!important
}

.p-300 {
	padding: 300px!important
}

.pt-300,
.py-300 {
	padding-top: 300px!important
}

.pr-300,
.px-300 {
	padding-right: 300px!important
}

.pb-300,
.py-300 {
	padding-bottom: 300px!important
}

.pl-300,
.px-300 {
	padding-left: 300px!important
}

.push,
.items-push>div {
	margin-bottom: 24px
}

.font-w300 {
	font-weight: 300!important
}

.font-w400 {
	font-weight: 400!important
}

.font-w600 {
	font-weight: 600!important
}

.font-w700 {
	font-weight: 700!important
}

.font-size-default {
	font-size: 1rem!important
}

.font-size-xl {
	font-size: 1.428571rem!important
}

.font-size-lg {
	font-size: 1.285714rem!important
}

.font-size-md {
	font-size: 1.142857rem!important
}

.font-size-sm {
	font-size: .928571rem!important
}

.font-size-xs {
	font-size: .857143rem!important
}

.line-height-base {
	line-height: 1.5!important
}

.text-wrap-break-word {
	word-wrap: break-word
}

.text-primary-dark {
	color: #343a40!important
}

a.text-primary-dark.link-effect::before {
	background-color: #343a40
}

a.text-primary-dark:hover,
a.text-primary-dark:focus {
	color: #060708!important
}

.text-primary-darker {
	color: #212529!important
}

a.text-primary-darker.link-effect::before {
	background-color: #212529
}

a.text-primary-darker:hover,
a.text-primary-darker:focus {
	color: #000!important
}

.text-primary-light {
	color: #8bc8f9!important
}

a.text-primary-light.link-effect::before {
	background-color: #8bc8f9
}

a.text-primary-light:hover,
a.text-primary-light:focus {
	color: #2a99f4!important
}

.text-primary-lighter {
	color: #d3eafd!important
}

a.text-primary-lighter.link-effect::before {
	background-color: #d3eafd
}

a.text-primary-lighter:hover,
a.text-primary-lighter:focus {
	color: #72bcf8!important
}

.text-body-bg {
	color: #f5f6f7!important
}

a.text-body-bg.link-effect::before {
	background-color: #f5f6f7
}

a.text-body-bg:hover,
a.text-body-bg:focus {
	color: #bcc3ca!important
}

.text-body-bg-dark {
	color: #eaecee!important
}

a.text-body-bg-dark.link-effect::before {
	background-color: #eaecee
}

a.text-body-bg-dark:hover,
a.text-body-bg-dark:focus {
	color: #b1b9c1!important
}

.text-body-bg-light {
	color: #fbfbfc!important
}

a.text-body-bg-light.link-effect::before {
	background-color: #fbfbfc
}

a.text-body-bg-light:hover,
a.text-body-bg-light:focus {
	color: #c2c8ce!important
}

.text-body-color {
	color: #575757!important
}

a.text-body-color.link-effect::before {
	background-color: #575757
}

a.text-body-color:hover,
a.text-body-color:focus {
	color: #242424!important
}

.text-body-color-dark {
	color: #171717!important
}

a.text-body-color-dark.link-effect::before {
	background-color: #171717
}

a.text-body-color-dark:hover,
a.text-body-color-dark:focus {
	color: #000!important
}

.text-body-color-light {
	color: #eaecee!important
}

a.text-body-color-light.link-effect::before {
	background-color: #eaecee
}

a.text-body-color-light:hover,
a.text-body-color-light:focus {
	color: #b1b9c1!important
}

.text-muted {
	color: #6c757d!important
}

a.text-muted.link-effect::before {
	background-color: #6c757d
}

a.text-muted:hover,
a.text-muted:focus {
	color: #3d4246!important
}

.text-success-light {
	color: #ebf5df!important
}

a.text-success-light.link-effect::before {
	background-color: #ebf5df
}

a.text-success-light:hover,
a.text-success-light:focus {
	color: #badd91!important
}

.text-warning-light {
	color: #fcf7e6!important
}

a.text-warning-light.link-effect::before {
	background-color: #fcf7e6
}

a.text-warning-light:hover,
a.text-warning-light:focus {
	color: #f1da8b!important
}

.text-info-light {
	color: #e3f4fc!important
}

a.text-info-light.link-effect::before {
	background-color: #e3f4fc
}

a.text-info-light:hover,
a.text-info-light:focus {
	color: #87d0f2!important
}

.text-danger-light {
	color: #fae9e8!important
}

a.text-danger-light.link-effect::before {
	background-color: #fae9e8
}

a.text-danger-light:hover,
a.text-danger-light:focus {
	color: #e89994!important
}

.text-white {
	color: #fff!important
}

a.text-white.link-effect::before {
	background-color: #fff
}

a.text-white:hover,
a.text-white:focus {
	color: #fff
}

.text-white-op {
	color: rgba(255, 255, 255, 0.8)!important
}

a.text-white-op.link-effect::before {
	background-color: rgba(255, 255, 255, 0.8)
}

a.text-white-op:hover,
a.text-white-op:focus {
	color: rgba(204, 204, 204, 0.8)!important
}

.text-black {
	color: #000!important
}

a.text-black.link-effect::before {
	background-color: #000
}

a.text-black:hover,
a.text-black:focus {
	color: #000!important
}

.text-black-op {
	color: rgba(0, 0, 0, 0.5)!important
}

a.text-black-op.link-effect::before {
	background-color: rgba(0, 0, 0, 0.5)
}

a.text-black-op:hover,
a.text-black-op:focus {
	color: rgba(0, 0, 0, 0.5)!important
}

.text-gray {
	color: #ced4da!important
}

a.text-gray.link-effect::before {
	background-color: #ced4da
}

a.text-gray:hover,
a.text-gray:focus {
	color: #94a1ae!important
}

.text-gray-dark {
	color: #6c757d!important
}

a.text-gray-dark.link-effect::before {
	background-color: #6c757d
}

a.text-gray-dark:hover,
a.text-gray-dark:focus {
	color: #3d4246!important
}

.text-gray-darker {
	color: #343a40!important
}

a.text-gray-darker.link-effect::before {
	background-color: #343a40
}

a.text-gray-darker:hover,
a.text-gray-darker:focus {
	color: #060708!important
}

.text-gray-light {
	color: #e9ecef!important
}

a.text-gray-light.link-effect::before {
	background-color: #e9ecef
}

a.text-gray-light:hover,
a.text-gray-light:focus {
	color: #aeb9c4!important
}

.text-gray-lighter {
	color: #f8f9fa!important
}

a.text-gray-lighter.link-effect::before {
	background-color: #f8f9fa
}

a.text-gray-lighter:hover,
a.text-gray-lighter:focus {
	color: #bdc6d0!important
}

.text-dual-primary-dark {
	color: #343a40!important
}

a.text-dual-primary-dark.link-effect::before {
	background-color: #343a40
}

a.text-dual-primary-dark:hover,
a.text-dual-primary-dark:focus {
	color: #060708!important
}

.page-header-inverse #page-header .text-dual-primary-dark,
.sidebar-inverse #sidebar .text-dual-primary-dark {
	color: #eaecee!important
}

.page-header-inverse #page-header a.text-dual-primary-dark.link-effect::before,
.sidebar-inverse #sidebar a.text-dual-primary-dark.link-effect::before {
	background-color: #eaecee
}

.page-header-inverse #page-header a.text-dual-primary-dark:hover,
.page-header-inverse #page-header a.text-dual-primary-dark:focus,
.sidebar-inverse #sidebar a.text-dual-primary-dark:hover,
.sidebar-inverse #sidebar a.text-dual-primary-dark:focus {
	color: #b1b9c1!important
}

.align-parent {
	position: relative
}

.align-v {
	position: absolute;
	top: 50%;
	right: auto;
	left: auto;
	-webkit-transform: translateX(0) translateY(-50%);
	transform: translateX(0) translateY(-50%)
}

.align-v-r {
	position: absolute;
	top: 50%;
	right: 0;
	left: auto;
	-webkit-transform: translateX(0) translateY(-50%);
	transform: translateX(0) translateY(-50%)
}

.align-v-l {
	position: absolute;
	top: 50%;
	right: auto;
	left: 0;
	-webkit-transform: translateX(0) translateY(-50%);
	transform: translateX(0) translateY(-50%)
}

.align-v-r {
	position: absolute;
	top: 50%;
	right: 0;
	left: auto;
	-webkit-transform: translateX(0) translateY(-50%);
	transform: translateX(0) translateY(-50%)
}

.align-v-h {
	position: absolute;
	top: 50%;
	right: auto;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%)
}

.text-default {
	color: #42a5f5!important
}

a.text-default.link-effect::before {
	background-color: #42a5f5
}

a.text-default:hover,
a.text-default:focus {
	color: #0b72c6!important
}

.text-default-dark {
	color: #343a40!important
}

a.text-default-dark.link-effect::before {
	background-color: #343a40
}

a.text-default-dark:hover,
a.text-default-dark:focus {
	color: #060708!important
}

.text-default-darker {
	color: #212529!important
}

a.text-default-darker.link-effect::before {
	background-color: #212529
}

a.text-default-darker:hover,
a.text-default-darker:focus {
	color: #000!important
}

.text-default-light {
	color: #8bc8f9!important
}

a.text-default-light.link-effect::before {
	background-color: #8bc8f9
}

a.text-default-light:hover,
a.text-default-light:focus {
	color: #2a99f4!important
}

.text-default-lighter {
	color: #d3eafd!important
}

a.text-default-lighter.link-effect::before {
	background-color: #d3eafd
}

a.text-default-lighter:hover,
a.text-default-lighter:focus {
	color: #72bcf8!important
}

.bg-default {
	background-color: #42a5f5!important
}

a.bg-default:hover,
a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
	background-color: #128ef2!important
}

.bg-default-op {
	background-color: rgba(66, 165, 245, 0.75)!important
}

a.bg-default-op:hover,
a.bg-default-op:focus,
button.bg-default-op:hover,
button.bg-default-op:focus {
	background-color: rgba(18, 142, 242, 0.75)!important
}

.bg-default-dark {
	background-color: #343a40!important
}

a.bg-default-dark:hover,
a.bg-default-dark:focus,
button.bg-default-dark:hover,
button.bg-default-dark:focus {
	background-color: #1d2124!important
}

.bg-default-dark-op {
	background-color: rgba(52, 58, 64, 0.83)!important
}

a.bg-default-dark-op:hover,
a.bg-default-dark-op:focus,
button.bg-default-dark-op:hover,
button.bg-default-dark-op:focus {
	background-color: rgba(29, 33, 36, 0.83)!important
}

.bg-default-darker {
	background-color: #212529!important
}

a.bg-default-darker:hover,
a.bg-default-darker:focus,
button.bg-default-darker:hover,
button.bg-default-darker:focus {
	background-color: #0a0c0d!important
}

.bg-default-light {
	background-color: #8bc8f9!important
}

a.bg-default-light:hover,
a.bg-default-light:focus,
button.bg-default-light:hover,
button.bg-default-light:focus {
	background-color: #5ab1f6!important
}

.bg-default-lighter {
	background-color: #d3eafd!important
}

a.bg-default-lighter:hover,
a.bg-default-lighter:focus,
button.bg-default-lighter:hover,
button.bg-default-lighter:focus {
	background-color: #a3d3fa!important
}

.bg-gd-default {
	background: #0c80df;
	background: linear-gradient(135deg, #0c80df 0%, #5ab1f6 100%)!important
}

.text-elegance {
	color: #d262e3!important
}

a.text-elegance.link-effect::before {
	background-color: #d262e3
}

a.text-elegance:hover,
a.text-elegance:focus {
	color: #a922bd!important
}

.text-elegance-dark {
	color: #403537!important
}

a.text-elegance-dark.link-effect::before {
	background-color: #403537
}

a.text-elegance-dark:hover,
a.text-elegance-dark:focus {
	color: #080707!important
}

.text-elegance-darker {
	color: #332a2c!important
}

a.text-elegance-darker.link-effect::before {
	background-color: #332a2c
}

a.text-elegance-darker:hover,
a.text-elegance-darker:focus {
	color: #000!important
}

.text-elegance-light {
	color: #e196ec!important
}

a.text-elegance-light.link-effect::before {
	background-color: #e196ec
}

a.text-elegance-light:hover,
a.text-elegance-light:focus {
	color: #c83fdd!important
}

.text-elegance-lighter {
	color: #edc1f4!important
}

a.text-elegance-lighter.link-effect::before {
	background-color: #edc1f4
}

a.text-elegance-lighter:hover,
a.text-elegance-lighter:focus {
	color: #d46be5!important
}

.bg-elegance {
	background-color: #d262e3!important
}

a.bg-elegance:hover,
a.bg-elegance:focus,
button.bg-elegance:hover,
button.bg-elegance:focus {
	background-color: #c637db!important
}

.bg-elegance-op {
	background-color: rgba(210, 98, 227, 0.75)!important
}

a.bg-elegance-op:hover,
a.bg-elegance-op:focus,
button.bg-elegance-op:hover,
button.bg-elegance-op:focus {
	background-color: rgba(198, 55, 219, 0.75)!important
}

.bg-elegance-dark {
	background-color: #403537!important
}

a.bg-elegance-dark:hover,
a.bg-elegance-dark:focus,
button.bg-elegance-dark:hover,
button.bg-elegance-dark:focus {
	background-color: #241e1f!important
}

.bg-elegance-dark-op {
	background-color: rgba(64, 53, 55, 0.83)!important
}

a.bg-elegance-dark-op:hover,
a.bg-elegance-dark-op:focus,
button.bg-elegance-dark-op:hover,
button.bg-elegance-dark-op:focus {
	background-color: rgba(36, 30, 31, 0.83)!important
}

.bg-elegance-darker {
	background-color: #332a2c!important
}

a.bg-elegance-darker:hover,
a.bg-elegance-darker:focus,
button.bg-elegance-darker:hover,
button.bg-elegance-darker:focus {
	background-color: #171314!important
}

.bg-elegance-light {
	background-color: #e196ec!important
}

a.bg-elegance-light:hover,
a.bg-elegance-light:focus,
button.bg-elegance-light:hover,
button.bg-elegance-light:focus {
	background-color: #d46be5!important
}

.bg-elegance-lighter {
	background-color: #edc1f4!important
}

a.bg-elegance-lighter:hover,
a.bg-elegance-lighter:focus,
button.bg-elegance-lighter:hover,
button.bg-elegance-lighter:focus {
	background-color: #e196ec!important
}

.bg-gd-elegance {
	background: #bc26d3;
	background: linear-gradient(135deg, #bc26d3 0%, #d878e7 100%)!important
}

.text-pulse {
	color: #e74c3c!important
}

a.text-pulse.link-effect::before {
	background-color: #e74c3c
}

a.text-pulse:hover,
a.text-pulse:focus {
	color: #a82315!important
}

.text-pulse-dark {
	color: #383838!important
}

a.text-pulse-dark.link-effect::before {
	background-color: #383838
}

a.text-pulse-dark:hover,
a.text-pulse-dark:focus {
	color: #050505!important
}

.text-pulse-darker {
	color: #232323!important
}

a.text-pulse-darker.link-effect::before {
	background-color: #232323
}

a.text-pulse-darker:hover,
a.text-pulse-darker:focus {
	color: #000!important
}

.text-pulse-light {
	color: #eb6d60!important
}

a.text-pulse-light.link-effect::before {
	background-color: #eb6d60
}

a.text-pulse-light:hover,
a.text-pulse-light:focus {
	color: #cd2a19!important
}

.text-pulse-lighter {
	color: #f3a8a0!important
}

a.text-pulse-lighter.link-effect::before {
	background-color: #f3a8a0
}

a.text-pulse-lighter:hover,
a.text-pulse-lighter:focus {
	color: #e85445!important
}

.bg-pulse {
	background-color: #e74c3c!important
}

a.bg-pulse:hover,
a.bg-pulse:focus,
button.bg-pulse:hover,
button.bg-pulse:focus {
	background-color: #d62c1a!important
}

.bg-pulse-op {
	background-color: rgba(231, 76, 60, 0.75)!important
}

a.bg-pulse-op:hover,
a.bg-pulse-op:focus,
button.bg-pulse-op:hover,
button.bg-pulse-op:focus {
	background-color: rgba(214, 44, 26, 0.75)!important
}

.bg-pulse-dark {
	background-color: #383838!important
}

a.bg-pulse-dark:hover,
a.bg-pulse-dark:focus,
button.bg-pulse-dark:hover,
button.bg-pulse-dark:focus {
	background-color: #1f1f1f!important
}

.bg-pulse-dark-op {
	background-color: rgba(56, 56, 56, 0.83)!important
}

a.bg-pulse-dark-op:hover,
a.bg-pulse-dark-op:focus,
button.bg-pulse-dark-op:hover,
button.bg-pulse-dark-op:focus {
	background-color: rgba(31, 31, 31, 0.83)!important
}

.bg-pulse-darker {
	background-color: #232323!important
}

a.bg-pulse-darker:hover,
a.bg-pulse-darker:focus,
button.bg-pulse-darker:hover,
button.bg-pulse-darker:focus {
	background-color: #0a0a0a!important
}

.bg-pulse-light {
	background-color: #eb6d60!important
}

a.bg-pulse-light:hover,
a.bg-pulse-light:focus,
button.bg-pulse-light:hover,
button.bg-pulse-light:focus {
	background-color: #e64433!important
}

.bg-pulse-lighter {
	background-color: #f3a8a0!important
}

a.bg-pulse-lighter:hover,
a.bg-pulse-lighter:focus,
button.bg-pulse-lighter:hover,
button.bg-pulse-lighter:focus {
	background-color: #ee7e72!important
}

.bg-gd-pulse {
	background: #bf2718;
	background: linear-gradient(135deg, #bf2718 0%, #ea6153 100%)!important
}

.text-flat {
	color: #70b29c!important
}

a.text-flat.link-effect::before {
	background-color: #70b29c
}

a.text-flat:hover,
a.text-flat:focus {
	color: #427a67!important
}

.text-flat-dark {
	color: #113f4c!important
}

a.text-flat-dark.link-effect::before {
	background-color: #113f4c
}

a.text-flat-dark:hover,
a.text-flat-dark:focus {
	color: #000!important
}

.text-flat-darker {
	color: #0b2830!important
}

a.text-flat-darker.link-effect::before {
	background-color: #0b2830
}

a.text-flat-darker:hover,
a.text-flat-darker:focus {
	color: #000!important
}

.text-flat-light {
	color: #acd2c5!important
}

a.text-flat-light.link-effect::before {
	background-color: #acd2c5
}

a.text-flat-light:hover,
a.text-flat-light:focus {
	color: #69ae97!important
}

.text-flat-lighter {
	color: #cde4dc!important
}

a.text-flat-lighter.link-effect::before {
	background-color: #cde4dc
}

a.text-flat-lighter:hover,
a.text-flat-lighter:focus {
	color: #8bc0ae!important
}

.bg-flat {
	background-color: #70b29c!important
}

a.bg-flat:hover,
a.bg-flat:focus,
button.bg-flat:hover,
button.bg-flat:focus {
	background-color: #549b83!important
}

.bg-flat-op {
	background-color: rgba(112, 178, 156, 0.75)!important
}

a.bg-flat-op:hover,
a.bg-flat-op:focus,
button.bg-flat-op:hover,
button.bg-flat-op:focus {
	background-color: rgba(84, 155, 131, 0.75)!important
}

.bg-flat-dark {
	background-color: #113f4c!important
}

a.bg-flat-dark:hover,
a.bg-flat-dark:focus,
button.bg-flat-dark:hover,
button.bg-flat-dark:focus {
	background-color: #081c22!important
}

.bg-flat-dark-op {
	background-color: rgba(17, 63, 76, 0.83)!important
}

a.bg-flat-dark-op:hover,
a.bg-flat-dark-op:focus,
button.bg-flat-dark-op:hover,
button.bg-flat-dark-op:focus {
	background-color: rgba(8, 28, 34, 0.83)!important
}

.bg-flat-darker {
	background-color: #0b2830!important
}

a.bg-flat-darker:hover,
a.bg-flat-darker:focus,
button.bg-flat-darker:hover,
button.bg-flat-darker:focus {
	background-color: #010507!important
}

.bg-flat-light {
	background-color: #acd2c5!important
}

a.bg-flat-light:hover,
a.bg-flat-light:focus,
button.bg-flat-light:hover,
button.bg-flat-light:focus {
	background-color: #8bc0ae!important
}

.bg-flat-lighter {
	background-color: #cde4dc!important
}

a.bg-flat-lighter:hover,
a.bg-flat-lighter:focus,
button.bg-flat-lighter:hover,
button.bg-flat-lighter:focus {
	background-color: #acd2c5!important
}

.bg-gd-flat {
	background: #4b8b75;
	background: linear-gradient(135deg, #4b8b75 0%, #81bba7 100%)!important
}

.text-corporate {
	color: #2facb2!important
}

a.text-corporate.link-effect::before {
	background-color: #2facb2
}

a.text-corporate:hover,
a.text-corporate:focus {
	color: #1a5e61!important
}

.text-corporate-dark {
	color: #323d4c!important
}

a.text-corporate-dark.link-effect::before {
	background-color: #323d4c
}

a.text-corporate-dark:hover,
a.text-corporate-dark:focus {
	color: #0a0c0e!important
}

.text-corporate-darker {
	color: #252d38!important
}

a.text-corporate-darker.link-effect::before {
	background-color: #252d38
}

a.text-corporate-darker:hover,
a.text-corporate-darker:focus {
	color: #000!important
}

.text-corporate-light {
	color: #5aced3!important
}

a.text-corporate-light.link-effect::before {
	background-color: #5aced3
}

a.text-corporate-light:hover,
a.text-corporate-light:focus {
	color: #2a999e!important
}

.text-corporate-lighter {
	color: #d3f2f3!important
}

a.text-corporate-lighter.link-effect::before {
	background-color: #d3f2f3
}

a.text-corporate-lighter:hover,
a.text-corporate-lighter:focus {
	color: #82dade!important
}

.bg-corporate {
	background-color: #2facb2!important
}

a.bg-corporate:hover,
a.bg-corporate:focus,
button.bg-corporate:hover,
button.bg-corporate:focus {
	background-color: #24858a!important
}

.bg-corporate-op {
	background-color: rgba(47, 172, 178, 0.75)!important
}

a.bg-corporate-op:hover,
a.bg-corporate-op:focus,
button.bg-corporate-op:hover,
button.bg-corporate-op:focus {
	background-color: rgba(36, 133, 138, 0.75)!important
}

.bg-corporate-dark {
	background-color: #323d4c!important
}

a.bg-corporate-dark:hover,
a.bg-corporate-dark:focus,
button.bg-corporate-dark:hover,
button.bg-corporate-dark:focus {
	background-color: #1e242d!important
}

.bg-corporate-dark-op {
	background-color: rgba(50, 61, 76, 0.83)!important
}

a.bg-corporate-dark-op:hover,
a.bg-corporate-dark-op:focus,
button.bg-corporate-dark-op:hover,
button.bg-corporate-dark-op:focus {
	background-color: rgba(30, 36, 45, 0.83)!important
}

.bg-corporate-darker {
	background-color: #252d38!important
}

a.bg-corporate-darker:hover,
a.bg-corporate-darker:focus,
button.bg-corporate-darker:hover,
button.bg-corporate-darker:focus {
	background-color: #111419!important
}

.bg-corporate-light {
	background-color: #5aced3!important
}

a.bg-corporate-light:hover,
a.bg-corporate-light:focus,
button.bg-corporate-light:hover,
button.bg-corporate-light:focus {
	background-color: #34bfc6!important
}

.bg-corporate-lighter {
	background-color: #d3f2f3!important
}

a.bg-corporate-lighter:hover,
a.bg-corporate-lighter:focus,
button.bg-corporate-lighter:hover,
button.bg-corporate-lighter:focus {
	background-color: #abe6e9!important
}

.bg-gd-corporate {
	background: #1f7275;
	background: linear-gradient(135deg, #1f7275 0%, #34bfc6 100%)!important
}

.text-earth {
	color: #6eab4d!important
}

a.text-earth.link-effect::before {
	background-color: #6eab4d
}

a.text-earth:hover,
a.text-earth:focus {
	color: #41652d!important
}

.text-earth-dark {
	color: #424242!important
}

a.text-earth-dark.link-effect::before {
	background-color: #424242
}

a.text-earth-dark:hover,
a.text-earth-dark:focus {
	color: #0f0f0f!important
}

.text-earth-darker {
	color: #222!important
}

a.text-earth-darker.link-effect::before {
	background-color: #222
}

a.text-earth-darker:hover,
a.text-earth-darker:focus {
	color: #000!important
}

.text-earth-light {
	color: #a0ca8a!important
}

a.text-earth-light.link-effect::before {
	background-color: #a0ca8a
}

a.text-earth-light:hover,
a.text-earth-light:focus {
	color: #69a44a!important
}

.text-earth-lighter {
	color: #e4f0de!important
}

a.text-earth-lighter.link-effect::before {
	background-color: #e4f0de
}

a.text-earth-lighter:hover,
a.text-earth-lighter:focus {
	color: #acd098!important
}

.bg-earth {
	background-color: #6eab4d!important
}

a.bg-earth:hover,
a.bg-earth:focus,
button.bg-earth:hover,
button.bg-earth:focus {
	background-color: #57883d!important
}

.bg-earth-op {
	background-color: rgba(110, 171, 77, 0.75)!important
}

a.bg-earth-op:hover,
a.bg-earth-op:focus,
button.bg-earth-op:hover,
button.bg-earth-op:focus {
	background-color: rgba(87, 136, 61, 0.75)!important
}

.bg-earth-dark {
	background-color: #424242!important
}

a.bg-earth-dark:hover,
a.bg-earth-dark:focus,
button.bg-earth-dark:hover,
button.bg-earth-dark:focus {
	background-color: #292929!important
}

.bg-earth-dark-op {
	background-color: rgba(66, 66, 66, 0.83)!important
}

a.bg-earth-dark-op:hover,
a.bg-earth-dark-op:focus,
button.bg-earth-dark-op:hover,
button.bg-earth-dark-op:focus {
	background-color: rgba(41, 41, 41, 0.83)!important
}

.bg-earth-darker {
	background-color: #222!important
}

a.bg-earth-darker:hover,
a.bg-earth-darker:focus,
button.bg-earth-darker:hover,
button.bg-earth-darker:focus {
	background-color: #090909!important
}

.bg-earth-light {
	background-color: #a0ca8a!important
}

a.bg-earth-light:hover,
a.bg-earth-light:focus,
button.bg-earth-light:hover,
button.bg-earth-light:focus {
	background-color: #84ba66!important
}

.bg-earth-lighter {
	background-color: #e4f0de!important
}

a.bg-earth-lighter:hover,
a.bg-earth-lighter:focus,
button.bg-earth-lighter:hover,
button.bg-earth-lighter:focus {
	background-color: #c8e0bb!important
}

.bg-gd-earth {
	background: #4c7635;
	background: linear-gradient(135deg, #4c7635 0%, #7bb65c 100%)!important
}

@font-face {
	font-family: "simple-line-icons";
	src: url(../fonts/Simple-Line-Icons.eot?v=2.4.0);
	src: url(../fonts/Simple-Line-Icons.eot?v=2.4.0#iefix) format("embedded-opentype"), url(../fonts/Simple-Line-Icons.woff2?v=2.4.0) format("woff2"), url(../fonts/Simple-Line-Icons.ttf?v=2.4.0) format("truetype"), url(../fonts/Simple-Line-Icons.woff?v=2.4.0) format("woff"), url(../fonts/Simple-Line-Icons.svg?v=2.4.0#simple-line-icons) format("svg");
	font-weight: 400;
	font-style: normal
}

.si {
	font-family: "simple-line-icons";
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.si-user:before {
	content: "\e005"
}

.si-people:before {
	content: "\e001"
}

.si-user-female:before {
	content: "\e000"
}

.si-user-follow:before {
	content: "\e002"
}

.si-user-following:before {
	content: "\e003"
}

.si-user-unfollow:before {
	content: "\e004"
}

.si-login:before {
	content: "\e066"
}

.si-logout:before {
	content: "\e065"
}

.si-emotsmile:before {
	content: "\e021"
}

.si-phone:before {
	content: "\e600"
}

.si-call-end:before {
	content: "\e048"
}

.si-call-in:before {
	content: "\e047"
}

.si-call-out:before {
	content: "\e046"
}

.si-map:before {
	content: "\e033"
}

.si-location-pin:before {
	content: "\e096"
}

.si-direction:before {
	content: "\e042"
}

.si-directions:before {
	content: "\e041"
}

.si-compass:before {
	content: "\e045"
}

.si-layers:before {
	content: "\e034"
}

.si-menu:before {
	content: "\e601"
}

.si-list:before {
	content: "\e067"
}

.si-options-vertical:before {
	content: "\e602"
}

.si-options:before {
	content: "\e603"
}

.si-arrow-down:before {
	content: "\e604"
}

.si-arrow-left:before {
	content: "\e605"
}

.si-arrow-right:before {
	content: "\e606"
}

.si-arrow-up:before {
	content: "\e607"
}

.si-arrow-up-circle:before {
	content: "\e078"
}

.si-arrow-left-circle:before {
	content: "\e07a"
}

.si-arrow-right-circle:before {
	content: "\e079"
}

.si-arrow-down-circle:before {
	content: "\e07b"
}

.si-check:before {
	content: "\e080"
}

.si-clock:before {
	content: "\e081"
}

.si-plus:before {
	content: "\e095"
}

.si-minus:before {
	content: "\e615"
}

.si-close:before {
	content: "\e082"
}

.si-event:before {
	content: "\e619"
}

.si-exclamation:before {
	content: "\e617"
}

.si-organization:before {
	content: "\e616"
}

.si-trophy:before {
	content: "\e006"
}

.si-screen-smartphone:before {
	content: "\e010"
}

.si-screen-desktop:before {
	content: "\e011"
}

.si-plane:before {
	content: "\e012"
}

.si-notebook:before {
	content: "\e013"
}

.si-mustache:before {
	content: "\e014"
}

.si-mouse:before {
	content: "\e015"
}

.si-magnet:before {
	content: "\e016"
}

.si-energy:before {
	content: "\e020"
}

.si-disc:before {
	content: "\e022"
}

.si-cursor:before {
	content: "\e06e"
}

.si-cursor-move:before {
	content: "\e023"
}

.si-crop:before {
	content: "\e024"
}

.si-chemistry:before {
	content: "\e026"
}

.si-speedometer:before {
	content: "\e007"
}

.si-shield:before {
	content: "\e00e"
}

.si-screen-tablet:before {
	content: "\e00f"
}

.si-magic-wand:before {
	content: "\e017"
}

.si-hourglass:before {
	content: "\e018"
}

.si-graduation:before {
	content: "\e019"
}

.si-ghost:before {
	content: "\e01a"
}

.si-game-controller:before {
	content: "\e01b"
}

.si-fire:before {
	content: "\e01c"
}

.si-eyeglass:before {
	content: "\e01d"
}

.si-envelope-open:before {
	content: "\e01e"
}

.si-envelope-letter:before {
	content: "\e01f"
}

.si-bell:before {
	content: "\e027"
}

.si-badge:before {
	content: "\e028"
}

.si-anchor:before {
	content: "\e029"
}

.si-wallet:before {
	content: "\e02a"
}

.si-vector:before {
	content: "\e02b"
}

.si-speech:before {
	content: "\e02c"
}

.si-puzzle:before {
	content: "\e02d"
}

.si-printer:before {
	content: "\e02e"
}

.si-present:before {
	content: "\e02f"
}

.si-playlist:before {
	content: "\e030"
}

.si-pin:before {
	content: "\e031"
}

.si-picture:before {
	content: "\e032"
}

.si-handbag:before {
	content: "\e035"
}

.si-globe-alt:before {
	content: "\e036"
}

.si-globe:before {
	content: "\e037"
}

.si-folder-alt:before {
	content: "\e039"
}

.si-folder:before {
	content: "\e089"
}

.si-film:before {
	content: "\e03a"
}

.si-feed:before {
	content: "\e03b"
}

.si-drop:before {
	content: "\e03e"
}

.si-drawer:before {
	content: "\e03f"
}

.si-docs:before {
	content: "\e040"
}

.si-doc:before {
	content: "\e085"
}

.si-diamond:before {
	content: "\e043"
}

.si-cup:before {
	content: "\e044"
}

.si-calculator:before {
	content: "\e049"
}

.si-bubbles:before {
	content: "\e04a"
}

.si-briefcase:before {
	content: "\e04b"
}

.si-book-open:before {
	content: "\e04c"
}

.si-basket-loaded:before {
	content: "\e04d"
}

.si-basket:before {
	content: "\e04e"
}

.si-bag:before {
	content: "\e04f"
}

.si-action-undo:before {
	content: "\e050"
}

.si-action-redo:before {
	content: "\e051"
}

.si-wrench:before {
	content: "\e052"
}

.si-umbrella:before {
	content: "\e053"
}

.si-trash:before {
	content: "\e054"
}

.si-tag:before {
	content: "\e055"
}

.si-support:before {
	content: "\e056"
}

.si-frame:before {
	content: "\e038"
}

.si-size-fullscreen:before {
	content: "\e057"
}

.si-size-actual:before {
	content: "\e058"
}

.si-shuffle:before {
	content: "\e059"
}

.si-share-alt:before {
	content: "\e05a"
}

.si-share:before {
	content: "\e05b"
}

.si-rocket:before {
	content: "\e05c"
}

.si-question:before {
	content: "\e05d"
}

.si-pie-chart:before {
	content: "\e05e"
}

.si-pencil:before {
	content: "\e05f"
}

.si-note:before {
	content: "\e060"
}

.si-loop:before {
	content: "\e064"
}

.si-home:before {
	content: "\e069"
}

.si-grid:before {
	content: "\e06a"
}

.si-graph:before {
	content: "\e06b"
}

.si-microphone:before {
	content: "\e063"
}

.si-music-tone-alt:before {
	content: "\e061"
}

.si-music-tone:before {
	content: "\e062"
}

.si-earphones-alt:before {
	content: "\e03c"
}

.si-earphones:before {
	content: "\e03d"
}

.si-equalizer:before {
	content: "\e06c"
}

.si-like:before {
	content: "\e068"
}

.si-dislike:before {
	content: "\e06d"
}

.si-control-start:before {
	content: "\e06f"
}

.si-control-rewind:before {
	content: "\e070"
}

.si-control-play:before {
	content: "\e071"
}

.si-control-pause:before {
	content: "\e072"
}

.si-control-forward:before {
	content: "\e073"
}

.si-control-end:before {
	content: "\e074"
}

.si-volume-1:before {
	content: "\e09f"
}

.si-volume-2:before {
	content: "\e0a0"
}

.si-volume-off:before {
	content: "\e0a1"
}

.si-calendar:before {
	content: "\e075"
}

.si-bulb:before {
	content: "\e076"
}

.si-chart:before {
	content: "\e077"
}

.si-ban:before {
	content: "\e07c"
}

.si-bubble:before {
	content: "\e07d"
}

.si-camrecorder:before {
	content: "\e07e"
}

.si-camera:before {
	content: "\e07f"
}

.si-cloud-download:before {
	content: "\e083"
}

.si-cloud-upload:before {
	content: "\e084"
}

.si-envelope:before {
	content: "\e086"
}

.si-eye:before {
	content: "\e087"
}

.si-flag:before {
	content: "\e088"
}

.si-heart:before {
	content: "\e08a"
}

.si-info:before {
	content: "\e08b"
}

.si-key:before {
	content: "\e08c"
}

.si-link:before {
	content: "\e08d"
}

.si-lock:before {
	content: "\e08e"
}

.si-lock-open:before {
	content: "\e08f"
}

.si-magnifier:before {
	content: "\e090"
}

.si-magnifier-add:before {
	content: "\e091"
}

.si-magnifier-remove:before {
	content: "\e092"
}

.si-paper-clip:before {
	content: "\e093"
}

.si-paper-plane:before {
	content: "\e094"
}

.si-power:before {
	content: "\e097"
}

.si-refresh:before {
	content: "\e098"
}

.si-reload:before {
	content: "\e099"
}

.si-settings:before {
	content: "\e09a"
}

.si-star:before {
	content: "\e09b"
}

.si-symbol-female:before {
	content: "\e09c"
}

.si-symbol-male:before {
	content: "\e09d"
}

.si-target:before {
	content: "\e09e"
}

.si-credit-card:before {
	content: "\e025"
}

.si-paypal:before {
	content: "\e608"
}

.si-social-tumblr:before {
	content: "\e00a"
}

.si-social-twitter:before {
	content: "\e009"
}

.si-social-facebook:before {
	content: "\e00b"
}

.si-social-instagram:before {
	content: "\e609"
}

.si-social-linkedin:before {
	content: "\e60a"
}

.si-social-pinterest:before {
	content: "\e60b"
}

.si-social-github:before {
	content: "\e60c"
}

.si-social-google:before {
	content: "\e60d"
}

.si-social-reddit:before {
	content: "\e60e"
}

.si-social-skype:before {
	content: "\e60f"
}

.si-social-dribbble:before {
	content: "\e00d"
}

.si-social-behance:before {
	content: "\e610"
}

.si-social-foursqare:before {
	content: "\e611"
}

.si-social-soundcloud:before {
	content: "\e612"
}

.si-social-spotify:before {
	content: "\e613"
}

.si-social-stumbleupon:before {
	content: "\e614"
}

.si-social-youtube:before {
	content: "\e008"
}

.si-social-dropbox:before {
	content: "\e00c"
}

.si-social-vkontakte:before {
	content: "\e618"
}

.si-social-steam:before {
	content: "\e620"
}

.si-users:before {
	content: "\e001"
}

.si-bar-chart:before {
	content: "\e077"
}

.si-camcorder:before {
	content: "\e07e"
}

.si-emoticon-smile:before {
	content: "\e021"
}

.si-eyeglasses:before {
	content: "\e01d"
}

.si-moustache:before {
	content: "\e014"
}

.si-pointer:before {
	content: "\e096"
}

@font-face {
	font-family: "FontAwesome";
	src: url(../fonts/fontawesome-webfont.eot?v=4.7.0);
	src: url(../fonts/fontawesome-webfont.eot?#iefix&v=4.7.0) format("embedded-opentype"), url(../fonts/fontawesome-webfont.woff2?v=4.7.0) format("woff2"), url(../fonts/fontawesome-webfont.woff?v=4.7.0) format("woff"), url(../fonts/fontawesome-webfont.ttf?v=4.7.0) format("truetype"), url(../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular) format("svg");
	font-weight: 400;
	font-style: normal
}

.fa {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.fa-lg {
	font-size: 1.33333333em;
	line-height: .75em;
	vertical-align: -15%
}

.fa-2x {
	font-size: 1.8em
}

.fa-3x {
	font-size: 3em
}

.fa-4x {
	font-size: 4em
}

.fa-5x {
	font-size: 5em
}

.fa-fw {
	width: 1.28571429em;
	text-align: center
}

.fa-ul {
	padding-left: 0;
	margin-left: 2.14285714em;
	list-style-type: none
}

.fa-ul>li {
	position: relative
}

.fa-li {
	position: absolute;
	left: -2.14285714em;
	width: 2.14285714em;
	top: 4px;
	text-align: center
}

.fa-li.fa-lg {
	left: -1.85714286em
}

.fa-border {
	padding: .2em .25em .15em;
	border: solid .08em #eee;
	border-radius: .1em
}

.fa-pull-left {
	float: left
}

.fa-pull-right {
	float: right
}

.fa.fa-pull-left {
	margin-right: .3em
}

.fa.fa-pull-right {
	margin-left: .3em
}

.pull-right {
	float: right
}

.pull-left {
	float: left
}

.fa.pull-left {
	margin-right: .3em
}

.fa.pull-right {
	margin-left: .3em
}

.fa-spin {
	-webkit-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear
}

.fa-pulse {
	-webkit-animation: fa-spin 1s infinite steps(8);
	animation: fa-spin 1s infinite steps(8)
}

@-webkit-keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

.fa-rotate-90 {
	-ms-filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.fa-rotate-180 {
	-ms-filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.fa-rotate-270 {
	-ms-filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg)
}

.fa-flip-horizontal {
	-ms-filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
	-webkit-transform: scale(-1, 1);
	transform: scale(-1, 1)
}

.fa-flip-vertical {
	-ms-filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
	-webkit-transform: scale(1, -1);
	transform: scale(1, -1)
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
	-webkit-filter: none;
	filter: none
}

.fa-star:before {
	content: "\f005"
}

.fa-star-o:before {
	content: "\f006"
}

.fa-facebook-f:before,
.fa-facebook:before {
	content: "\f09a"
}

.fa-instagram:before {
	content: "\f16d"
}

.fa-google-plus:before {
	content: "\f0d5"
}

.fa-youtube-play:before {
	content: "\f16a"
}

.fa-twitter:before {
	content: "\f099"
}

.fa-refresh:before {
	content: "\f021"
}

.fa-trash-o:before {
	content: "\f014";
}

::-webkit-scrollbar {
	width: 0;
	background: transparent
}

body {
	color: #282c3f!important;
	background-color: #fff
}

.bg-grey {
	background-color: #f4f4f4!important
}

.bg-grey-light {
	background-color: rgba(250, 250, 250, 0.5)!important
}

.bg-white {
	background-color: #fff!important
}

.hidden {
	display: none!important
}

.spinner {
	color: rgba(252, 128, 25, 0.15)
}

.height-25 {
	height: 25vh
}

.height-30 {
	height: 30vh
}

.height-40 {
	height: 40vh
}

.height-50 {
	height: 50vh
}

.height-60 {
	height: 60vh
}

.height-70 {
	height: 70vh
}

.height-80 {
	height: 82vh
}

.height-100 {
	height: 100vh
}

.height-100-percent {
	height: 100%!important;
	/*min-height: 100vh!important;*/
	display: flex;
	flex-direction: column;

	
}

.height-100-percent > div{
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling:touch;
	height:100%;
	flex: 1 1 0;
	padding: 0;
	padding-bottom: constant(safe-area-inset-bottom);
    	padding-bottom: env(safe-area-inset-bottom);
}

.fixed {
	position: fixed;
	pointer-events: all
}

.truncate-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis
}

.shadow-light {
	-webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
	-webkit-transition: .2s linear all;
	transition: .2s linear all
}

.shadow-medium {
	-webkit-box-shadow: 0 8px 18px rgba(0, 0, 0, 0.15);
	box-shadow: 0 8px 18px rgba(0, 0, 0, 0.15)
}

.shadow-dark {
	-webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25)
}

.display-flex {
	display: -webkit-box!important;
	display: -ms-flexbox!important;
	display: flex!important
}

.flex-auto {
	-webkit-box-flex: 1;
	-ms-flex: auto;
	flex: auto
}

.welcome-heading {
	text-align: center;
	margin: .25rem 1.5625rem 0;
	font-size: 1.5rem;
	line-height: 1.75rem;
	font-weight: 700;
	white-space: pre-wrap;
	color: #282c3f
}

.welcome-subheading {
	font-size: .875rem;
	color: #646464;
	margin-top: .9375rem
}

.btn-setup-location {
	width: 16rem;
	height: 4rem;
	margin: 0 auto;
	font-size: 1.1rem;
	font-weight: 700;
	display: block;
	color: #fff;
	text-transform: uppercase;
	border-radius: 1px;
	text-align: center;
	line-height: 2.7rem
}

.btn-setup-location:hover {
	color: #fff
}

.login-block {
	display: block;
	text-align: center;
	color: #646464;
	margin-top: .9375rem
}

.login-block>a {
	font-weight: 400
}

.search-box {
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05)
}

.search-input {
	font-size: .9375rem;
	height: 60px;
	border: 0
}

.search-navs-btns {
	height: 4rem;
	border: 0;
	width: 65px;
	background: #fff;
	border-radius: 0
}

.search-navs-btns>i {
	font-weight: 900;
	color: #899EA2;
	font-size: 25px;
}

.height-8-5 {
	height: 8.5vh
}

.location-search-result {
	min-height: auto;
	background-color: #fff
}

.location-search-block {
	border-top: 1px solid #eaecee;
	position: relative;
	padding: 1.4rem 0;
	background-color: rgba(0, 0, 0, 0.02)
}

.location-search-block:nth-of-type(2n-1) {
	background-color: #fff
}

.location-search-icon {
	width: 5rem;
	float: left;
	text-align: center
}

.location-search-data {
	padding-left: 5.625rem
}

table>tbody>tr {
	height: 6rem
}

.footer-fixed {
	position: fixed;
	bottom: -1px;
	-webkit-box-shadow: 0 -2px 6px 1px rgba(0, 0, 0, 0.15);
	box-shadow: 0 -2px 6px 1px rgba(0, 0, 0, 0.15);
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	background-color: #fff;
	text-align: center;
	z-index: 9999999;
	width: 101%
}

.active-footer-tab {
	color: #fc8019;
	font-weight: 600
}

.footer-links {
	color: #4d4d4d;
	font-weight: 500
}

.cart-quantity-badge {
	border-radius: 100%;
	color: #fff;
	position: absolute;
	top: 0;
	left: 40px;
	font-size: 10px;
	text-align: center;
	height: 16px;
	width: 16px
}

.nav-location {
	height: 4rem;
	border: 0;
	background: #fff;
	border-radius: 0
}

.nav-location-icon {
	font-size: .625rem;
	margin-left: .125rem;
	font-weight: 700
}


.slider-wrapper {
	margin-top: 1rem;
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	margin-bottom: 1rem;
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch
}

.slider-wrapper::-webkit-scrollbar {
	display: none
}

.slider-wrapper__img-wrapper {
	display: inline-block;
	padding-left: 5px;
	padding-right: 5px
}

.slider-wrapper__img {
	height: 12rem;
	width: 12rem;
	border-radius: .4rem
}

.slider-wrapper__img-shadow {
	-webkit-box-shadow: 1px 2px 10px 1px #e0e0e0;
	box-shadow: 1px 2px 10px 1px #e0e0e0
}

.slider-wrapper__img-wrapper:first-child {
	padding-left: 15px
}

.slider-wrapper__img-wrapper:last-child {
	padding-right: 15px !important
}

.slider-wrapper.secondary-slider-wrapper .slider-wrapper__img-wrapper {
	padding-right: 15px;
	/*padding: 0 15px;
    margin: 0;*/
    }

    .slider-wrapper__img-wrapper a{
	padding: 4px 10px;
	display: block;
	position: relative;
	background: #FFFFFF;
	box-shadow: 0px 3px 6px rgba(78, 133, 142, 0.3);
	border-radius: 20px;
    }
    
    .slider-wrapper__img-wrapper a.is-active{
		color:#F8FAFA;
	    background: #4E858E;
    }
    

    .slider-wrapper__img-wrapper a.is-active:before{
	    /*
	content: "";
	background-color: rgb(16, 25, 40);
	width: 100%;
	height: 4px;
	position: absolute;
	bottom: 0;*/
    }

.promo-slider-loader {
	width: 100%;
	margin: 0;
	height: 192px
}

.restaurant-count {
	text-transform: uppercase;
	color: #5b5b5b;
	font-size: .78rem;
	font-weight: 500
}

.restaurant-block {
	padding: 0!important
}

.restaurant-block>div {
	margin-bottom: 0!important
}

.restaurant-image {
	width: 80px;
	height: 80px;
	float: left;
	height: 5.625rem;
	border-radius: .25rem;
	margin-top: 1rem
}

.restaurant-info {
	margin-top: -1.8rem;
	text-align: left;
	padding-left: 8.5rem;
	padding-bottom: 1rem!important
}

.restaurant-info>h4 {
	font-size: 1.2rem
}

.restaurant-meta {
	margin-top: -1.2rem;
	font-size: .8rem;
	font-weight: 500
}

.block-content.block-content-full {
	padding-bottom: 18px
}

.back-button {
	background-color: transparent
}

.login-image {
	max-height: 7rem
}

.login-title {
	font-size: 1.3rem;
	font-weight: 700;
	text-transform: uppercase
}

.login-subtitle {
	font-size: 1rem;
	font-weight: 400
}

.overlay-loading {
	position: fixed;
	width: 100%;
	background-color: #fff;
	z-index: 9999
}

.overlay-loading>div {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: absolute
}

.overlay-loading>div>img {
	width: 300px
}

.auth-error {
	position: fixed;
	bottom: 0;
	text-align: center;
	width: 100%;
	background-color: #282c3f;
	color: #f4f4f5;
	padding: .8rem;
	z-index: 2147483647
}

.error-shake {
	-webkit-animation: shake .85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	animation: shake .85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-perspective: 1000px;
	perspective: 1000px
}

@-webkit-keyframes shake {
	10%,
	90% {
		-webkit-transform: translate3d(-3px, 0, 0);
		transform: translate3d(-3px, 0, 0)
	}
	20%,
	80% {
		-webkit-transform: translate3d(5px, 0, 0);
		transform: translate3d(5px, 0, 0)
	}
	30%,
	50%,
	70% {
		-webkit-transform: translate3d(-1px, 0, 0);
		transform: translate3d(-1px, 0, 0)
	}
	40%,
	60% {
		-webkit-transform: translate3d(7px, 0, 0);
		transform: translate3d(7px, 0, 0)
	}
}

@keyframes shake {
	10%,
	90% {
		-webkit-transform: translate3d(-3px, 0, 0);
		transform: translate3d(-3px, 0, 0)
	}
	20%,
	80% {
		-webkit-transform: translate3d(5px, 0, 0);
		transform: translate3d(5px, 0, 0)
	}
	30%,
	50%,
	70% {
		-webkit-transform: translate3d(-1px, 0, 0);
		transform: translate3d(-1px, 0, 0)
	}
	40%,
	60% {
		-webkit-transform: translate3d(7px, 0, 0);
		transform: translate3d(7px, 0, 0)
	}
}

.button-block {
	width: 100%
}

.btn-main {
	width: 100%;
	height: 4rem;
	font-size: 1.2rem;
	border-radius: 0;
	color: #fff;
	font-weight: 600;
	text-transform: uppercase
}

.fixed-restaurant-info {
	position: absolute;
	top: .6rem;
	left: 5.2rem
}

.fixedRestaurantName {
	font-size: 1rem;
	font-weight: 700
}

.fixedRestaurantTime {
	font-weight: 400;
	font-size: .9rem
}

.recommended-item-content {
	padding: .5rem;
	height: 14rem;
	min-height: 16rem
}

.recommended-item-shadow {
	-webkit-box-shadow: 0 -5px 12px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 0 -5px 12px 1px rgba(0, 0, 0, 0.05)
}

.recommended-item-image {
	border-radius: .1rem;
	height: 131px;
	width: 180px
}

.recommended-item-meta {
	height: 4.5rem;
	min-height: 4.5rem
}

.meta-name {
	font-size: .95rem;
	font-weight: 500
}

.meta-price {
	font-size: .95rem;
	line-height: 1.5625rem
}

.Collapsible {
	background-color: #fff;
	padding:0 15px 5px 15px;
}

.Collapsible__contentInner {
	padding: 5px 5px 5px 0px;
}

.Collapsible__contentInner .category-list-item {
	margin-bottom: .625rem;
	font-size: .98rem;
	margin-bottom: 15px;
	
	padding: 0;
	padding-left: .3125rem;
	flex-direction: row;
}

.itemDetailLeft{
	background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(78, 133, 142, 0.3);
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px;
}

.Collapsible__contentInner .category-list-item:last-child {
	margin-bottom: 5px;
	border: 0
}

.Collapsible__contentInner .category-list-item:first-child {
	margin-top: 5px;
	border: 0
}

.Collapsible__contentInner>div>.item-name {
	font-weight: 500
}

.Collapsible__contentInner>div>.item-price {
	font-weight: 400;
	color: #757575
}

.Collapsible__trigger {
	display: block;
	text-decoration: none;
	position: relative;
	padding: 1.5rem 1rem;
	background: #F4F6F7;
	color: #4B575C;
	border: none;
	margin-bottom: .5rem;
	font-weight: 600
}



.item-badge {
	position: relative;
	color: #fafafa;
	font-size: .6rem;
	padding: .25rem .4rem;
	text-transform: none;
	font-weight: 600;
	margin-left: .25rem;
	border-radius: 1px
}

.btn-add-remove {
	border: 1px solid #efefef;
	width: 3.7rem;
	background-color: #fff;
	height: 2.5rem!important
}

.btn-full .btn-add-remove {
	width: 82px!important
}

.btn-dec {
	font-size: 2rem;
	font-weight: 600;
	line-height: 11px
}

.btn-inc {
	font-size: 1.4rem;
	font-weight: 900;
	line-height: 14px
}

.flex-item-name {
	margin-left: 10px;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	border: 0!important;
	display: flex;
    align-items: center;
    padding-right: 0px;
}

.flex-item-image {
	border-radius: .25rem;
	width: 80px;
	height: 58px;
	position: relative
}

.recommended-text {
	font-size: 1rem;
	text-transform: uppercase
}

.float-cart {
	position: fixed;
	width: 100%;
	height: 4rem;
	padding: 1.2rem;
	font-weight: 700;
	opacity: 0;
	bottom: -20px;
	-webkit-transition: .2s all linear;
	transition: .2s all linear;
	z-index: 10
}

.float-cart--open {
	opacity: 1;
	bottom: 0;
	-webkit-transition: .2s all linear;
	transition: .2s all linear
}

.nav-page-title {
	font-size: 16px;
	font-weight: 400;
	text-transform: uppercase;
}

.line{
	border-bottom: 1px solid #f2f2f2!important;
}

.titleBackWitdth{
	width: calc(100% - 70px) !important;
	/*left: 30px;*/
	left: 45px;
	position: relative !important
}

.back-navs-cartscreen{
	border: 0;
    background: none;
    margin-top: -20px;
    margin-right: 0px;
    padding: 10px;
    text-align: left;
    font-size: 20px;
    margin-left: 0px;
    position: absolute;
    top: 50%;
}



.block-content-navtitlebar .search-box{
	box-shadow: none !important;
	border:none !important
}

.cart-empty-block {
	height: auto;

}

.principalhostblk{
	height: auto;
    padding-top: 70px;
}

.principalhostblk .titleSpan{
	font-size: 1.2rem;
    font-weight: 600;
    color: #535665;
    text-align: center;
    display: block;
    padding: 0 10px;
}

.selectprincipalhost{
	width: 70px;
	padding: 5px 10px;
	margin: 0 auto;
	display: block;
	margin-top: 20px;
}

.cart-empty-img {
	width: 100%
}

.cart-empty-text {
	font-size: 1.2rem;
	font-weight: 600;
	color: #535665
}

.CookieConsent{
	color:#535665 !important;
	font-size: 1rem;
	background: #fff !important;
	position: relative !important;
    justify-content: center !important;
    margin-top: 0px;
    text-align: center;
    z-index: 1 !important;
    display: block !important;
}

.CookieConsent div:first-child{
	margin-bottom: 5px !important;
}

.cookieButton{
	display: block;
}

.cookieButton button{
	background: rgb(16, 25, 40) !important;
	color: #fff !important;
	font-size: 1rem !important;
	padding: 7px 30px !important;
	border-radius: 3px !important;
	font-weight: 600;
	border:none
}

.imgprincipalhost{
	max-height: 170px;
    margin: 0 auto;
    display: block;
    opacity: 0.1;
    margin-top: 30px;
}

.nofloat .navbar-header-left{
	float:none
}

.nofloat .navbar-header{
	margin:0 auto
}

.progress-transparent {
	background-color: transparent!important
}

.btn-quantity {
	height: 2.5rem!important;
	background-color: #fff;
	border: 1px solid #efefef
}

.cart-item-meta {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	
}

.cart-item-meta:last-child {
	border-bottom: 1px solid #f2f2f2
}

.cart-item-meta2{
	align-items: center;
}

.cart-item-name {

	flex-grow: 1;
	
}

.cart-item-meta2 .cart-item-name{
	box-shadow: 0px 3px 6px rgba(78, 133, 142, 0.3);
	padding: 5px 15px;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.cart-item-btn {
	-webkit-box-flex: 1;
	-ms-flex: auto;
	flex: auto;
	width: 110px;
	margin-top: -.5rem
}

.itemDetailContainer{
	position: relative
}

.cart-item-price {
	font-weight: 600;
	color: #4E858E;
	line-height: 26px;
	font-size: 16px
}

.item-text {
	font-size: 1rem;
	text-transform: uppercase;
	border-bottom: 1px solid #f2f2f2;
	padding-bottom: 1rem;
	line-height: 25px;
}

.order-comment {
	height: 3.2rem;
	font-size: 1rem;
	border: 0;
	padding: 0 15px;
	border-radius: 0
}

.coupon-form {
	width: 100%;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee
}

.apply-coupon-input {
	height: 5rem;
	font-size: 1.1rem;
	border: 0;
	padding: 0 15px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px
}

.apply-coupon-input::-webkit-input-placeholder {
	color: #282c3f;
	letter-spacing: 1px
}

.apply-coupon-input:-ms-input-placeholder {
	color: #282c3f;
	letter-spacing: 1px
}

.apply-coupon-input::-ms-input-placeholder {
	color: #282c3f;
	letter-spacing: 1px
}

.apply-coupon-input::placeholder {
	color: #282c3f;
	letter-spacing: 1px
}

.apply-coupon-input::-webkit-input-placeholder {
	color: #282c3f;
	letter-spacing: 1px
}

.apply-coupon-input::-moz-placeholder {
	color: #282c3f;
	letter-spacing: 1px
}

.apply-coupon-btn {
	height: 5rem;
	background-color: #fff;
	width: 4rem;
	border: 0;
	border-radius: 0!important
}

.apply-coupon-btn>i {
	font-size: 1.2rem;
	font-weight: 600
}

.coupon-status {
	width: 100%;
	text-align: center
}

.coupon-success {
	background-color: #60b246;
	color: #fff;
	font-weight: 500
}

.coupon-fail {
	background-color: #f44336;
	color: #fff;
	font-weight: 500
}

.coupon-text {
	color: #60b246;
	font-weight: 500
}

.bill-details::after {
	content: " ";
	background: linear-gradient(-45deg, #f4f4f5 7px, transparent 0), linear-gradient(45deg, #f4f4f5 7px, transparent 0);
	background-position: left bottom;
	background-repeat: repeat-x;
	background-size: 12px 14px;
	display: block;
	left: 0;
	width: 100%;
	height: 14px;
	bottom: 0
}

.bill-detail-text {
	height: 3.2rem;
	font-size: 1.1rem;
	border: 0;
	text-transform: uppercase
}

.cart-checkout-block {
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: 9;
	height: 22vh;
	-webkit-box-shadow: 0 -2px 12px 2px rgba(0, 0, 0, 0.06);
	box-shadow: 0 -2px 12px 2px rgba(0, 0, 0, 0.06)
}

.deliver-to-text {
	font-size: 1rem;
	text-transform: uppercase;
	padding-bottom: 1rem;
	font-weight: 600
}

.change-address-text {
	font-size: .8rem;
	text-transform: uppercase;
	padding-bottom: 1rem;
	font-weight: 600;
	border: 1px solid #ccc
}

.user-address {
	font-size: 13px
}

.almost-there-text {
	font-size: 1.2rem;
	text-transform: uppercase;
	padding-bottom: 1rem;
	font-weight: 700
}

.almost-there-sub {
	font-weight: 400;
	font-size: .9rem
}

.btn-continue {
	width: 100%;
	border-radius: 0;
	height: 4rem;
	margin: 0 auto;
	font-size: 1.1rem;
	font-weight: 700;
	display: block;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 2.7rem;
	bottom: 0;
	position: fixed!important;
	margin-left: -1.1rem
}

.btn-continue:focus,
.btn-continue:hover {
	color: #fff!important
}

.btn-make-payment {
	width: 100%;
	margin: 0 auto;
	
	line-height: 1.95rem;
	font-size: 1.1rem;
	font-weight: 700;
	display: block;
	
	border-radius: 0;
	/*height: 4rem;*/
	bottom: 0;
	position: sticky !important;
	background: #fff;
    box-shadow: rgba(16, 25, 40, 0.08) 0px 0px 9px 0px;
    color:#000 !important
}

.btn-make-payment:focus,
.btn-make-payment:hover {
	color: #000 !important
}

.hr-bold {
	border-top: 2.5px solid #000!important
}

.pages-modal {
	overflow: scroll;
	min-height: 100vh
}

.popup-overlay {
	padding: 0!important
}

.popup-content {
	width: 100%!important;
	border: 0!important;
	padding: 10px!important;
	padding-top: 0!important
}

.cartwraper{
	min-height: 100%;
    display: flex;
    flex-direction: column;
}

.cartwraper .btn-make-payment{
	flex-shrink: 0;
}

.cartcontent{
	flex: 1 0 auto;
}

.close-modal-header {
	position: fixed;
	height: 4rem;
	top: 0;
	width: 100%;
	right: 10px;
	background-color: #fff;
	border: 0!important
}

.close-modal-icon {
	font-size: 2.5rem;
	font-weight: 200
}

.logout-text {
	color: #f44336;
	font-weight: 600
}

.logout-icon {
	color: #f44336;
	font-weight: 900
}

.btn-new-address {
	position: fixed!important;
	width: 100%;
	margin: 0 auto;
	padding: .9375rem 0;
	line-height: 2.15rem;
	font-size: 1.2rem;
	font-weight: 600;
	display: block;
	text-transform: uppercase;
	border-radius: 0;
	bottom: 0;
	color: #fff;
	text-align: center
}

.btn-save-address {
	position: relative;
	width: 100%;
	margin: 0 auto;
	padding: .9375rem 0;
	line-height: 2.15rem;
	font-size: 1.2rem;
	font-weight: 600;
	display: block;
	text-transform: uppercase;
	border-radius: 0;
	color: #fff;
	text-align: center;
	border: 0
}

.edit-address-input-label {
	color: #9e9e9e
}

.edit-address-input {
	margin-bottom: 1.5rem;
	border: 0;
	border-bottom: 1.2px solid #eee;
	border-radius: 0;
	padding: 0
}

.edit-address-tag {
	text-transform: uppercase
}

.btn-edit-address {
	font-weight: 500
}

.btn-address-default {
	background-color: transparent;
	position: relative
}

.btn-address-default>i {
	color: #ccc
}

.btn-address-default.selected>i {
	font-size: 2rem
}

.btn-deliverypin {
	color: #ff9800
}

.btn-refresh-status {
	width: 100%;
	position: fixed;
	bottom: 0;
	border: 0;
	border-radius: 0;
	height: 4rem;
	font-size: 1.2rem
}

.order-item-quantity {
	border: 1px solid #e0e0e0;
	padding: 2px 8px;
	font-weight: 500;
	min-width: 35px;
	text-align: center
}



.phone {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-webkit-box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
	width: 380px;
	height: 665px;
	padding: 10px 7px;
	border-radius: 20px;
	background: #d9dee3;
	-ms-flex-item-align: center;
	align-self: center;
	margin: -3.5rem 0 0 12rem;
	position: relative;
	-webkit-transform: rotate(-15deg);
	transform: rotate(-15deg);
	-webkit-transition: .3s linear all;
	transition: .3s linear all
}

.phone-use {
	width: 380px;
	height: 665px;
	margin: -3rem 0 0 8rem;
	-webkit-transition: .3s linear all;
	transition: .3s linear all;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.phone .notch {
	position: absolute;
	height: 1rem;
	width: 28%;
	background-color: #d9dee3;
	z-index: 9;
	right: 50%;
	-webkit-transform: translate(50%, -5%);
	transform: translate(50%, -5%);
	border-bottom-left-radius: 2rem;
	border-bottom-right-radius: 2rem
}

.phone .iframe-wrapper {
	position: relative;
	border-radius: 12px;
	overflow: hidden;
	height: 100%
}

.phone iframe {
	height: 100%;
	display: block;
	width: 100%;
	border-radius: 12px
}

.blured {
	-webkit-filter: grayscale(0.8);
	filter: grayscale(0.8)
}

.btn-unblur {
	height: 4.5rem;
	width: 200px;
	background-color: #f1592a;
	text-align: center;
	font-size: 1.5rem;
	font-weight: 500;
	color: #fff;
	border-radius: .275rem;
	line-height: 4rem;
	-webkit-transition: .2s linear all;
	transition: .2s linear all
}

.btn-unblur:hover {
	background-color: #f1593a;
	-webkit-box-shadow: 0 12px 32px rgba(0, 0, 0, 0.4);
	box-shadow: 0 12px 32px rgba(0, 0, 0, 0.4);
	cursor: pointer;
	-webkit-transition: .2s linear all;
	transition: .2s linear all
}

#appIframe {
	pointer-events: none
}

.phone-use #appIframe {
	pointer-events: auto!important
}

.bg-gradient {
	background: linear-gradient(-45deg, rgba(103, 58, 183, 0.9), rgba(0, 150, 136, 0.9), rgba(103, 58, 183, 0.9));
	background-size: 400% 400%;
	-webkit-animation: Gradient 30s ease infinite;
	animation: Gradient 30s ease infinite
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0 50%
	}
}

.left-col {
	-webkit-transform: translate(0px, 20%);
	transform: translate(0px, 20%)
}

.desktop-logo {
	margin-bottom: 2.5rem;
	height: 3.5rem
}

.desktop-title {
	color: #424242;
	font-size: 3.5rem;
	font-weight: 900
}

.desktop-subtitle {
	color: #424242;
	font-size: 1.3rem;
	font-weight: 400
}

.store-achievement {
	width: 200px;
	height: 150px;
	-webkit-box-shadow: rgba(34, 48, 102, 0.1) -3px 6px 21.1px -4px;
	box-shadow: rgba(34, 48, 102, 0.1) -3px 6px 21.1px -4px;
	border-radius: 7px;
	background-color: #fff;
	-webkit-transition: all .3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0;
	transition: all .3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0
}

.store-achievement:hover {
	-webkit-transform: translateY(0, -10%);
	transform: translateY(0, -10%);
	-webkit-box-shadow: rgba(34, 48, 102, 0.1) -3px 6px 21.1px -10px;
	box-shadow: rgba(34, 48, 102, 0.1) -3px 6px 21.1px -10px
}

.Tilt {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}

.store-achievement h3 {
	font-weight: 900;
	color: #424242;
	margin-bottom: .45rem
}

.store-achievement h4 {
	-webkit-transform: translateZ(15px);
	transform: translateZ(15px);
	color: #424242
}



.footer-logo {
	height: 2rem
}

.delivery::after {
	content: " ";
	background: -webkit-linear-gradient(135deg, #f4f4f4 7px, transparent 0), -webkit-linear-gradient(45deg, #f4f4f4 7px, transparent 0);
	background-position: left bottom;
	background-repeat: repeat-x;
	background-size: 14px 14px;
	display: block;
	left: 0;
	width: 100%;
	height: 23px;
	bottom: 0;
	position: relative
}

.btn-logout-delivery {
	width: 100%;
	border-radius: 0;
	height: 4rem;
	margin: 0 auto;
	font-size: 1.1rem;
	font-weight: 700;
	display: block;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 2.7rem;
	bottom: 0;
	position: fixed!important;
	background-color: #000
}

.delivery>h3 {
	color: #575757;
	text-transform: uppercase;
	font-size: 1.2rem
}

.delivery .block {
	background-color: #fff;
	margin-bottom: .8rem
}

.delivery .accepted {
	background-color: #4caf50;
	border-radius: .275rem
}

.delivery .new-order {
	background-color: #ffc107;
	border-radius: .275rem
}

.delivery .block-title {
	color: #fff;
	font-weight: 700
}

.delivery-action {
	position: fixed;
	width: 100%;
	bottom: 0
}

.btn-accept {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	border-radius: 0;
	border: 0;
	height: 4rem;
	font-size: 1.2rem;
	color: #fff
}

.btn-get-direction {
	border-radius: 1px;
	background-color: #fff;
	border: 1px solid #e0e0e0
}

.btn-clock {
	color: #ff5722;
	width: 100%
}

.btn-cod {
	width: 100%;
	height: 4rem;
	font-size: 1.2rem;
	background-color: #fff;
	border: 3px dashed #60b246;
	border-radius: 0
}

.btn-payed-online {
	width: 100%;
	height: 4rem;
	font-size: 1.2rem;
	background-color: #fff;
	border: 3px dashed #60b246;
	border-radius: 0
}

.delivery-pin-block input {
	height: 4rem;
	border-radius: 0;
	text-transform: uppercase;
	border: 3px solid #e0e0e0
}

.delivery-pin-block .btn-submit-pin {
	height: 3.2rem;
	width: 100%;
	border-radius: 0
}

.view-delivery-order h3 {
	font-size: 1.3rem;
	text-transform: uppercase
}

.restaurant-address,
.customer-address {
	border: 1px solid #e0e0e0;
	padding: 1rem;
	background-color: #fff
}

.fixed-gdpr {
	min-height: 3rem;
	height: auto;
	text-align: center;
	padding-top: .6rem;
	background-color: #282c3f;
	color: #fff
}

.fixed-gdpr-mobile {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 5px 15px;
	min-height: 0;
	height: auto;
	text-align: center;
	padding-top: .6rem;
	background-color: #282c3f;
	color: #fff;
	position: fixed;
	bottom: 0;
	z-index: 99999999
}

.fixed-gdpr-mobile button {
	color: #fff
}

.fixed-gdpr button {
	color: #fff
}

.mobile-gdpr {
	position: fixed;
	bottom: 0;
	height: 6rem;
	color: #fff;
	left: 0;
	padding: 10px;
	-webkit-box-shadow: 0 -5px 10px 2px rgba(0, 0, 0, 0.2);
	box-shadow: 0 -5px 10px 2px rgba(0, 0, 0, 0.2);
	z-index: 9999999;
	background-color: #282c3f
}

.mobile-gdpr button {
	color: #fff;
	padding: 3px 12px;
	font-size: 1.1rem;
	font-weight: 400;
	opacity: 1
}

.mobile-gdpr button:hover {
	text-shadow: none;
	color: #fff
}

@-webkit-keyframes hover-color {
	from {
		border-color: silver
	}
	to {
		border-color: #3e97eb
	}
}

@keyframes hover-color {
	from {
		border-color: silver
	}
	to {
		border-color: #3e97eb
	}
}

.magic-radio,
.magic-checkbox {
	position: absolute;
	height: 3rem;
	width: 100%;
	opacity: 0;
	z-index: 99999
}

.magic-radio[disabled],
.magic-checkbox[disabled] {
	cursor: not-allowed
}

.magic-radio+label,
.magic-checkbox+label {
	position: relative;
	display: block;
	cursor: pointer;
	vertical-align: middle;
	line-height: 30px
}

.magic-radio+label:hover:before,
.magic-checkbox+label:hover:before {
	-webkit-animation-duration: .4s;
	animation-duration: .4s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-name: hover-color;
	animation-name: hover-color
}

.magic-radio+label:before,
.magic-checkbox+label:before {
	position: absolute;
	top: 0;
	right: 0;
	display: inline-block;
	width: 30px;
	height: 30px;
	content: "";
	border: 2px solid #e7e7e7
}

.magic-radio+label:after,
.magic-checkbox+label:after {
	position: absolute;
	display: none;
	content: ""
}

.magic-radio[disabled]+label,
.magic-checkbox[disabled]+label {
	cursor: not-allowed;
	color: #e4e4e4
}

.magic-radio[disabled]+label:hover,
.magic-radio[disabled]+label:before,
.magic-radio[disabled]+label:after,
.magic-checkbox[disabled]+label:hover,
.magic-checkbox[disabled]+label:before,
.magic-checkbox[disabled]+label:after {
	cursor: not-allowed
}

.magic-radio[disabled]+label:hover:before,
.magic-checkbox[disabled]+label:hover:before {
	border: 1px solid #e4e4e4;
	-webkit-animation-name: none;
	animation-name: none
}

.magic-radio[disabled]+label:before,
.magic-checkbox[disabled]+label:before {
	border-color: #e4e4e4
}

.magic-radio:checked+label:before,
.magic-checkbox:checked+label:before {
	-webkit-animation-name: none;
	animation-name: none
}

.magic-radio:checked+label:after,
.magic-checkbox:checked+label:after {
	display: block
}

.magic-radio+label:before {
	border-radius: 50%
}

.magic-radio+label:after {
	top: 9px;
	right: 8.6px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #60b246
}

.magic-radio:checked+label:before {
	border: 2px solid #bdbdbd
}

.magic-radio:checked[disabled]+label:before {
	border: 1px solid #c9e2f9
}

.magic-radio:checked[disabled]+label:after {
	background: #c9e2f9
}

.magic-checkbox+label:before {
	border-radius: 3px
}

.magic-checkbox+label:after {
	top: 7px;
	right: 11px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 8px;
	height: 15px;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	border-width: 3px;
	border-style: solid;
	border-color: #fff;
	border-top: 0;
	border-left: 0
}

.magic-checkbox:checked+label:before {
	border: #60b246;
	background: #60b246
}

.magic-checkbox:checked[disabled]+label:before {
	border: #c9e2f9;
	background: #c9e2f9
}

.form-group .addon-list {
	margin-bottom: 20px
}

.addon-label {
	font-weight: 400;
	font-size: 1rem
}

.addon-category-block:last-of-type {
	margin-bottom: 8rem
}

.addon-category-name {
	font-weight: 700;
	margin-bottom: 0!important;
	color: #424242;
	font-size: 1rem;
	text-transform: uppercase
}

.btn-customization-done {
	position: fixed;
	bottom: 0;
	margin: 0;
	width: 110%;
	margin-left: -3.8rem;
	height: 4rem;
	z-index: 99999
}

.customizable-item-text {
	text-align: left;
	font-weight: 400;
	font-size: .8rem
}

.noscroll {
	overflow: hidden
}

.customizable-scroll {
	overflow-y: scroll
}

button.payButton {
	width: 100%;
	height: 5.5rem;
	background-color: #fff;
	border: 0;
	-webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
	color: #282c3f!important;
	font-weight: 600;
	font-size: 1.1rem!important
}

.facebook-login-button {
	border: 0;
	height: 3rem;
	color: #fff;
	background-color: #3b5998;
	font-weight: 500;
	padding: 0 15px;
	font-size: 1.1rem
}

.google-login-button {
	border: 0;
	height: 3rem;
	color: #fff;
	background-color: #ea4335;
	font-weight: 500;
	padding: 0 15px;
	font-size: 1.1rem
}

.login-or {
	color: #bdbdbd
}

.login-or:after,
.login-or:before {
	content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
	text-decoration: line-through
}

.srv-validation-message {
	font-size: .8rem;
	color: #ff5722;
	font-weight: 400
}

.resend-otp {
	text-align: center;
	background-color: #fff;
	border: 1px solid #ccc;
	font-size: 1rem;
	color: #9e9e9e;
	width: 100%;
	font-weight: 500;
	padding: 10px 0
}

.countdown {
	font-size: .9rem;
	color: #9e9e9e;
	border: 0
}

button.btn.btn-add-remove:disabled {
	opacity: .8;
	background-color: #eee
}

.quantity-badge-recommended {
	position: absolute;
	top: 5px;
	border-bottom-right-radius: 1rem;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	height: 23px;
	width: 23px;
	font-size: .8rem;
	font-weight: 500;
	color: #fff;
	text-align: center
}

.quantity-badge-list {
	position: absolute;
	border-bottom-right-radius: 1rem;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	height: 20px;
	width: auto;
	min-width: 20px;
	font-size: .7rem;
	font-weight: 500;
	color: #fff;
	display: block;
	text-align: center
}

.popup-overlay.customizable-scroll {
	background: 0!important
}

.btn-preference {
	background-color: #f5f5f5;
	border-radius: 0;
	border-radius: .175rem;
	border-bottom: 3px solid #e0e0e0;
	font-size: .9rem
}

.user-preferred {
	border: 1px solid #eee;
	background-color: #fff;
	border-bottom: 3px solid #fc8019;
	color: #fc8019
}

.sticky-top {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 10
}

.delivery-assigned-block {
	background-color: #fafafa;
	border-bottom: 1px solid #eee
}

.ongoing-payment {
	z-index: 100
}

.ongoing-payment-spin {
	background-color: rgba(96, 125, 139, 0.45)
}

.spin-load,
.spin-load:after {
	border-radius: 50%;
	width: 10em;
	height: 10em
}

.spin-load {
	margin: 0 auto;
	font-size: 4px;
	border-top: 1.1em solid rgba(0, 0, 0, 0.2);
	border-right: 1.1em solid rgba(0, 0, 0, 0.2);
	border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
	border-left: 1.1em solid #000;
	-webkit-animation: load8 1s infinite linear;
	animation: load8 1s infinite linear;
	left: 44%!important;
	top: 45%!important
}

@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg)
	}
}

@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg)
	}
}

.location-error {
	bottom: 4rem
}

.delivery-order-refresh {
	z-index: 2147483647;
	margin-top: -3.7rem;
	position: fixed;
	right: 0;
	font-size: 2rem;
	padding-top: 4rem
}

.order-status-button {
	color: #424242;
	border: 1px solid #e0e0e0
}

.order-track-button {
	border: 1px solid #e0e0e0;
	padding: 5px;
	font-size: 1rem;
	font-weight: 500;
	color: #60b246;
	line-height: 21.5px
}

.pulse {
	display: inline-block;
	width: 12.5px;
	height: 12.5px;
	border-radius: 50%;
	-webkit-animation: pulse 2s infinite;
	animation: pulse 2s infinite;
	vertical-align: middle;
	margin: -3px 0 0 3px;
	background: #60b246
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(96, 178, 70, 0.5);
		box-shadow: 0 0 0 0 rgba(96, 178, 70, 0.5)
	}
	50% {
		-webkit-box-shadow: 0 0 0 26px rgba(96, 178, 70, 0);
		box-shadow: 0 0 0 26px rgba(96, 178, 70, 0)
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(96, 178, 70, 0);
		box-shadow: 0 0 0 0 rgba(96, 178, 70, 0)
	}
}

@keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(96, 178, 70, 0.5);
		box-shadow: 0 0 0 0 rgba(96, 178, 70, 0.5)
	}
	50% {
		-webkit-box-shadow: 0 0 0 26px rgba(96, 178, 70, 0);
		box-shadow: 0 0 0 26px rgba(96, 178, 70, 0)
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(96, 178, 70, 0);
		box-shadow: 0 0 0 0 rgba(96, 178, 70, 0)
	}
}

.ongoing-order-notify {
	background-color: #282c3f;
	border-radius: .275rem;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	position: relative;
	z-index: 0
}

.going-different-restaurant-notify {
	margin-left: -1.2rem;
	margin-bottom: 3.8rem;
	font-size: .9rem;
	font-weight: 400
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
	color: #424242;
	background-color: #fff;
	border-color: #e0e0e0!important
}

.show-more {
	color: #60b246;
	cursor: pointer;
	font-size: .9rem
}

.wallet-checkbox {
	display: none
}

.transaction-wrapper {
	padding: 1.5rem 1.5rem 1.5rem .5rem;
	border-radius: .275rem
}

.transaction-wrapper:nth-child(even) {
	background-color: rgba(247, 247, 247, 0.5)
}

.payment-select-block {
	border-bottom: 2px solid #eee
}

.btn-redeem {
	height: 3rem;
	border-radius: .175rem;
	background: transparent;
	border: 1px solid #ccc;
	width: 100%
}

.no-click {
	pointer-events: none
}

.google-places-autocomplete {
	width: auto!important
}

.google-places-autocomplete input {
	border-bottom: 1px solid #eee;
	border-radius: .1rem;
	background-color: #fafafa;
	color: #575757;
	height: 4rem;
	-webkit-transition: .2s all linear;
	transition: .2s all linear;
	padding-left: 20px;
	font-size: 1rem;
	-webkit-box-shadow: 0 1px 6px 1px #9e9e9e3d;
	box-shadow: 0 1px 6px 1px #9e9e9e3d
}

.google-places-autocomplete input:focus {
	-webkit-box-shadow: 0 0 6px 2px #eee;
	box-shadow: 0 0 6px 2px #eee;
	-webkit-transition: .2s all linear;
	transition: .2s all linear;
	border-color: transparent
}

.location-suggestions-container {
	position: relative;
	margin-top: .6rem
}

.location-suggestion {
	padding: 5px 15px;
	border-bottom: 1px solid #eee
}

.location-main-name {
	color: #171717;
	font-weight: 600
}

.location-secondary-name {
	color: #757575
}

.input-location-icon-field i {
	position: absolute;
	right: 10px;
	font-size: 23px;
	color: #bdbdbd;
	top: 16px
}

.btn-gps {
	background-color: transparent
}

.location-loading-spinner {
	height: 40px;
	margin: 1rem auto auto;
	width: 40px;
	display: block
}

.center-marker {
	position: absolute;
	top: 24%;
	left: 49.8%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.confirm-gps-location {
	height: 44.7vh;
	bottom: 0;
	position: absolute;
	background-color: #fff;
	width: 100%;
	-webkit-box-shadow: 0 -3px 12px -2px #e0e0e0;
	box-shadow: 0 -3px 12px -2px #e0e0e0
}

.bg-danger {
	background-color: #f44336!important
}

.text-danger {
	color: #f44336!important
}

.address-not-usable {
	color: #ccc
}

.address-not-usable h6 {
	color: #ccc
}

.text-sm09 {
	font-size: .9rem
}

.text-sm08 {
	font-size: .8rem
}

.language-select {
	border: 0;
	max-width: 130px;
	border-bottom: 1px solid #e0e0e0;
	border-radius: 0
}

.react-tabs__tab-list {
	display: -webkit-box!important;
	display: -ms-flexbox!important;
	display: flex!important;
	-ms-flex-pack: distribute!important;
	justify-content: space-around!important;
	border: 0!important;
	background-color: #fff
}

.react-tabs__tab {
	padding: 15px 25px!important
}

.react-tabs__tab--selected {
	border-color: transparent!important;
	color: #ff9801!important;
	font-weight: 700
}

.react-tabs__tab:focus {
	-webkit-box-shadow: none!important;
	box-shadow: none!important;
	border-color: none!important
}

.delivery-block-earning {
	background: #ff9801;
	background: -webkit-gradient(linear, left top, right top, from(#ff9801), to(#f44336));
	background: linear-gradient(to right, #ff9801, #f44336)
}

.delivery-block-ongoing {
	background: #8bc34a;
	-webkit-transition: .2s linear all;
	transition: .2s linear all
}

.delivery-block-completed {
	background: #4caf50;
	-webkit-transition: .2s linear all;
	transition: .2s linear all
}

.secondary-slider-wrapper {
	padding: 30px 0 30px 0;
	border-left: 0;
	border-right: 0;
	background-color: #fff;
	margin: 0;
}

.slider-wrapper.secondary-slider-wrapper ul{
	margin:0;
	padding:0
}

.ribbon-box:after {
	content: "";
	top: 0;
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
	width: 100%;
	height: 22px;
	position: absolute;
	z-index: 1;
	-webkit-animation: slide 2.2s infinite;
	animation: slide 2.2s infinite;
	background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0.8)), color-stop(99%, rgba(128, 186, 232, 0)), to(rgba(125, 185, 232, 0)));
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#007db9e8', GradientType=1)
}

@-webkit-keyframes slide {
	0% {
		-webkit-transform: translateX(-130%);
		transform: translateX(-130%)
	}
	100% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%)
	}
}

@keyframes slide {
	0% {
		-webkit-transform: translateX(-130%);
		transform: translateX(-130%)
	}
	100% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%)
	}
}

.items-search-box {
	height: 3.2rem;
	border: 1px solid #eee;
	border-right: 0;
	border-radius: 0
}

.items-search-box:focus {
	border: 1px solid #eee;
	border-right: 0
}

.addQuantityData{
	margin-top:0px
}

.itemDetailAddToCart{
	margin-top:25px
}

.items-search-box-icon {
	height: 3.2rem;
	border: 1px solid #eee;
	border-left: 0;
	border-radius: 0;
	background: transparent
}

.input-group {
	-webkit-transition: .2s linear all;
	transition: .2s linear all
}

.border-radius-275 {
	border-radius: .275rem!important
}

.update-full-notification {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100.2vh;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	vertical-align: middle;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	border-radius: .275rem;
	bottom: 0
}

.update-full-notification>h1 {
	color: #fafafa;
	text-shadow: 0 2px 3px #212121;
	-webkit-animation: color-change 2s infinite linear;
	animation: color-change 2s infinite linear
}

@-webkit-keyframes color-change {
	0% {
		color: #fff
	}
	20% {
		color: #f5f5f5
	}
	40% {
		color: #e0e0e0
	}
	60% {
		color: #9e9e9e
	}
	80% {
		color: #f5f5f5
	}
	100% {
		color: #fff
	}
}

@keyframes color-change {
	0% {
		color: #fff
	}
	20% {
		color: #f5f5f5
	}
	40% {
		color: #e0e0e0
	}
	60% {
		color: #9e9e9e
	}
	80% {
		color: #f5f5f5
	}
	100% {
		color: #fff
	}
}

.store-logo {

        max-width:150px;
     	
	display: block;
	max-height:55px;
}

.no-padding-vertical{
	padding-top:0;
	padding-bottom: 0;
}

.navbar-header-left{
	float:left
}

.buttonrightnav i{
	font-size: 36px;
	color:#4E858E;
	
}

.buttonrightnav svg{
	width: 24px;
	height: 24px;
	margin-left: 25px;
}

.navbar-header-right{
	float:right
}

.navbar-header{
	display: table;
	height: 60px;
}

.navbar-header a{
	height: auto;
    display: table-cell;
    vertical-align: middle;
    
}

.buttonrightnav i{
	margin-left: 25px
}

.actionsheetcontainer{
	position: fixed;
	top:0;
	bottom:0;
	width: 100%;
	background: rgba(0,0,0,0.4);
	z-index: 1000;
	height:100%;
	display: none;
}

.actionsheetcontents{
	position: fixed;
    left: 0;
    bottom: 0;
    z-index: 13500;
    width: 100%;
    max-height: 100%;
    
}

.actionsheetcontents.hide{
	display: none
}

.actionsheetcontainer.show{
	display: block;
}

.actions-group {
	overflow: hidden;
	position: relative;
	margin:10px;
	
	transform: translate3d(0,0,0);
	border-radius: 10px;
}



.actions-button:first-child {
	border-radius: 10px 10px 0 0;
    }

    .actions-button:last-child, .actions-label:last-child {
	border-radius: 0 0 10px 10px;
    }

    .actions-button {
	line-height: 1.3;
	display: block;
	align-items: center;
	padding:20px 10px;
	background: #fff;
	text-align: center;
	font-size: 18px;
	position: relative;
    }

    .actions-button:after, .actions-label:after {
	content: '';
	position: absolute;
	background-color: #e0dfdf;
	display: block;
	z-index: 15;
	top: auto;
	right: auto;
	bottom: 0;
	left: 0;
	height: 1px;
	width: 100%;
	transform-origin: 50% 100%;
	
    }

    .actions-button:last-child:after{
	    display: none
    }

    .color-red{
	    color:red
    }

.certificate-code {
	font-size: 1rem;
	color: #bdbdbd;
	font-weight: 700
}

.explore-bg {
	width: 80%;
	opacity: .5
}

.menu-button-block {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: fixed;
	bottom: 5rem;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 12;
	-webkit-transition: linear .1s bottom;
	transition: linear .1s bottom
}

.btn-menu-list {
	border-radius: 30px;
	height: 2.6rem;
	min-width: 90px;
	color: #fff;
	font-size: 1.1rem;
	-webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 1px 0 rgba(93, 141, 213, 0.2);
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 1px 0 rgba(93, 141, 213, 0.2);
	z-index: 9;
	font-weight: 600;
	font-size: .95rem;
	background-color: #5d8ed5!important
}

.menu-items-block {
	position: fixed;
	left: 50%;
	bottom: 100px;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	pointer-events: auto;
	z-index: 102499999;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	will-change: transform
}

.menu-item-block-inner {
	padding: 32px;
	max-height: 320px;
	width: 250px;
	overflow: auto;
	background: #fff;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	will-change: transform, opacity;
	-webkit-transform-origin: bottom;
	transform-origin: bottom;
	opacity: 1
}

.menu-item-block-single {
	margin-bottom: 20px;
	font-size: 1.15rem;
	color: #3d4152;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	overflow: hidden
}

.menu-item-block-single-name {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	text-transform: capitalize;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1
}

.menu-item-block-single-quantity {
	margin-left: 16px
}

.menu-open-backdrop {
	z-index: 102499998;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(40, 44, 62, 0.6)
}

.page-inactive {
	-webkit-filter: grayscale(1);
	filter: grayscale(1);
	pointer-events: none
}

button[type="submit"]:disabled {
	opacity: .5
}

.category-slider-name {
	position: absolute;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	color: #fff;
	background: rgba(0, 0, 0, 0.8);
	border-radius: 3px;
	font-weight: 500;
	font-size: .7rem;
	left: 50%;
	top: 100%;
	text-align: center;
	padding: 0 5px
}

.category-checkboxes-block {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	overflow-x: auto
}

.category-checkboxes-block label {
	margin-right: 10px;
	background-color: rgba(250, 250, 250, 0.3);
	border-radius: 25px
}

.category-checkboxes-block label span {
	text-align: center;
	display: block;
	padding: 5px 10px;
	border: 1px solid #eee;
	border-radius: 25px
}

.category-checkboxes-block label input {
	position: absolute;
	top: -20px
}

.category-checkboxes-block input:checked+span {
	background-color: #60b246;
	padding: 5px 10px;
	color: #fff;
	border: 1px solid #eee;
	font-weight: 500
}

.filter-count-block {
	margin-right: 10px;
	background-color: rgba(250, 250, 250, 0.3);
	border-radius: 25px;
	min-width: 85px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-weight: 700;
	border: 1px solid #fc8019;
	margin-bottom: 5px;
	color: #fc8019
}

.filter-no-found {
	color: #e0e0e0;
	letter-spacing: 1px
}

.dv-star-rating {
	font-size: 2.6rem
}

.dv-star-rating-empty-star {
	color: #e0e0e0!important
}

.review-screen-bg {
	position: absolute;
	right: 15px;
	width: 10rem;
	top: 8rem
}

.feedback-textarea {
	width: 100%;
	border: 1px solid #eee;
	height: 140px;
	resize: none;
	position: relative;
	opacity: .9;
	background-color: #fff;
	border-radius: .275rem;
	padding: .5rem;
	-webkit-transition: .2s linear all;
	transition: .2s linear all
}

.feedback-textarea:focus {
	-webkit-box-shadow: 0 3px 5px 1px #eee;
	box-shadow: 0 3px 5px 1px #eee;
	outline: 0;
	height: 180px;
	padding: 1rem;
	-webkit-transition: .2s linear all;
	transition: .2s linear all
}

.btn-fixed-bottom {
	position: fixed;
	bottom: 0;
	width: 100%;
	margin: 0 auto;
	padding: .9375rem 0;
	line-height: 2.15rem;
	font-size: 1.2rem;
	font-weight: 600;
	display: block;
	text-transform: uppercase;
	border-radius: 0;
	color: #fff;
	text-align: center;
	border: 0;
	left: 0
}

@-webkit-keyframes strike-text {
	0% {
		width: 0
	}
	100% {
		width: 100%
	}
}

@keyframes strike-text {
	0% {
		width: 0
	}
	100% {
		width: 100%
	}
}

.strike-text {
	position: relative
}

.strike-text::after {
	content: " ";
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: 2px;
	background: rgba(40, 44, 63, 0.55);
	-webkit-animation-name: strike-text;
	animation-name: strike-text;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards
}

.price-percentage-discount {
	font-weight: 700;
	font-size: .8rem
}

.location-saved-address {
	background-color: #fafafa;
	min-height: 80vh
}

.alert-notify-bell {
	position: absolute;
	right: 16px;
	bottom: 6px;
	color: #ccc;
	-webkit-animation: alert-notify-bell 8s 1s ease-in-out infinite;
	animation: alert-notify-bell 8s 1s ease-in-out infinite;
	-webkit-transform-origin: 50% 4px;
	transform-origin: 50% 4px
}

.has-alert {
	position: relative;
	-webkit-animation: alert-notify-bell 8s 1s ease-in-out infinite;
	animation: alert-notify-bell 8s 1s ease-in-out infinite;
	-webkit-transform-origin: 50% 4px;
	transform-origin: 50% 4px
}

@-webkit-keyframes alert-notify-bell {
	0% {
		-webkit-transform: rotateZ(0)
	}
	1% {
		-webkit-transform: rotateZ(30deg)
	}
	3% {
		-webkit-transform: rotateZ(-28deg)
	}
	5% {
		-webkit-transform: rotateZ(34deg)
	}
	7% {
		-webkit-transform: rotateZ(-32deg)
	}
	9% {
		-webkit-transform: rotateZ(30deg)
	}
	11% {
		-webkit-transform: rotateZ(-28deg)
	}
	13% {
		-webkit-transform: rotateZ(26deg)
	}
	15% {
		-webkit-transform: rotateZ(-24deg)
	}
	17% {
		-webkit-transform: rotateZ(22deg)
	}
	19% {
		-webkit-transform: rotateZ(-20deg)
	}
	21% {
		-webkit-transform: rotateZ(18deg)
	}
	23% {
		-webkit-transform: rotateZ(-16deg)
	}
	25% {
		-webkit-transform: rotateZ(14deg)
	}
	27% {
		-webkit-transform: rotateZ(-12deg)
	}
	29% {
		-webkit-transform: rotateZ(10deg)
	}
	31% {
		-webkit-transform: rotateZ(-8deg)
	}
	33% {
		-webkit-transform: rotateZ(6deg)
	}
	35% {
		-webkit-transform: rotateZ(-4deg)
	}
	37% {
		-webkit-transform: rotateZ(2deg)
	}
	39% {
		-webkit-transform: rotateZ(-1deg)
	}
	41% {
		-webkit-transform: rotateZ(1deg)
	}
	43% {
		-webkit-transform: rotateZ(0)
	}
	100% {
		-webkit-transform: rotateZ(0)
	}
}

@keyframes alert-notify-bell {
	0% {
		-webkit-transform: rotateZ(0)
	}
	1% {
		-webkit-transform: rotateZ(30deg)
	}
	3% {
		-webkit-transform: rotateZ(-28deg)
	}
	5% {
		-webkit-transform: rotateZ(34deg)
	}
	7% {
		-webkit-transform: rotateZ(-32deg)
	}
	9% {
		-webkit-transform: rotateZ(30deg)
	}
	11% {
		-webkit-transform: rotateZ(-28deg)
	}
	13% {
		-webkit-transform: rotateZ(26deg)
	}
	15% {
		-webkit-transform: rotateZ(-24deg)
	}
	17% {
		-webkit-transform: rotateZ(22deg)
	}
	19% {
		-webkit-transform: rotateZ(-20deg)
	}
	21% {
		-webkit-transform: rotateZ(18deg)
	}
	23% {
		-webkit-transform: rotateZ(-16deg)
	}
	25% {
		-webkit-transform: rotateZ(14deg)
	}
	27% {
		-webkit-transform: rotateZ(-12deg)
	}
	29% {
		-webkit-transform: rotateZ(10deg)
	}
	31% {
		-webkit-transform: rotateZ(-8deg)
	}
	33% {
		-webkit-transform: rotateZ(6deg)
	}
	35% {
		-webkit-transform: rotateZ(-4deg)
	}
	37% {
		-webkit-transform: rotateZ(2deg)
	}
	39% {
		-webkit-transform: rotateZ(-1deg)
	}
	41% {
		-webkit-transform: rotateZ(1deg)
	}
	43% {
		-webkit-transform: rotateZ(0)
	}
	100% {
		-webkit-transform: rotateZ(0)
	}
}

.btn-clear-alerts {
	border: 1px solid #ef5350;
	background: transparent;
	color: #ef5350;
	border-radius: 0
}

.styles_overlay__CLSq- {
	display: block!important;
	background-color: #fff!important
}

.styles_modal__gNwvD {
	box-shadow: none!important
}

.styles_closeButton__20ID4:focus {
	outline: none!important
}

.menu-background{
  width: 100%      
}

.flex-item-name .item-name{
	display: block;
	font-size: 15px;
	color: #707070;
}

.defaultSelect{
	padding:5px 10px	
}

.form-block-content{
	transition: opacity .2s ease-out;
	margin: 0 auto;
	padding: 18px 18px 1px;
	width: 100%;
	overflow-x: visible;
}

.form-block-content-item{
	display: flex;
	align-items: center!important;
}

.bottomborder{
	border-bottom: 1px solid #f2f2f2;
	position: relative;
}

.middletext{
	position: absolute;
	top: -17px;
	padding: 5px 10px;
	background: #fff;
	left: 50%;
	margin-left: -20px;

}

.form-block-content-item-name{
	flex: auto;
	width: 100px;
	font-weight: 400;
}

.form-block-content-item-right{
	font-weight: 400;
	width: 90px;
	text-align: right
}

.defaultButton{
	display: block;
}



.defaultButton button{
	background: rgb(16, 25, 40);
    color: #fff !important;
    font-size: 1rem ;
    padding: 7px 30px;
    border-radius: 3px;
    border: none;
    outline: none
}

.float-cart{
	background: #FBD74D;
	
	box-shadow: rgba(16, 25, 40, 0.08) 0px 0px 9px 0px;	
}

.cart-item-half{
	flex: auto;
    width: 50%;
    font-weight: 400;
}

.iconCash{
	padding: 2px 12px;
	border: 1px solid #7d7d7d;
	color: #7d7d7d;
	border-radius: 4px;
	margin-right: 10px;
}

.no-border{
	border:none !important
}

.cart-item-arrowup{
	margin-right: 15px;
}

.block-content-total{
	padding: 0px 18px 0px 18px;
}

.btn-order{
	
    display: block;
    font-size: 16px;
    color:#F4FBFC !important;
    background: #022126;
border-radius: 30px;
}

.font-bold{
	font-weight: 700
}

.font-24{
	font-size: 24px;
}

.navtitlebar{
	text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height:56px;
}


.second-navtitle{
	display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
}

.nav-page-title-top{
	font-size: 16px;
	line-height: 32px
}



.orderPlacedContainer{
	display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.orderPlacedTitle{
	font-size: 20px;
    font-weight: 600;
}

.block-content-navtitlebar{
	min-height:56px;
	position: relative
}

.cart-item-quantity{
	border: 1px solid #7d7d7d;
    color: #7d7d7d;
    padding: 0px 2px;
    font-size: 12px;
    min-width: 27px;
    text-align: center;
    border-radius: 4px;
    margin-right:10px
}

.cart-item-quantity span{    
	margin-left: 1px;
}

.btn-default{
	background: #FBD74D;
	display: block;
	font-size: 16px;
}

.btn-default-nobackground{
	border:1px solid #FBD74D;
	display: block;
	color: #FBD74D !important;
}

.width95{
	width: 95%
}

.width-35{
	width: 35%
}

.form-block-content-item-center{
	text-align: center
}

.flex{
	display:flex;
}

#page-container{
	max-width: 768px
}

.itemDetailName{
	margin-top:15px;
	font-size:18px;
	font-weight: 700;
	color: #4E858E;
}

.itemDetailPrice{
	font-size:34px;
	color: #4E858E;
	margin-top:0px;
	font-weight: 700;
}

.itemDescription{
	font-size:16px;
	margin-top:5px;
	white-space: pre-line;
	text-align: center;
}

.itemListDescription{
	font-size:14px;
	margin-top:2px;
	white-space: pre-line;
	
	margin-left:10px;
}

.itemDetailWrapper{
	padding:0 15px;
	position: relative;
}

.back-navs-btns {
	height: 30px;
    border: 0;
    width: 30px;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(164, 173, 186, 0.25);
    left: 10px;
}

.addOnQuantity.hide{
	display: none
}

.back-navs {
	
        border: 0;
    background: none;
    margin-top: 10px;
    margin-right: 0px;
    padding: 10px;
    text-align: left;
    font-size: 20px;
    margin-left: -10px;
}

.firstpersonscreen{
	padding: 10px;
}

.firstpersonscreen .titleSpan{

	text-align: center;
    display: block;
    font-size: 16px;
    
}

.fontweight-500{
	font-weight: 500
}

.qrcodeblk{
	text-align: center;
    margin-top: 10px;
}

.itemdetailcourse .form-block-content{
	padding:0;
	margin-bottom: 15px
}

.itemdetailcourse .addQuantityData{
	margin:0
}

.itemdetailcourse{
	margin-top:0px
}

.itemdetailcourse .form-block-content-item-name{
	width: 65px;
}

.itemdetailcourse .form-block-content-item-right{
	width: 140px;
}

.courseName{
	font-weight: 600;
    background: rgb(205, 228, 255);
    display: inline-block;
    padding: 4px 12px;
    border-radius: 4px;
    color: rgb(3, 122, 255);
}

.courseBlk{
	margin-bottom: 10px!important;
}

.courseWrapper .courseBlk:last-child{
	margin:0 !important
}

.courseWrapper .courseBlk:first-child{
	margin-top: 10px !important
}

.btnbasket{
	position: relative
}

.btnbasket svg{
	fill: none;
    stroke: #4e848d;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-width: 1.4px;
    width: 38px;
    height: 38px;
}

.cartbadge{
	position: absolute;
    background: #022126;
    padding: 0px 5px;
    border-radius: 50%;
    color: #F4FBFC;
    top: -7px;
    right: -18px;
    font-size: 12px;
    text-align: center;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.badgeStyle
{
	display: flex;
    align-items: center;
    margin-right:15px;
    margin-bottom: 10px
}

.badgeStyle img{
	height:16px
}

.badgeStyle span{
	font-size:14px;
	margin-left: 5px;
	font-weight: 400;
}

.badgeBlk{
	display: flex;
    justify-content: center;
    margin-top:10px;
    flex-wrap: wrap;
}

.spinner{
	display: flex;
    align-items: center;
    justify-content: center;
    height: 100%!important;
    min-height: 100vh!important;
    flex-direction: column;
}

.spinnerNoHeight{
	display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loadingText{
	color:#555;
	font-size: 18px;
	font-weight:500;
	margin-top:10px
}

.top-60{
	top: 60px
}

.category-listrightflex{
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.category-listrightflex-top{
	display: flex;
    justify-content: space-between;
}

.badgeBlkList{
	display: flex;
	justify-content: left;
	margin-top: 10px;
	margin-left: 10px;
}

.badgeBlkList img{
	height:14px;
}

.badgeBlkList span{
	font-size: 12px;
    font-weight: 400;
    margin-left: 3px
}

.item-actions{
	display: flex;
    align-items: center;
}

.modal-bottom-full{
	margin: 0;
	bottom: 0;
	position: fixed;
	width: 100%;
}

.modal .modal-bottom-full {
	transform: translate(0, 25%) !important;
}

.modal.show .modal-bottom-full
{
	transform: translate(0, 0) !important;
	min-height: 90vh;
	background: #fff;
	max-height: 90vh;
}

.modal.show .modal-bottom-full-small{
	min-height: 450px;
	max-height: 50vh;
}

.modalCartItemContainer{
	margin-top:40px
}

.modalCartItemButton{
	margin-top:60px
}

.courseWrapper .cart-item-meta:last-child {
	padding-bottom: 15px
}

.addon-background{
	width: 100%;
	border-radius: 4px;
}

.addOnItemNameAndPrice{
	margin-top: 10px;
	display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.addOnItemName{
        font-size: 18px;
    font-weight: 700;
    color: #4E858E;
}

.addOnItemPrice{
	font-size: 16px;
    color: blue;
}

.modal-header-absolute .modal-header{
	position: absolute;
	z-index: 1;
}

.modal-bottom-full .modal-header{
	padding: 8px 18px;
    border-bottom: 1px solid #f2f2f2;
    display: block;
    position: relative;
    text-align: center
}

.modal-bottom-full .modal-header .close{
	position: absolute;
	right: 10px;
	top: 6px;
	z-index: 1;
	font-size: 35px;
	padding: 0;
	margin: 0;
	opacity:1
}

.modal-bottom-full .modal-body{
	padding: 0;
    height: calc(90vh - 44px);
    overflow: auto;
}

.addOnContainer{
	padding-bottom: 20px;
	overflow: auto;
}

.addOnContainerTop{
	padding:18px 18px 18px 18px
}

.addOnItemNameNote{
	margin-top:5px
}

.addOnItemNameNote input{
	width: 100%;
    border: none;
    padding: 0;
    outline: none;
    box-shadow: none;
}

.addOnItemNameNote input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
color: blue;
font-size: 13px
}
.addOnItemNameNote input::-moz-placeholder { /* Firefox 19+ */
color: blue;
font-size: 13px
}
.addOnItemNameNote input:-ms-input-placeholder { /* IE 10+ */
color: blue;
font-size: 13px
}
.addOnItemNameNote input:-moz-placeholder { /* Firefox 18- */
color: blue;
font-size: 13px
}
/*
.block-content .courseBlk:last-child .cart-item-meta{
	border:none
}*/

.addOnHeader{
	background: #F2F2F7;
    padding: 12px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addOnHeaderName{
	font-weight: 500
}

.addOnHeaderRequired{
	color:#D60404
}

.addOnItemContainer{
	display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px 0;
}

.addonItemName{
	flex-grow: 1;
	margin-left: 10px;
	color: #868585
}

.addOnBody{
	-moz-transition: height .5s;
	-ms-transition: height .5s;
	-o-transition: height .5s;
	-webkit-transition: height .5s;
	transition: height .5s;
	overflow: hidden;
	height: 0;
}

.addOnBodyChild{
	padding: 5px 18px;
}

/*
.addOnBody.active{
	height: auto !important;
    
    
}*/

.addOnItemContainer .checkbox{
	width: 25px;
	height: 25px;
	-moz-appearance: none;
	-webkit-appearance: none;
	-o-appearance: none;
	border: 1px solid #4E858E;
	flex-shrink: 0;
}

.addOnItemContainer.onChecked{
	position: relative;
}

.addOnItemContainer.onChecked .checkbox{
	background: transparent;
	border: 1px solid transparent;
	position:relative;
	z-index: 1;
}

.addOnItemContainer.onChecked .checkbox + div::before
{
	content: "\f00c";
    font-size: 15px;
    font-family: "FontAwesome";
    position: absolute;
    left: 0;
    color: #fff;
    background: #022126;
    border: 1px solid #022126;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    margin-top: -12.5px;
}

.addonItemPrice{
	font-weight: 500
}

.addOnUpTo{
	color: #8e8e8e;
    font-weight: 400;
    margin-left: 10px;
    font-size: 13px;
}

.addOnItemContainer.onChecked .addonItemName{
	color:#000;
	font-weight: 500
}

.addOnAddToCart{
	padding: 0 18px;
}

.addOnAddToCart .itemDetailAddToCart{
	margin-top: 15px;
	position: relative
}

.btn-disabled{
	background: #dadada;
	color: #fff!important;
	white-space: normal;
	min-height: 40px;
	height: auto;
}

.btncartremove{
	position: fixed;
    width: calc(100% - 36px);
    bottom: 50px;
    background: none;
    border: 2px solid #555;
    color: #333 !important;
}

.addOnTotal{
	display: flex;
	justify-content: space-between;
	padding:0 18px;
	margin-top:10px;
	align-items: center;
	flex-direction: column-reverse;
}

.addOnTotalText{
	font-size: 14px;
	font-weight: 400 !important;
	text-transform: uppercase;
}

.addOnTotalNumber{
	font-size: 34px;
	color: #4E858E;
	font-weight: 700
}

.cartscreenItemName{
	
	display: flex;
    flex-direction: row;
    align-items: center;
}

.cartscreenItemName span{
	font-weight: 400;
	display: flex;
    flex-direction: row;
    font-size: 16px;
line-height: 26px;
color: #4B575C;
}

.cartscreenItemAddOnName{
	font-size: 12px;
	color: #4B575C;
}

.cartitemquantity{
	margin-right: 5px;
	font-size: 16px;
	line-height: 26px;
	color: #4B575C;
	min-width: 22px;
}




.btn-order .spinnerload > div {
        width: 18px;
        height: 18px;
        background-color: #F4FBFC;
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .btn-order .spinnerload .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .btn-order .spinnerload .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }

    .orderhistory-wraper{
	padding: 10px 0px;
	background: #fff;
	margin: 5px 15px 0px 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	
    }

    .orderhistory-wraper .orderhistory-right{
	flex-shrink: 0;
    }

    .orderhistory-countitems{
	    margin-top:5px;
	    display: flex;
    align-items: center;
    color: #707070;
    }

    .orderhistory-countitems span{
	    margin-left:10px;
	    margin-right:10px;
	    color:#b2b2b2
    }

    .orderhistory-right a {
	color: #F4FBFC !important;
	background: #022126;
	border-radius: 30px;
	padding: 5px 10px;
	font-weight: 500
    }

    .cartcontent .orderhistory-wraper:last-child{
	border:none;
	margin-bottom: 10px
    }
    

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.btn-order .spinnerload{
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
}



.btn-order .spinnerload > div {
	color: #000;
    }

.spinnerModal{
	display: flex;
    align-items: center;
    justify-content: center;
    height: 100%!important;
    min-height: 100vh!important;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 10000000;
}

.spinnerModal > div
{
	z-index: 300;
}

.spinnerModal:after
{
	content: "";
	background: rgba(0, 0 ,0, 0.5);
	z-index: 200;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
}

.spinnerModal .loadingText{
	color:#fff;
	z-index: 1000;
	padding: 0 15px;
    text-align: center;
}

.btn-ordercartscreen{
	display: flex;
	justify-content: center;
	align-items: center
}

.flex-item-name-noimage{
	margin-left: 0px;
}

.badgeBlkList.noimage{
	margin-left: 0;
}

.policySection{
	padding:13px 15px
}

.block-section {
	line-height: 24px;
	font-size: 16px;
	margin-bottom: 20px;
	color: #101928;
    }

    .font-18 {
	font-size: 18px !important;
    }

    .font-22 {
	font-size: 22px !important;
	line-height: 26px;
    }

    .phoneblk{
	    margin:20px 0 0 0
    }

    .phoneblk input{
	width: 100%;
	display: block;
	padding: 10px 16px 12px 16px;

    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	}

	.cookieButton.disableBtn button{
		background: #dadada !important;
	color: #fff !important;
	}

	.video-container {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
	    }

	    


	    .video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	    }

	    .player-wrapper {
		position: relative;
		padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
	      }
	       
	      .react-player {
		position: absolute;
		top: 0;
		left: 0;
	      }

	    .badgeName{

	    }

	    .subtree{
		    position:relative;
		    /*padding: 10px 10px 10px 15px;*/
		    padding:0
	    }

	    .subtree:before{
		display: inline-block;
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 10px;
    left: 15px;
    border-left: 1px dashed #eaeaea;

    display: none
	}

	.subcategory .Collapsible{
		padding: 0 0px 0px 15px;
	}

	.subcategory .Collapsible__trigger{
		margin: 0;
    margin-left: 5px;
    background: none;
    border-bottom: 2px solid #c4c4c4;
    color: #4E858E;
    padding: 10px 35px 10px 20px;
	}

	.subcategory .Collapsible__trigger:before{
		display: inline-block;
    content: "";
    position: absolute;
    top: 31px;
    left: -20px;
    width: 18px;
    height: 0;
    border-top: 1px dashed #eaeaea;
    z-index: 1;

    display: none
	}

	.subcategory .Collapsible__trigger:after{
		content: "+";
		top: 4px;
		right:15px;
		position:absolute;
		display: block;
		transition: transform .3s;
		font-size: 26px;
		color: #4e858e;
		font-family: "FontAwesome";
		font-size: 20px;
		font-family: inherit;
	}

	.subcategory .Collapsible__trigger.is-open:after{
		transform: rotate(135deg);
		font-size: 22px;
		top: 6px;
		right: 13px;
	}

	.subcategory .Collapsible__trigger.is-open{
		border-color:transparent
	}

	.subTrigger{
		margin:0;
		margin-bottom: 0.5rem
	}

	.subcategory .Collapsible__contentInner{
		padding:0
	}

	.subcategory .Collapsible__contentInner .category-list-item{
		padding: 0;
    margin: 3px 5px 5px 5px;
    padding-left: 0;
   
	}

	.subtree .subcategory:last-child .Collapsible__trigger{
		border:none
	}

	.form-control.order-comment{
		padding-left: 18px;
		padding-right: 18px;
	}
@media (max-height: 736px) {
	.principalhostblk{
		padding-top: 10px;
	}
}

@media (max-width: 374px) {
	.store-logo{
		max-width: 130px;
	}

	.buttonrightnav i{
		font-size: 18px;
	}

	.buttonrightnav svg{
		width: 38px;
		height: 38px;
	}

	.orderhistory-countitems{
		flex-direction: column;
		align-items: flex-start;
	}

	.orderhistory-countitems span{
		display:none
	}
	
}

.animateCart{
	position: fixed;
	width:30px;
	height:30px;
	border-radius: 50%;
	background: #022126;
	/*transition: all .04s;*/
	z-index: 10000000000;
	left: 0;
	top: 0;
	display:none;    

	
	align-items: center;
	justify-content: center;
	
}

.animateCart span{
	font-size: 22px;
	color: #F4FBFC;
	max-height: 30px;
	margin-top: -4px;
}

.animateCart.showAnimation{
	display:flex;  
	
	
}

.paymentForm{
	flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 30px;
}

.paymentForm{
	background: #fff;
	margin-top: 20px;
	padding-top: 15px;
}

.paymentForm form{
	padding:0 18px 0 18px
}

.paymentForm form label{
	width:100%
}

.StripeElement {
	display: block;
	margin: 10px 0 20px 0;
	max-width: 500px;
	padding: 10px 14px;
	font-size: 1em;
	font-family: "Source Code Pro", monospace;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
	  rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border: 0;
	outline: 0;
	border-radius: 4px;
	background: #f2f2f2;
      }

      

      .modal-dialog.modal-bottom-full.fullpage{

	
	height: 100%!important;
	min-height: 100% !important;
	display: flex;
	flex-direction: column;
	max-height: 100%;
      }

      .modal-dialog.modal-bottom-full.fullpage .modal-content{
	border-radius: 0;
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	height: 100%;
	flex: 1 1;
	padding: 0;
	padding-bottom: constant(safe-area-inset-bottom);
	padding-bottom: env(safe-area-inset-bottom);
	background: #fff !important;
      }

      .modal-dialog.modal-bottom-full.fullpage .modal-content .cartwraper .p-0.sticky-top{
	flex: 0 0 auto;
      }

      .languagewraper{
	background: #fff;
	margin: 20px 0;
      }

      .language-item{
	      border-bottom: 1px solid #c7ced4;
	      padding: 10px 15px;
	      display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
      }

      .languagewraper .language-item:last-child{
	border:none
      }

      .languageCurrent{
	display: flex;
	align-items: center;
      }

      .languageCurrent svg{
	      width:16px;
	      height:16px;
	      fill: '#4E858E'
      }

      .nav-header-wraper{
	display: flex;
	align-items: center;
	justify-content: center;
      }

      .navbar-header-mobile{
	      display:flex;
	      justify-content: space-between;
	      align-items: center;
	      position: absolute;
		    left: 20px;
		    width: 37px;
		    height: 24px;
      }

      .navbar-header-menu{
	      width:37px;
	      position: relative;
	      height:24px
      }

      .nav-dot-one{
	position: absolute;
	left: 0%;
	right: 0%;
	top: 0%;
	bottom: 75%;
	background: #4E858E;
	border-radius: 8px;
      }

      .nav-dot-two{
	position: absolute;
	left: 0%;
	right: 24.32%;
	top: 37.5%;
	bottom: 37.5%;
	background: #4E858E;
	border-radius: 8px;
      }

      .nav-dot-three{
	position: absolute;
	left: 0%;
	right: 48.65%;
	top: 75%;
	bottom: 0%;
	background: #4E858E;
	border-radius: 8px;
      }

      .navbar-header-mobile-right {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	right: 25px;
    }

    .btnbasket-item{
	width: 42px;
	height: 42px;
	display: flex;
	background: #022126;
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
align-items: center;
justify-content: center;
border-radius: 50%;
    }

    .btnbasket-item i{
	    color:#fff;
	    font-size: 23px;
	    margin-top: 3px;
    }

    .btnbasket-item svg{
	fill: none;
	stroke: #fff;
	stroke-linecap: round;
	stroke-miterlimit: 10;
	stroke-width: 1.4px;
	width: 29px;
	height: 25px;
    }

    .itemDetailRight{
	display: flex;
	align-items: center;
	margin-left: 20px;
    }

    .itemDetailLeft .item-price{
	margin-left: 10px;
	margin-top: 0px;
    }

    .itemDetailLeft .item-price span{
	color: #4E858E;
	font-weight: 700;
    }

    .form-block-content-course-name{
	font-weight: 500;
	display: flex;
    align-items: center;
    }

    .form-block-content-course-name input{
	    margin-right:10px;
	    width: 20px;
    height: 20px;
    }

    .modal.show .modal-bottom-full-center{
	margin: 0 20px;
	width: calc(100% - 40px);
	bottom: auto;
	top: 5vh;
    }

    .modal.show .modal-bottom-full-center{
	margin: 0 20px;
	width: calc(100% - 40px);
	bottom: auto;
	top: 80px;
	border-radius: 10px !important;
	min-height: calc(100% - 100px);
	    max-height: calc(100% - 100px);
	    overflow: auto;
    }

    .modal.show .modal-bottom-full-center .modal-body{
	height: calc(100vh - 144px);
    }

    .modal.show .modal-bottom-full-center .modal-body .menu-background{
	width: calc(100% - 30px);
	margin: 15px auto 0 auto;
	display: block;
	border-radius: 4px
    }

    .shouldhidebackdrop {
	    display:none
    }

    .menuscreen{
	transition: all ease-in-out .15s;
	opacity:1
    }

    .menuscreen.onmodalshow{
	position: relative;
    }

    .menuscreen.onmodalshow:before{
	content:'';
	position: absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background: #022126;
	opacity: 0.8;
	z-index: 1000;
    }

    .custommodal.modal{
	top: 60px;
    }

    .onmodalitemopen #pagewraper > .sticky-top{
	z-index: 1001;   
    }

    .custommodal.modal .modal-dialog{
	overflow: visible;
	z-index: 2;
    }

    .custommodal.modal{
	overflow: visible;
    }

    .custommodal.modal .modal-dialog .modal-content
    {
	border-radius: 10px !important;
    }

    .btn-add-remove{
	background: #022126;
	color: #fff;
	
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px !important;
	border-radius: 50% !important;
	padding: 0 !important;
    }

    .btn-add-remove .btn-dec{
	font-size: 0;
	height: 2px;
	width: 12px;
	background: #fff;
	display: block;
    }

    .btn-add-remove .btn-inc{
	font-size: 0;
	height: 2px;
	width: 12px;
	background: #fff;
	display: block;
	position:relative
    }

    .btn-add-remove .btn-inc:before{
	content: "";
	font-size: 0;
	height: 2px;
	width: 12px;
	background: #fff;
	display: block;
	transform: rotate(90deg);
	
    }

    .btn-quantity{
	color: #4E858E;
	font-size: 18px !important;
	font-weight: 700 !important;
	border:none !important;
	padding:0 15px !important;
	height:30px !important
    }

    .cart-item-btn{
	width: auto;
	background: #FFFFFF;
	box-shadow: 0px 3px 6px rgba(78, 133, 142, 0.3);
	border-radius: 30px;
	margin:10px 0
    }

    .itemDescription{
	    color: #707070
    }

    .addOnArrow{
	    position: relative;
	    height: 20px;
	    width: 23px;
	    cursor: pointer;
	    text-align: right;
    }

    /*
    .addOnArrow:after{
	font-family: "FontAwesome";
	content: "\f107";
	position: absolute;
	right: 0;
	transition: transform 300ms;
	font-size: 26px;
	color: #4E858E;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
    }*/

    .addOnArrow svg{
	height: 11px;
	transition: transform .3s;
    }

    .addOnWraper
    {
	margin: 0 18px 5px 18px;
    }

   

   

    .modal .modal-bottom-full-menu {
	min-height: calc(100vh - 60px) !important;
	    max-height: calc(100vh - 60px) !important;
	    width: 145px;
	    transform: none !important;
	    opacity: 0;
    }

    .modal-bottom-full-menu .modal-content
    {
	background: #022126;
	opacity: 0.9;
	position: fixed;
	top:0;
	bottom:0
    }

    .modal-bottom-full-menu .modal-body{
	height: calc(100vh - 60px);
    }

    .custommodalmenu.modal{
	top: 60px;
    }

   

.modal.show .modal-bottom-full-menu
{
	opacity: 0.9;
	top:60px;
	bottom:0;
	min-height: auto !important;
	max-height:100% !important;
	height:auto !important;
}



    .leftMenuToggle{
	display:none;
	width: 145px;
    position: fixed;
    top: 60px;
    bottom: 0;
    

    }

    .leftMenuToggle.onMenuOpen{
	display:block
	}

	.headersticky{
		z-index: 11;
	}

	.leftMenuWrapper{
		height: 100%;
	}

	.leftMenuBlock{
		padding: 30px 0;
		margin:0 20px;
    border-bottom: 1px solid #4E858E;
	}

	.leftMenuWrapper .leftMenuBlock:last-child{
		border:none
	}

	
	.callwaitermenu .svgmenuwraper{
		height: 65px;
		position:'relative'
	}

	.callwaitermenu svg{
		height: 65px;
		position: absolute;
		left: 50%;
		margin-left: -32.5px;
	}

	.orderhistorymenu .svgmenuwraper{
		height: 65px;
		position:'relative'
	}

	.orderhistorymenu svg{
		height: 65px;
		position: absolute;
		left: 50%;
		margin-left: -32.5px;
	}

	.leftMenuBlockAction{
		color: #F4FBFC;
		text-align: center;
		font-size: 12px;
		line-height: 20px;
	}

	.leftMenuBlockIcon svg{
		fill:#fff;
		width:50px;
		height:50px
	}

	.leftMenuBlockIcon{
		text-align: center;
		margin-bottom: 10px
	}

	.leftMenuBlockImg
	{
		text-align: center;
		margin-bottom: 10px;
		position: relative;
	}

	.leftMenuBlockImg img
	{
		width:50px;
		height:50px
	}

	.orderhistorymenu img{
		width: 60px;
    		height: 60px;
	}

	.callwaitermenu img{
		width: auto;
    		height: 70px;
	}

	.navigation-block{
		box-shadow:none;
		padding:10px 0
	}

	.navigation-block .search-navs-btns i{
		font-size: 25px;
		color:#899EA2;
	}

	.back-navs-cartscreen i{
		font-size: 25px;
		color: #899EA2;
	}

	.navigation-block .back-navs-cartscreen i {
		font-size: 25px;
		color: #899EA2;
		font-weight: bold;
	}

	.navigation-block .nav-page-title
	{
		color: #4E858E;
		font-size: 22px;
		line-height: 27px;
	}

	.cart-item-trash{
		margin-left: 15px;
	}

	.cart-item-trash a {
		width: 42px;
		height: 42px;
		display: flex;
		background: #022126;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
		align-items: center;
		justify-content: center;
		border-radius: 50%;
	    }

	.cart-item-trash a i{
		font-size:23px;
		color: #fff;
	}

	.cart-item-trash a svg{
		height:26px;
		fill: #fff;
	}

	.mainbutton{
		border-radius: 4px;
	}

	.cart-item-meta-second .cart-item-price{
		font-size:34px;
		line-height: 37px;
		margin-bottom: 5px;
	}

	.cart-item-meta-second .cart-item-name{
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 400;
	}

	.cart-item-name-right i
	{
		color: #4E858E;
		font-size: 23px;
	}

	.cart-item-name-left{
		flex-grow:1
	}

	.cart-item-meta-second{
		flex-direction: column-reverse;
	}

	.noborder{
		border:none
	}

	.nopadding{
		padding:0
	}

	.maincolor{
		color: #4E858E;
	}

	.textnormal{
		text-transform: none
	}

	.item-text2{
		text-transform: none;
		color: #4E858E;
		border:none;
		padding:0;
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
		padding-bottom: 10px
	}

	.cart-item-meta3{
		padding: 5px 0 !important;
	}

	.cart-item-meta2:last-child{
		border:none
	}

	.cart-item-meta3:last-child{
		border:none
	}

	.btn-make-payment2{
		box-shadow: none;
		padding:10px 0
	}

	.btn-make-payment3{
		box-shadow: none;
		padding-top: 18px
	}

	.cartwraperheader{
		min-height:calc(100% - 60px)
	}

	.whitebackdrop{
		background: rgba(255,255,255,0);
	}

	.bgmodal.modal:after{
		content:"";
		position:absolute;
		top:0;
		bottom:0;
		right:0;
		left:0;
		background: #022126;
		opacity: 0.8;
		z-index: 1;
	}

	.custommodalmain.modal{
		display: flex !important;
    align-items: center;
	}

	.custommodalmain.modal .modal-header{
		padding:0;
		border:none
	}

	.custommodalmain.modal .modal-header .close{
		font-size:34px;
		color:#899EA2
	}

	.custommodalmain.modal:after{
		content:"";
		position:absolute;
		top:0;
		bottom:0;
		right:0;
		left:0;
		background: #022126;
		opacity: 0.8;
		z-index: 1;
	}

	.custommodalmain.modal .modal-dialog{
		margin: 0 40px;
		    width: calc(100% - 80px);
		    top: auto;
		    bottom: auto;
		    min-height: auto;
		    max-height: auto;
		    height: auto;
	}

	.custommodalmain.modal .modal-bottom-full-center .modal-body{
		height:auto
	}

	.ModalSubmitWraper{
		text-align: center;
		padding: 40px 10px;
	}

	.ModalSubmitWraper div{
		font-size:16px;
		color: #4B575C;
	}
	.orderDetailWraper{
		box-shadow: 0px 3px 6px rgba(78, 133, 142, 0.3);
		padding:10px 15px 0px 15px;
	}

	.item-text3{
		text-transform: none;
		color: #4E858E;
		border-bottom: 1px solid #4B575C;
		padding: 0;
		font-size: 16px;
		line-height: 17px;
		font-weight: 500;
		padding-bottom: 10px;
		margin: 0 !important;
	}

	.orderDetailWraper .cart-item-meta{
		border-bottom: 1px solid #899EA2;
		padding: 10px 0 !important;
	}

	.orderDetailWraper .cart-item-meta:last-child{
		border:none
	}

	.back-navs-item{
		border: 0;
		background: none;
		padding: 10px;
		text-align: left;
		font-size: 25px;
		margin-left: 0px;
		position: absolute;
		top: -15px;
		left: 0;
		color: #899EA2;
	}

	.back-navs-item .si-arrow-left{
		font-weight: 900;
	}

	.onpaddingitemname{
		padding:0 15px
	}

	.orderhistory-date{
		color: #4E858E;
		font-weight: 500;
	}

	.popupImg{
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.popupMiddleText{
		color: #4E858E !important;
		font-weight: 500;
		position: absolute;
	}

	.ModalAlertWraper{
		text-align: center;
		padding: 30px 20px;
	}

	.mainalertbtn{
		background: #022126;
		border-radius: 30px;
		padding: 5px 10px;
		color: #F4FBFC !important;
		min-width: 130px;
		display: inline-block;
		font-weight:500;
		font-size: 16px
	}

	.ModalAlertWraper .mainalertbtn{
		margin-top:20px
	}

	.ModalAlertWraper div{
		font-size: 16px;
		color: #707070;
	}

	.modalalertheadertext{

	}

	.modalalertcontenttext{
		color: #4E858E !important;
		font-weight: 500;
		font-size: 18px !important;
	}

	.backAtTop{
		top:35px
	}

	.modal-bottom-full-fixed .modal-content{
		height:100%;
		position:fixed
	}

	.modal.show .modal-bottom-full-fixed .modal-body{
		padding-bottom: 20px;
	}

	.lineWraper{
		position: relative;
		height: 30px;
		width: 30px;
		display: block;
	}

	.lineWraper .firstLine{
		border: 2px solid #899EA2;
    transform: rotate(45deg);
    display: block;
    border-radius: 8px;
    position: absolute;
    width: 100%;
    top: 13px;
    background: #899EA2;
	}

	.lineWraper .secondLine{
		border: 2px solid #899EA2;
    transform: rotate(-45deg);
    display: block;
    border-radius: 8px;
    position: absolute;
    width: 100%;
    top: 13px;
    background: #899EA2;
	}

	.navbar-header-menu.onMenuOpen{
		height: 30px;
		width: 30px;
	}

	.menucategoryheader{
		display: flex;
    justify-content: space-between;
    align-items: center;
	}

	.menucategoryheaderIcon svg{
		height:11px;
		transition: transform .3s;
	}

	

	.Collapsible__trigger.is-open .menucategoryheaderIcon svg{
		-webkit-transform: rotateZ(180deg);
		transform: rotateZ(180deg)
	}

	.poweredByContainer
	{
		height:30px;
		background: #022126;
		
		color:#F4F6F7;
		font-size:12px;
		line-height: 16px;
		display:flex;
		justify-content: center;
		align-items: center;
	}

	.ModalCommonWraper{
		padding: 20px 10px;
	}

	.ModalCommonTittle{
		text-align: center;
		font-size: 16px;
		line-height: 22px;
		font-weight: 600;
	}

	.ModalCommonBody{
		padding:20px 0px
	}

	.ModalCommonOptionWraper{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.ModalCommonOption{
		flex-grow: 1;
		text-align: center;
	}

	.ModalCommonOption a{
		width: 80px;
		height: 80px;
		display: flex;
		margin: 0 auto;
		align-items: center;
		justify-content: center;
		background: #4e858e;
		border-radius: 50%;
	}

	.ModalCommonOption a svg{
		width: 45px;
		height: 45px;
		fill: #fff;

	}

	.ModalCommonOptionText{
		font-size: 16px;
		line-height: 26px;
		font-weight: 500;
		margin-top:5px
	}

	.modalForm{
		padding:18px 18px	
	}

	.modalForm .modalFormGroup input[type="text"]{
		width: 100%;
		height: 40px;
		border-radius: 4px;
		transition: .2s all linear;
		color: #575757;
		background-color: #fafafa;
		padding:5px 10px;
		outline: none;
		border: 1px solid #cecccc;
	}
	.modalForm .modalFormGroup input[type="text"]:focus{
		background-color: #fff;
	}

	.spinner-small-center{
		text-align: center
	}

	.loadingLocations{
		margin-top: 20px
	}

	.input-location-field{
		position: relative
	}

	.input-location-field input[type="text"]{
		padding-left: 35px !important;
	}


	.input-location-field svg{
		width: 20px;
		    height: 20px;
		    position: absolute;
		   top:50%;
		   margin-top:-10px;
		   left:10px
	}

	.location-suggestion{
		padding: 5px 0px;
    		border-bottom: 1px solid #eee;
	}

	.location-suggestion span{
		display:block
	}

	.modalFormGroup .form-group .input-label{
		color: #9e9e9e;
		margin-bottom: 5px;
		font-size: 14px;
		display:block
	}

	.input-label-wraper{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.input-label-second{
		font-weight: 300
	}

	.btnChangeLocation{
		border: 1px solid #4e858e;
    padding: 3px 5px;
    color: #4e858e !important;
    font-size: 14px;
    font-weight: 600;
	}

	

	.placeholdermaincolor::-webkit-input-placeholder {
		color: #4e858e;
		opacity: 1
	}
	
	.placeholdermaincolor::-moz-placeholder {
		color: #4e858e;
		opacity: 1
	}
	
	.placeholdermaincolor:-ms-input-placeholder {
		color: #4e858e;
		opacity: 1
	}
	
	.placeholdermaincolor::-ms-input-placeholder {
		color: #4e858e;
		opacity: 1
	}
	
	.placeholdermaincolor::placeholder {
		color: #4e858e;
		opacity: 1
	}

	.modalForm .modalFormGroup input.input-text{
		height:auto;
		padding:0;
		border:none;
		background: none;
		color: #4e858e
	}

	.lbl-small{
		font-size: 13px;

	}

	.lbl-main{
		font-weight:600;
		font-size:16px
	}

	.lbl-main span{
		display:block;
		font-size: 20px;
		margin:5px 0 0 0;
	}

	.lbl-main span.secondlbl{
		font-weight:400;
		font-size: 16px;
		color: #4E858E;
		margin: 0;
	}

	.delivery-cart{
		margin-bottom: 5px;
		border-bottom: 1px solid #f2f2f2;
		padding-bottom: 0px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.delivery-cart button{
		border-radius: 4px;
		background: none;
		color: #4E858E;
		font-weight: 600;
		border: 1px solid #4E858E;
	}

	.mainformwraper{
		border: 1px solid #cecccc;
		border-radius: 4px;
		padding: 5px 10px 0px 10px;
		margin-bottom: 15px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
	}

	.mainformlabel{
		font-size: 13px;
    		color: #4E858E;
	}

	.mainformcontrol input[type="text"]{
		border:none;
		
		width:100%;
		outline:none;
		padding: 0px 0 5px 0;
	}
	
	.headerlabel{
		color: #4E858E;
	}

	.location-suggestion-edit{
		display:flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between
	}

	.location-suggestion-edit button
	{
		margin-left: 10px;
    border: none;
    display: flex;
    background: #022126;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 36px;
    width: 36px;
	}

	.location-suggestion-edit button svg
	{
		height:25px;
		width:25px
	}

	.cartusername{
		margin-bottom: 10px;
		text-align: center;
	}

	.cartusername button{
		border: none;
		background: none;
		margin-left: 5px;
	}

	.cartusername span{
		font-weight: 600
	}

	.modal.show .modal-bottom-full-small.modal-bottom-full-very-small{
		min-height: 200px;
		    max-height: 200px;
		    
	}

	.addtohomescreen-wraper{
		margin: 0 16px;
		padding: 16px;
	}

	.addtohomescreen{
		align-items: center;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		text-align: left;
		margin-bottom: 16px;
	}

	.addtohomescreen svg
	{
		flex: 0 0 auto;
		height: 30px;
		margin-right: 32px;
		width: 25px;
		color: #007aff;
    		fill: #007aff;
	}

	.addtohomescreen-wraper-android .addtohomescreen.cookieButton{
		justify-content: center;
		margin-top: 15px;
	}

	.modal-bottom-full-very-small .modal-title{
		padding: 0 30px;
	}

	.cartwraper.onmodalshow:before{
		content: '';
		position: absolute;
		top: 60px;
		bottom: 0;
		left: 0;
		right: 0;
		background: #022126;
		opacity: 0.8;
		z-index: 1000;
	}

	.cartwraper.onmodalshowwithheader:before{
		content: '';
		position: absolute;
		top: 75px;
		bottom: 0;
		left: 0;
		right: 0;
		background: #022126;
		opacity: 0.8;
		z-index: 1000;
	}

	.iframemap{
		overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px
	}

	.viewDriver{
		text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    margin-bottom: 20px;
	}

	.viewDriverHistory{
		text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 14px;
    margin-bottom: 10px;
	}

	.orderhistory-container{
		border-bottom: 1px solid #899EA2;
	}
	
	/*
	.Collapsible__trigger:after {
	font-family: "FontAwesome";
	content: "\f107";
	position: absolute;
	right: 15px;
	top: 12px;
	display: block;
	-webkit-transition: -webkit-transform 300ms;
	transition: -webkit-transform 300ms;
	transition: transform 300ms;
	transition: transform 300ms, -webkit-transform 300ms;
	font-size: 26px;
	color: #4E858E;
}

.Collapsible__trigger.is-open:after {
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg)
}
	*/

	.tabdelivery{
		background: #F4F6F7;
		border-radius: 10px;
		padding: 5px 12px;
		box-shadow: 0px 3px 6px rgb(78 133 142 / 30%);
		margin-bottom: 15px;
	}

	.deliveryTabs{
		display: flex;
		justify-content: space-around;
		border-bottom: 1px solid #cccbcb;
		
		margin-bottom: 10px;
	}

	.deliveryTabs a
	{
		padding: 6px 0;
		display: block;
		width: 50%;
		text-align: center;
		position:relative;
		color:#a9a9a9 !important
	}

	.deliveryTabs a.activetab{
		color:#4E858E !important
	}

	.deliveryTabs a.activetab:before{
		position: absolute;
		content: "";
		background: #4E858E;
		height: 1px;
		width: 100%;
		bottom: -1px;
		left: 0;
	}